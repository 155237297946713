import { FetchResponse, publisherApi } from '../../utils/fetch';

export interface IPageContentTryAcquireLockResponse {
  pageLock: {
    pageGuid: string;
    status: 'acquired' | 'failed';
    lockedBy: string;
  };
  headVersion: number;
}

export const pageContentTryAcquireLock = async (pageGuid: string,
                                                editionWindowId: string): Promise<FetchResponse<IPageContentTryAcquireLockResponse>> => {
  return await publisherApi<IPageContentTryAcquireLockResponse>(
    `/pages/${pageGuid}/content/try-acquire-lock?editionWindowId=${editionWindowId}`,
    'POST');
};
