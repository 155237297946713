import React from 'react';
import styled from 'styled-components';
import { TextArea } from '../../TextArea/TextArea';
import { useKeyboard } from '../useKeyboard';
import { IKeyboard } from '../../../keyboard/Keyboard.models';
import { ReadonlyContext } from '../../../../contexts/ReadonlyContext/ReadonlyContext';
import { KeyboardIcon } from '../../../../index';

export interface ITextAreaWithKeyboardProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  keyboard: IKeyboard;
}

export const TextAreaWithKeyboard: React.FC<ITextAreaWithKeyboardProps> = ({
  keyboard,
  onFocus,
  onBlur,
  className,
  disabled,
  ...otherProps
}) => {
  const {
    inputRef,
    hasFocus,
    wrappedOnFocus,
    wrappedOnBlur,
    onClickKeyboardIcon,
  } = useKeyboard(onFocus, onBlur, keyboard);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const icon = !isDisabled && (
    <IconWrapper
      hasFocus={hasFocus}
    >
      <KeyboardIcon
        style={{ width: '24px', height: '24px' }}
        onClick={onClickKeyboardIcon}
      />
    </IconWrapper>
  );

  return (
    <Container
      className={className}
    >
      <StyledTextArea
        {...otherProps}
        onFocus={wrappedOnFocus}
        onBlur={wrappedOnBlur}
        disabled={isDisabled}
        ref={inputRef}
      />
      {icon}
    </Container>
  );
};

const StyledTextArea = styled(TextArea)`
  padding-right: 28px;
  width: 100%;
  box-sizing: border-box;
`;

interface IStyledKeyboardIcon {
  readonly hasFocus: boolean;
}

const IconWrapper = styled.div<IStyledKeyboardIcon>`
  & svg {
    height: 1em;
    width: 1em;
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
    color: ${props => props.theme.colorset.grey13};
    visibility: ${props => props.hasFocus ? 'visible' : 'hidden'};

    &:hover {
      visibility: visible;
    }
  }
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${IconWrapper} svg {
      visibility: visible;
    }
  }
`;
