import React from 'react';
import styled from 'styled-components';
import { Editor, RangeRef } from '../../TypedSlate';
import { ICustomEditor } from '../../models/editor';
import { getFontSize, setFontSize } from '../../utils/characterFormatUtils';
import { NumberInput } from '../../../NumberInput/NumberInput';
import { IClassDefinition } from '../../models/configuration';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IFontSizeControlProps {
  editor: ICustomEditor;
  label: string;
  classes: ReadonlyArray<IClassDefinition>;
  className?: string;
}

export const FontSizeControl: React.FC<IFontSizeControlProps> = (props) => {
  const { editor, label, classes, className } = props;
  const selectionRefRef = React.useRef<RangeRef>(null); // Name is not a typo. It's a React ref of a slate ref.
  const currentFontSize = getFontSize(editor, classes, selectionRefRef.current?.current);
  const onChange = (value: number) => {
    setFontSize(editor, classes, value, selectionRefRef.current ? selectionRefRef.current.current : null);
  };

  const onFocus = () => {
    const rangeRef = Editor.rangeRef(editor, editor.selection);
    selectionRefRef.current = rangeRef;
  };
  const onBlur = () => {
    selectionRefRef.current.unref();
    selectionRefRef.current = null;
  };
  const onMouseDown = (event: React.MouseEvent) => {
    const isInInput = (event.target as HTMLElement).nodeName === 'INPUT';
    if (!isInInput) {
      event.preventDefault();
    }
  };
  return (
    <Tooltip
      title={label}
    >
      <FontSizeContainer>
        <StyledNumberInput
          value={currentFontSize}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onMouseDown={onMouseDown}
          aria-label={label}
          className={className}
        />
      </FontSizeContainer>
    </Tooltip>
  );
};

const FontSizeContainer = styled.div`
  display: inline-block;
`;

const StyledNumberInput = styled(NumberInput)`
  width: 80px;
  height: 40px;
`;
