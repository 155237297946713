import React from 'react';
import styled from 'styled-components';
import { ICustomText, ICustomRenderLeafProps } from '../../models/editor';

export const Leaf: React.FC<ICustomRenderLeafProps<ICustomText>> = (props) => {
  let textDecoration: ReadonlyArray<string> = [];

  if (props.leaf.underline) {
    textDecoration = textDecoration.concat('underline');
  }

  if (props.leaf.overline) {
    textDecoration = textDecoration.concat('overline');
  }

  if (props.leaf.strikethrough) {
    textDecoration = textDecoration.concat('line-through');
  }

  const style = {
    fontWeight: props.leaf.fontWeight ? props.leaf.fontWeight : null,
    fontStyle: props.leaf.fontStyle ? props.leaf.fontStyle : null,
    fontSize: props.leaf.fontSize ? `${props.leaf.fontSize}px` : null,
    textDecoration: textDecoration.length > 0 ? textDecoration.join(' ') : null,
    color: props.leaf.color ? props.leaf.color : null,
    backgroundColor: props.leaf.backgroundColor ? props.leaf.backgroundColor : null,
  };

  let children = props.children;
  if (props.leaf.baselineShift === 'sub') {
    children = (
      <sub>
        {children}
      </sub>
    );
  } else if (props.leaf.baselineShift === 'super') {
    children = (
      <sup>
        {children}
      </sup>
    );
  }

  if (props.leaf.isNonBreakingCharacter) {
    children = (
      <NonBreakingCharacter>
        {children}
      </NonBreakingCharacter>
    );
  }

  return (
    <span
      {...props.attributes}
      style={style}
    >
      {children}
    </span>
  );
};

const NonBreakingCharacter = styled.span`
  padding-left: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4688 6C13.9688 6 16 8.03125 16 10.5312C16 12.75 14.375 14.625 12.2188 15H12.1562C11.5938 15.0938 11.0938 14.7188 11 14.1875C10.9062 13.625 11.2812 13.125 11.8125 13.0312H11.875C13.0938 12.8125 14 11.75 14 10.5312C14 9.15625 12.8438 8 11.4688 8H6.5C5.125 8 4 9.15625 4 10.5312C4 11.75 4.875 12.8125 6.09375 13.0312H6.15625C6.6875 13.125 7.0625 13.625 6.96875 14.1875C6.875 14.7188 6.375 15.0938 5.8125 15H5.75C3.59375 14.625 2 12.75 2 10.5312C2 8.03125 4 6 6.5 6H11.4688ZM12.5 18C10 18 8 16 8 13.5C8 11.2812 9.59375 9.40625 11.75 9.03125H11.8125C12.375 8.9375 12.875 9.3125 12.9688 9.84375C13.0625 10.4062 12.6875 10.9062 12.1562 11H12.0938C10.875 11.2188 10 12.25 10 13.5C10 14.875 11.125 16 12.5 16H17.4688C18.8438 16 20 14.875 20 13.5C20 12.25 19.0938 11.2188 17.875 11H17.8125C17.2812 10.9062 16.9062 10.4062 17 9.84375C17.0938 9.3125 17.5938 8.9375 18.1562 9.03125H18.2188C20.375 9.40625 22 11.2812 22 13.5C22 16 19.9688 18 17.4688 18H12.5Z' fill='%23FF45A2'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
