import React from 'react';
import {
  IMultiSelectProps,
  MultiSelect,
} from './MultiSelect';
import { TopLabeledSelectedItem } from '../Select/TopLabeledSelectedItem';

type SelectionIdType = string | number;

export interface ITopLabeledMultiSelect extends IMultiSelectProps<SelectionIdType> {}

export const TopLabeledMultiSelect: React.FC<ITopLabeledMultiSelect> = (props) => {
  const {
    allValues,
    resources,
    selectedIds,
    SelectedComponent,
  } = props;
  const selectedValuesLabel = React.useMemo(() => {
    if (selectedIds.length === 0 || Boolean(SelectedComponent)) {
      return null;
    }

    return allValues.filter(value => selectedIds.includes(value.id))
      .map(value => value.displayValue)
      .join(', ');
  }, [allValues, selectedIds, SelectedComponent]);

  const LocalSelectedComponent = SelectedComponent ?? (
    <TopLabeledSelectedItem
      label={resources.dropdownLabel}
      selectedLabel={selectedValuesLabel}
    />
  );

  return (
    <MultiSelect
      {...props}
      SelectedComponent={LocalSelectedComponent}
    />
  );
};
