import {
  RangeRef,
  Transforms,
} from '../TypedSlate';
import { ICustomEditor } from '../models/editor';
import { IImageElement } from '../models/elements';

export const insertImage = (editor: ICustomEditor, src: string, altText: string, rangeRef?: RangeRef) => {
  const selection = getRange(editor, rangeRef);
  const text = { text: '' };
  const image: IImageElement = { src, altText, type: 'image', width: 32, height: 32, children: [text] };
  Transforms.insertNodes(editor, image, { at: selection });
};

const getRange = (editor: ICustomEditor, rangeRef?: RangeRef) =>
  rangeRef ? rangeRef.current : editor.selection;
