import React from 'react';
import { indentMore } from '../../utils/indentUtils';
import { ICustomEditor } from '../../models/editor';
import { Button } from '../../../Button/Button';
import IndentIcon from '../../../../../assets/indent.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IIndentMoreControlProps {
  editor: ICustomEditor;
  label: string;
}

export const IndentMoreControl: React.FC<IIndentMoreControlProps> = ({ editor, label }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    indentMore(editor);
  };
  return (
    <Tooltip
      title={label}
    >
      <Button
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <IndentIcon />
      </Button>
    </Tooltip>
  );
};
