import React from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import { ITheme } from '../../../themes/ITheme';
import { setHexColorTransparency } from '../../../themes/ColorSet';

export interface ITranslationStatusIconProps {
  readonly status: TranslationStatus;
  readonly className?: string;
}

interface IIconDiv {
  readonly status: TranslationStatus;
}

export enum TranslationStatus {
  complete = 'complete',
  partial = 'partial',
  none = 'none',
}

export const TranslationStatusIcon: React.FC<ITranslationStatusIconProps> = (
  {
    children,
    status,
    className,
  },
) => {
  return (
    <LanguageIconDiv
      className={className}
      status={status}
    >
      {children}
    </LanguageIconDiv>
  );
};

const getBackgroundColor = (props: ThemedStyledProps<IIconDiv, ITheme>) => {
  if (props.status === TranslationStatus.complete) {
    return setHexColorTransparency(props.theme.colorset.primary500, 0.1);
  }

  if (props.status === TranslationStatus.partial) {
    return props.theme.colorset.grey050;
  }

  return 'transparent';
};

const getBorderColor = (props: ThemedStyledProps<IIconDiv, ITheme>) => {
  if (props.status === TranslationStatus.complete) {
    return props.theme.colorset.primary500;
  }

  if (props.status === TranslationStatus.partial) {
    return props.theme.colorset.grey400;
  }

  return props.theme.colorset.grey100;
};

const getColor = (props: ThemedStyledProps<IIconDiv, ITheme>) => {
  if (props.status === TranslationStatus.complete) {
    return props.theme.colorset.primary700;
  }

  if (props.status === TranslationStatus.partial) {
    return props.theme.colorset.grey500;
  }

  return props.theme.colorset.grey100;
};

const LanguageIconDiv = styled.div<IIconDiv>`
  border: 1px solid;
  background-color: ${getBackgroundColor};
  border-color: ${getBorderColor};
  border-radius: 50%;
  color: ${getColor};
  height: 24px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  width: 24px;
`;
