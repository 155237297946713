import React from 'react';
import { SelectContext } from './SelectContext';
import { Dropdown } from '../../utils/Dropdown/Dropdown';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';
import { ContentVariant } from '../../utils/Dropdown/models';

export interface ISelectProps {
  SelectedComponent: React.ReactNode;
  value: any;
  onChange: (value: any) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  className?: string;
  direction?: 'top' | 'bottom';
  disabled?: boolean;
  toggle?: boolean;
  optionsCount?: number;
  contentVariant?: ContentVariant;
  dataTestId?: string;
  ariaLabel?: string;
  preventDynamicDirection?: boolean;
}

export const Select: React.FC<ISelectProps> = (props) => {
  const {
    SelectedComponent,
    className,
    children,
    value,
    onChange,
    onMouseDown,
    disabled,
    toggle,
    optionsCount,
    direction,
    contentVariant,
    dataTestId,
    ariaLabel,
    preventDynamicDirection,
  } = props;
  const [open, setOpen] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const onSelectionChange = (newValue: any) => {
    setOpen(false);
    onChange(newValue);
  };
  const actualOptionsCount = optionsCount ?? React.Children.count(children);
  const isOpen = open && actualOptionsCount > 0;
  return (
    <Dropdown
      SelectedComponent={SelectedComponent}
      className={className}
      open={isOpen}
      direction={direction}
      onMouseDown={onMouseDown}
      onChangeOpen={setOpen}
      disabled={isDisabled}
      toggle={toggle}
      contentVariant={contentVariant}
      dataTestId={dataTestId}
      ariaLabel={ariaLabel}
      preventDynamicDirection={preventDynamicDirection}
    >
      <SelectContext.Provider
        value={{
          value,
          onChange: onSelectionChange,
        }}
      >
        {children}
      </SelectContext.Provider>
    </Dropdown>
  );
};
