import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { BasePopup } from '../BasePopup';
import { POPUP_ZINDEX } from '../../../../themes/zIndex';

/**
 * This component is built to display a popover over the anchor element
 * with a tip at the bottom, aligned horizontally according to tipSide.
 *
 * If we want to show the tip on any side depending on anchorOrigin and
 * transformOrigin, we will need to refactor this component.
 */

export type TipAlignment =
  'bottom-center' |
  'bottom-right' |
  'bottom-left' |
  'top-center' |
  'top-right' |
  'top-left' |
  'right-center' |
  'left-center';

export interface IPopoverWithTipProps {
  readonly open: boolean;
  readonly anchorEl: Element | null;
  readonly tipAlignment: TipAlignment;
  readonly onClose: (event: React.MouseEvent) => void;
  readonly horizontalOffset?: number;
  readonly verticaLOffset?: number;
  readonly onMouseDown?: (event: React.MouseEvent) => void;
}

export const PopoverWithTip: React.FC<IPopoverWithTipProps> = ({ children, ...props }) => {
  const {
    tipAlignment,
    open,
    anchorEl,
    verticaLOffset = 0,
    horizontalOffset = 0,
    onClose,
    onMouseDown,
  } = props;

  if (!anchorEl || !open) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Backdrop
        onClick={onClose}
      />
      <BasePopup
        anchorEl={anchorEl}
        verticaLOffset={verticaLOffset}
        horizontalOffset={horizontalOffset}
        tipAlignment={tipAlignment}
        onMouseDown={onMouseDown}
      >
        {children}
      </BasePopup>
    </>,
    document.body);
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${POPUP_ZINDEX};
`;
