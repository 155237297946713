import React from 'react';
import styled from 'styled-components';

export interface IDraggableWindowProps {
  startPosition?: { x: number; y: number };
  className?: string;
  onRef?: (element: HTMLDivElement) => void;
  dragCondition?: (event: React.PointerEvent) => boolean;
  onFinishDragging?: (x: number, y: number) => void;
}

export class DraggableWindow extends React.Component<IDraggableWindowProps> {
  private containerDomElement: HTMLDivElement;

  private relativeDragPosition: { x: number; y: number };

  private currentPosition: { x: number; y: number };

  constructor(props: IDraggableWindowProps) {
    super(props);
    this.containerDomElement = null;
    this.relativeDragPosition = {
      x: 0,
      y: 0,
    };
    this.currentPosition = {
      x: 0,
      y: 0,
    };
  }

  public componentDidMount(): void {
    const {
      startPosition,
    } = this.props;
    if (startPosition) {
      this.setContainerPosition(startPosition.x, startPosition.y);
    }
    window.addEventListener('resize', this.onWindowResize);
  }

  public componentWillUnmount(): void {
    this.removeDragListeners();
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render(): React.ReactNode {
    const {
      children,
      className,
    } = this.props;
    return (
      <Container
        ref={this.onContainerRef}
        onPointerDown={this.onPointerDown}
        className={className}
      >
        {children}
      </Container>
    );
  }

  private onContainerRef = (element: HTMLDivElement) => {
    this.containerDomElement = element;
    this.props.onRef?.(element);
  };

  private onPointerDown = (event: React.PointerEvent) => {
    const canDrag = this.props.dragCondition ? this.props.dragCondition(event) : true;
    if (canDrag) {
      event.preventDefault();
      document.addEventListener('pointermove', this.onDragPointerMove);
      document.addEventListener('pointerup', this.onDragPointerUp);
      const containerBoundingBox = this.containerDomElement.getBoundingClientRect();
      this.relativeDragPosition = {
        x: containerBoundingBox.left - event.clientX,
        y: containerBoundingBox.top - event.clientY,
      };
    }
  };

  private onDragPointerMove = (event: PointerEvent) => {
    event.preventDefault();
    const x = event.clientX + this.relativeDragPosition.x;
    const y = event.clientY + this.relativeDragPosition.y;
    this.setContainerPosition(x, y);
  };

  private onDragPointerUp = (event: PointerEvent) => {
    this.props.onFinishDragging?.(this.currentPosition.x, this.currentPosition.y);
    this.removeDragListeners();
  };

  private removeDragListeners = () => {
    document.removeEventListener('pointermove', this.onDragPointerMove);
    document.removeEventListener('pointerup', this.onDragPointerUp);
  };

  // Prevent the window from being unreachable
  private onWindowResize = () => {
    const containerBoundingBox = this.containerDomElement.getBoundingClientRect();
    const rightDiff = document.documentElement.clientWidth - containerBoundingBox.right;
    const bottomDiff = document.documentElement.clientHeight - containerBoundingBox.bottom;
    const newX = rightDiff < 0
      ? containerBoundingBox.left + rightDiff
      : containerBoundingBox.left;
    const newY = bottomDiff < 0
      ? containerBoundingBox.top + bottomDiff
      : containerBoundingBox.top;
    this.setContainerPosition(newX, newY);
  };

  private setContainerPosition = (x: number, y: number) => {
    this.currentPosition = { x, y };
    this.containerDomElement.style.transform = `translate(${x}px, ${y}px)`;
  };
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: grab;

  & > * {
    cursor: auto;
  }
`;
