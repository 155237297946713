import { Editor, Element, Transforms, Range } from '../TypedSlate';
import { ICustomEditor } from '../models/editor';
import { unsetMarkAtRange } from './markUtils';

/**
 * Clear character and paragraph formats.
 *
 * @param editor
 * @param range
 */
export const clearFormat = (editor: ICustomEditor, range?: Range) => {
  const selection = range ?? editor.selection;
  if (!selection) {
    return;
  }
  const selectionRef = Editor.rangeRef(editor, selection);

  Transforms.unsetNodes(editor, ['indent', 'textAlign', 'className'], {
    mode: 'all',
    at: selectionRef.current,
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && (n.type === 'paragraph' || n.type === 'list');
    },
  });

  const characterFormats: ReadonlyArray<string> = [
    'fontWeight',
    'fontStyle',
    'fontSize',
    'underline',
    'strikethrough',
    'overline',
    'baselineShift',
    'color',
    'backgroundColor',
  ];
  characterFormats.forEach((characterFormat: string) => {
    unsetMarkAtRange(editor, selectionRef.current, characterFormat);
  });
  selectionRef.unref();
};
