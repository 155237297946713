import React from 'react';
import styled from 'styled-components';
import { SwitchButton, ISwitchButtonProps } from './SwitchButton';

interface ILabeledSwitchButtonProps extends ISwitchButtonProps {
  Label?: React.ReactNode;
  className?: string;
}

export const LabeledSwitchButton: React.FC<ILabeledSwitchButtonProps> = (props) => {
  const {
    Label,
    className,
    ...otherProps
  } = props;
  return (
    <Container
      className={className}
    >
      <LabelContainer>
        {Label}
      </LabelContainer>
      <SwitchButton {...otherProps} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const LabelContainer = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.colorset.grey13};
`;
