import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../../components/utils/Tooltip/Tooltip';
import {
  TabContainer,
  Tabs,
  Tab,
  SingleTab,
} from './TabView.styles';
import { LayoutContext } from '../../../contexts/LayoutContext';

export interface ITabViewProps {
  readonly labels: React.ReactNode[];
  readonly icons: ReadonlyArray<JSX.Element>;
  readonly contents: JSX.Element[];
  readonly selectedTabIndex: number;
  readonly selectTab: (index: number) => void;
  readonly className?: string;
}

export const TAB_PANEL_CLASSNAME = 'TAB_PANEL_CLASSNAME';

export const TabView: React.FC<ITabViewProps> = (props: ITabViewProps) => {
  const itemsRef = React.useRef<Array<HTMLDivElement | null>>([]);

  const [itemWidth, setItemWidth] = React.useState(0);

  React.useEffect(() => {
    let maxWidth = 0;
    itemsRef.current.forEach((element) => {
      const elemWidth = getTextWidth(element.innerText, getComputedStyle(element).font);
      if (elemWidth > maxWidth) {
        maxWidth = elemWidth;
      }
    });
    if (maxWidth !== itemWidth) {
      setItemWidth(maxWidth);
    }
  }, []);

  const getTextWidth = React.useCallback(
    (text: string, font: string) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      return context.measureText(text).width;
    },
    [],
  );

  const handleChange = (tabIndex: number) => {
    props.selectTab(tabIndex);
  };

  const isSelectedTab = (tabIndex: number): boolean => {
    return props.selectedTabIndex === tabIndex;
  };

  const labels = props.labels.map((label, index) => {
    const formattedLabel = (
      <LabelSpan>
        {label}
      </LabelSpan>
    );
    const panelWidth = React.useContext(LayoutContext);
    const content = ((panelWidth / props.labels.length) < itemWidth) && props.icons[index]
      ? (
        <Tooltip
          title={formattedLabel}
          enterDelay={100}
        >
          <IconContainer>
            {props.icons[index]}
          </IconContainer>
        </Tooltip>
      )
      : label;

    if (index === 0 && props.labels.length === 1) {
      return (
        <SingleTab
          ref={el => itemsRef.current[index] = el}
          key="0"
          id="simple-tabpanel-0"
          data-testid={`simple-tabpanel-${index}`}
          aria-labelledby="simple-tab-0"
        >
          {content}
        </SingleTab>
      );
    }
    return (
      <Tab
        ref={el => itemsRef.current[index] = el}
        key={index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        data-testid={`simple-tabpanel-${index}`}
        onClick={() => handleChange(index)}
        selected={isSelectedTab(index)}
      >
        {content}
      </Tab>
    );
  });

  const tabs = props.contents.map((tab, index) => {
    const isSelected = isSelectedTab(index);
    return (
      <div
        key={index}
        role="tabpanel"
        hidden={!isSelected}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={TAB_PANEL_CLASSNAME}
      >
        {isSelected && tab}
      </div>
    );
  });

  return (
    <TabContainer
      className={props.className}
    >
      <Tabs>
        {labels}
      </Tabs>
      {tabs}
    </TabContainer>
  );
};

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const LabelSpan = styled.span`
  display: block;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;
