import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../inputs/Checkbox/Checkbox';

export interface ICardProps {
  readonly icon?: React.ReactNode;
  readonly title: React.ReactNode;
  readonly actionBar?: React.ReactNode;
  readonly footerLeft?: React.ReactNode;
  readonly footerRight?: React.ReactNode;
  readonly selected?: boolean;
  readonly id?: string | number;
  readonly onClick?: () => void;
  readonly onDoubleClick?: () => void;
  readonly checkboxVisible?: boolean;
  readonly checked?: boolean;
  readonly onToggleChecked?: (checked: boolean) => void;
  readonly dragHandleRef?: (node: HTMLElement) => void;
}

export const Card: React.FC<ICardProps> = (
  {
    icon,
    title,
    actionBar,
    footerLeft,
    footerRight,
    onClick,
    onDoubleClick,
    selected = false,
    checkboxVisible = false,
    checked = false,
    onToggleChecked = () => {},
    dragHandleRef = null,
    id,
  },
) => {
  const iconElement = icon && (
    <IconWrapper>
      {icon}
    </IconWrapper>
  );

  const titleElement = title && (
    <TitleWrapper>
      {title}
    </TitleWrapper>
  );

  const actionBarElement = actionBar && (
    <Cell>
      {actionBar}
    </Cell>
  );

  const footer = (footerLeft || footerRight) && (
    <Footer>
      <FooterLeft
        data-testid="card-footer-left"
      >
        {footerLeft}
      </FooterLeft>
      <FooterRight
        data-testid="card-footer-right"
      >
        {footerRight}
      </FooterRight>
    </Footer>
  );

  const card = (
    <Container
      ref={dragHandleRef}
      selected={selected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      draggable={dragHandleRef !== null}
      data-testid="card"
      data-card-id={id}
    >
      <Row>
        <Cell
          data-testid="card-title"
        >
          {iconElement}
          {titleElement}
        </Cell>
        {actionBarElement}
      </Row>
      {footer}
    </Container>
  );

  if (checkboxVisible) {
    return (
      <CheckboxContainer>
        <Checkbox
          checked={checked}
          onChange={onToggleChecked}
        />
        {card}
      </CheckboxContainer>
    );
  }

  return card;
};

interface IThemedCardProps {
  readonly selected: boolean;
}

const CardThemeWrapper = styled.div<IThemedCardProps>((props) => {
  return props.selected ? props.theme.card.selected : props.theme.card.default;
});

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Footer = styled(Row)`
  margin-top: 20px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 9px;

  > svg {
    height: 24px;
    width: 24px;
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const TitleWrapper = styled.div`
  font: ${props => props.theme.typeset.body1SemiBold};
  font-size: 16px;
  user-select: none;
`;

const FooterLeft = styled(Cell)`
  font: ${props => props.theme.typeset.captionSemiBold};
  font-size: 12px;
`;

const FooterRight = styled(Cell)`
  font: ${props => props.theme.typeset.captionRegular};
  font-size: 12px;
`;

const Container = styled(CardThemeWrapper)<{ draggable: boolean }>`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: ${props => props.draggable ? 'grab' : 'pointer'};

  ${FooterLeft},
  ${FooterRight} {
    color: ${props => props.selected ? props.theme.card.selected.color : props.theme.colorset.grey500};
  }

  &:hover {
    ${props => props.selected ? props.theme.card.selected.hover : props.theme.card.default.hover}

    ${FooterLeft},
    ${FooterRight},
  }
`;
