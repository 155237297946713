import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Converti une variable en WMarkup
 */
export class ConvertToMathML extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    const element = args.getContentElement(0);
    const markupFactory: IMarkupFactory = {
      writeTo: (exporter: IMarkupExporter) => {
        const hasRoot = exporter.hasRootElement();
        if (!hasRoot) {
          exporter.writer.beginMath();
        }
        element.writeTo(exporter);
        if (!hasRoot) {
          exporter.writer.endMath();
        }
      },
      culture: args.env.culture,
    };
    return new WMarkup(markupFactory);
  }
}
