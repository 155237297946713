import React from 'react';
import styled from 'styled-components';
import CaretRightIcon from '../../../assets/caret-right.svg';
import ChevronDownIcon from '../../../assets/chevronDown.svg';
import { Collapsable } from '../../layout/Collapsable';
import { Tooltip } from '../../utils/Tooltip/Tooltip';

export enum TreeviewVariant {
  header = 'header',
  folder = 'folder',
  headerFolder = 'header-folder',
  listItem = 'list-item',
}

export interface ITreeviewProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  readonly title: React.ReactNode;
  readonly isOpen?: boolean;
  readonly selected?: boolean;
  readonly variant?: TreeviewVariant;
  readonly openTooltip?: React.ReactNode;
  readonly closeTooltip?: React.ReactNode;
  readonly onToggle?: () => void;
  readonly onHeaderClick?: () => void;
}

export const Treeview: React.FC<ITreeviewProps> = (props) => {
  const {
    title,
    isOpen,
    selected,
    onToggle,
    onHeaderClick,
    children,
    variant = TreeviewVariant.header,
    openTooltip = 'Open',
    closeTooltip = 'Close',
    ...otherProps
  } = props;

  const onIconClick = onToggle
    ? (e: React.MouseEvent) => {
      onToggle();
      e.stopPropagation();
    }
    : null;

  const tooltip = isOpen ? closeTooltip : openTooltip;

  let headerContents = <HeaderLabel>{title}</HeaderLabel>;
  if (variant === TreeviewVariant.folder || variant === TreeviewVariant.headerFolder) {
    headerContents = (
      <>
        <Tooltip title={tooltip}>
          <FolderIconWrapper
            isOpen={isOpen}
            onClick={onIconClick}
            data-testid="treeview-arrow-icon"
          >
            <CaretRightIcon />
          </FolderIconWrapper>
        </Tooltip>
        <HeaderLabel>{title}</HeaderLabel>
      </>
    );
  } else if (variant === TreeviewVariant.header) {
    headerContents = (
      <>
        <HeaderLabel>{title}</HeaderLabel>
        <Tooltip title={tooltip}>
          <TitleIconWrapper
            isOpen={isOpen}
            onClick={onIconClick}
            data-testid="treeview-arrow-icon"
          >
            <ChevronDownIcon />
          </TitleIconWrapper>
        </Tooltip>
      </>
    );
  }

  return (
    <Container
      {...otherProps}
    >
      <Header
        isOpen={isOpen}
        selected={selected}
        variant={variant}
        onClick={onHeaderClick}
        className={TREEVIEW_HEADER_CLASSNAME}
        data-testid="TREEVIEW_HEADER"
      >
        {headerContents}
      </Header>
      <Collapsable
        isOpen={isOpen}
        className={TREEVIEW_CONTENT_CLASSNAME}
      >
        {children}
      </Collapsable>
    </Container>
  );
};

export const TREEVIEW_HEADER_CLASSNAME = 'TREEVIEW_HEADER_CLASSNAME';
export const TREEVIEW_CONTENT_CLASSNAME = 'TREEVIEW_CONTENT_CLASSNAME';
const ANIMATION_DURATION_MS = 200;

const Container = styled.div`
`;

interface IHeader {
  readonly selected: boolean;
  readonly isOpen: boolean;
  readonly variant: 'header' | 'folder' | 'header-folder' | 'list-item';
}

const ThemeHeader = styled.div<IHeader>((props) => {
  const style = props.theme.treeview;
  return {
    ...style,
    ...(props.isOpen ? style.open : null),
    ...(props.selected ? style.selected[props.variant] : null),
    '&:hover': style.hover,
  };
});

const Header = styled(ThemeHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 600;

  ${(props) => {
    switch (props.variant) {
      case TreeviewVariant.header: return `
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 12px 16px 12px 16px;
        height: 48px;
        justify-content: flex-end;
      `;
      case TreeviewVariant.headerFolder: return `
        justify-content: flex-start;
        font-size: 14px;
        line-height: 20px;
        padding: 2px 4px;
        height: 48px;
        border-radius: 4px;
      `;
      default: return `
        justify-content: flex-start;
        font-size: 14px;
        line-height: 20px;
        padding: 2px 4px;
        height: 24px;
        border-radius: 4px;
      `;
    }
  }}
`;

const HeaderLabel = styled.div`
  width: 100%;
  min-width: 0;
`;

interface IIcon {
  readonly isOpen: boolean;
}

const FolderIconWrapper = styled.div<IIcon>`
  line-height: 0;

  & svg {
    transform: rotate(${props => props.isOpen ? 90 : 0}deg);
    transition: transform ${ANIMATION_DURATION_MS}ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const TitleIconWrapper = styled.div<IIcon>`
  line-height: 0;

  & svg {
    transform: rotate(${props => props.isOpen ? 180 : 0}deg);
    transition: transform ${ANIMATION_DURATION_MS}ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 14px;
    height: 14px;
  }
`;
