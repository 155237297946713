import { Editor, Path, Range, Text } from '../TypedSlate';
import { ICustomEditor } from '../models/editor';
import { IFormattedText } from '../models/elements';
import { setMarkAtRange, unsetMarkAtRange } from './markUtils';
import { IClassDefinition } from '../models/configuration';
import { commonClassName } from './classNameUtils';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_COLOR, DEFAULT_FONT_SIZE } from '../constants/marks';

const isMarkActive = (editor: ICustomEditor,
                      format: string,
                      valueWhenActive: any) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === valueWhenActive : false;
};

const getMark = (editor: ICustomEditor,
                 format: string): any => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] : null;
};

const toggleMark = (editor: ICustomEditor,
                    format: string,
                    valueWhenActive: any) => {
  const isActive = isMarkActive(editor, format, valueWhenActive);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, valueWhenActive);
  }
};

export const isBoldMarkActive = (editor: ICustomEditor,
                                 classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isFontWeightInherited(editor, classes, 'bold');
  const fontWeight = getMark(editor, 'fontWeight');
  return fontWeight === 'bold' || (isInherited && fontWeight !== 'normal' && fontWeight !== 600);
};

export const isSemiBoldMarkActive = (editor: ICustomEditor,
                                     classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isFontWeightInherited(editor, classes, 600);
  const fontWeight = getMark(editor, 'fontWeight');
  return fontWeight === 600 || (isInherited && fontWeight !== 'normal' && fontWeight !== 'bold');
};

export const isFontWeightInherited = (editor: ICustomEditor,
                                      classes: ReadonlyArray<IClassDefinition>,
                                      fontWeightValue: string | number): boolean => {
  const className = commonClassName(editor);
  if (className) {
    return classes.find(c => c.className === className).characterStyles.fontWeight === fontWeightValue;
  }
  return false;
};

export const isItalicMarkActive = (editor: ICustomEditor,
                                   classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isItalicInherited(editor, classes);
  const fontStyle = getMark(editor, 'fontStyle');
  return fontStyle === 'italic' || (isInherited && fontStyle !== 'normal');
};

export const isItalicInherited = (editor: ICustomEditor,
                                  classes: ReadonlyArray<IClassDefinition>): boolean => {
  const className = commonClassName(editor);
  if (className) {
    return classes.find(c => c.className === className).characterStyles.fontStyle === 'italic';
  }
  return false;
};

export const isUnderlineMarkActive = (editor: ICustomEditor) => {
  return isMarkActive(editor, 'underline', true);
};

export const isOverlineMarkActive = (editor: ICustomEditor) => {
  return isMarkActive(editor, 'overline', true);
};

export const isStrikethroughMarkActive = (editor: ICustomEditor) => {
  return isMarkActive(editor, 'strikethrough', true);
};

export const isSubscriptMarkActive = (editor: ICustomEditor) => {
  return isMarkActive(editor, 'baselineShift', 'sub');
};

export const isSuperscriptMarkActive = (editor: ICustomEditor) => {
  return isMarkActive(editor, 'baselineShift', 'super');
};

export const getColor = (editor: ICustomEditor,
                         classes: ReadonlyArray<IClassDefinition>, range?: Range) => {
  const colors = getSelectionMarkValues<string | undefined>(editor, 'color', range);
  if (colors.length > 1) {
    return null; // multiple colors used, return null
  }
  if (colors.length === 1 && colors[0] !== undefined) {
    return colors[0];
  }
  return getInheritedColor(editor, classes, range);
};

export const getInheritedColor = (editor: ICustomEditor,
                                  classes: ReadonlyArray<IClassDefinition>,
                                  range?: Range) => {
  const className = commonClassName(editor, range);
  if (className) {
    return classes.find(c => c.className === className).characterStyles.color ?? DEFAULT_COLOR;
  }
  return DEFAULT_COLOR;
};

export const getBackgroundColor = (editor: ICustomEditor,
                                   classes: ReadonlyArray<IClassDefinition>,
                                   range?: Range) => {
  const backgroundColors = getSelectionMarkValues<string | undefined>(editor, 'backgroundColor', range);
  if (backgroundColors.length > 1) {
    return null; // multiple colors used, return null
  }
  if (backgroundColors.length === 1 && backgroundColors[0] !== undefined) {
    return backgroundColors[0];
  }
  return getInheritedBackgroundColor(editor, classes, range);
};

export const getInheritedBackgroundColor = (editor: ICustomEditor,
                                            classes: ReadonlyArray<IClassDefinition>,
                                            range?: Range) => {
  const className = commonClassName(editor, range);
  if (className) {
    return classes.find(c => c.className === className).characterStyles.backgroundColor ?? DEFAULT_BACKGROUND_COLOR;
  }
  return DEFAULT_BACKGROUND_COLOR;
};

export const getSelectedTextNodes = (editor: ICustomEditor,
                                     range?: Range): ReadonlyArray<IFormattedText> => {
  const selectionRange = range || editor.selection;
  const textNodes: IFormattedText[] = [];
  const textNodeEntries = Editor.nodes<IFormattedText>(editor, {
    at: selectionRange,
    match: Text.isText,
  });
  for (const textNodeEntry of textNodeEntries) {
    const textNodeRange = getTextNodeRange(textNodeEntry[1], textNodeEntry[0].text);
    if (Range.isCollapsed(selectionRange) || !Range.isCollapsed(Range.intersection(selectionRange, textNodeRange))) {
      textNodes.push(textNodeEntry[0]);
    }
  }
  return textNodes;
};

export const getSelectionMarkValues = <T>(editor: ICustomEditor, markName: string, range?: Range): T[] => {
  const selectionRange = range || editor.selection;
  if (selectionRange && Range.isCollapsed(selectionRange) && editor.marks && editor.marks[markName]) {
    return [editor.marks[markName]];
  }
  const textNodes = getSelectedTextNodes(editor, range);
  const commonValues: T[] = [];
  textNodes.forEach((textNode: IFormattedText) => {
    const value: T = textNode[markName] as T;
    if (commonValues.indexOf(value) === -1) {
      commonValues.push(value);
    }
  });
  return commonValues;
};

/**
 * Returns null when no common fontSize is found.
 *
 * @param editor
 * @param classes
 * @param range
 */
export const getFontSize = (editor: ICustomEditor,
                            classes: ReadonlyArray<IClassDefinition>,
                            range?: Range): number | null => {
  const fontSizes = getSelectionMarkValues<number | undefined>(editor, 'fontSize', range);
  if (fontSizes.length > 1) {
    return null; // multiple font sizes used, return null
  }
  if (fontSizes.length === 1 && fontSizes[0] !== undefined) {
    return fontSizes[0];
  }
  return getInheritedFontSize(editor, classes, range);
};

export const getInheritedFontSize = (editor: ICustomEditor,
                                     classes: ReadonlyArray<IClassDefinition>,
                                     range?: Range) => {
  const className = commonClassName(editor, range);
  if (className) {
    return classes.find(c => c.className === className).characterStyles.fontSize ?? DEFAULT_FONT_SIZE;
  }
  return DEFAULT_FONT_SIZE;
};

export const toggleBoldMark = (editor: ICustomEditor,
                               classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isFontWeightInherited(editor, classes, 'bold');
  const isActive = isBoldMarkActive(editor, classes);
  if (isActive) {
    if (isInherited) {
      Editor.addMark(editor, 'fontWeight', 'normal');
    } else {
      Editor.removeMark(editor, 'fontWeight');
    }
  } else if (isInherited) {
    Editor.removeMark(editor, 'fontWeight');
  } else {
    Editor.addMark(editor, 'fontWeight', 'bold');
  }
};

export const toggleSemiBoldMark = (editor: ICustomEditor,
                                   classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isFontWeightInherited(editor, classes, 600);
  const isActive = isSemiBoldMarkActive(editor, classes);
  if (isActive) {
    if (isInherited) {
      Editor.addMark(editor, 'fontWeight', 'normal');
    } else {
      Editor.removeMark(editor, 'fontWeight');
    }
  } else if (isInherited) {
    Editor.removeMark(editor, 'fontWeight');
  } else {
    Editor.addMark(editor, 'fontWeight', 600);
  }
};

export const toggleItalicMark = (editor: ICustomEditor,
                                 classes: ReadonlyArray<IClassDefinition>) => {
  const isInherited = isItalicInherited(editor, classes);
  const isActive = isItalicMarkActive(editor, classes);
  if (isActive) {
    if (isInherited) {
      Editor.addMark(editor, 'fontStyle', 'normal');
    } else {
      Editor.removeMark(editor, 'fontStyle');
    }
  } else if (isInherited) {
    Editor.removeMark(editor, 'fontStyle');
  } else {
    Editor.addMark(editor, 'fontStyle', 'italic');
  }
};

export const toggleUnderlineMark = (editor: ICustomEditor) => {
  toggleMark(editor, 'underline', true);
};

export const toggleOverlineMark = (editor: ICustomEditor) => {
  toggleMark(editor, 'overline', true);
};

export const toggleStrikethroughMark = (editor: ICustomEditor) => {
  toggleMark(editor, 'strikethrough', true);
};

export const toggleSubscriptMark = (editor: ICustomEditor) => {
  toggleMark(editor, 'baselineShift', 'sub');
};

export const toggleSuperscriptMark = (editor: ICustomEditor) => {
  toggleMark(editor, 'baselineShift', 'super');
};

export const minFontSize = 1;
export const maxFontSize = 400;

export const isValidFontSize = (value: number) => {
  return !isNaN(value)
    && isFinite(value)
    && value >= minFontSize
    && value <= maxFontSize
    && value === Math.floor(value);
};

export const getTextNodeRange = (path: Path, text: string) => {
  return {
    anchor: {
      path,
      offset: 0,
    },
    focus: {
      path,
      offset: text ? text.length : 0,
    },
  };
};

export const setFontSize = (editor: ICustomEditor,
                            classes: ReadonlyArray<IClassDefinition>,
                            fontSize: number,
                            range?: Range) => {
  if (!isValidFontSize(fontSize)) {
    return;
  }
  const inheritedFontSize = getInheritedFontSize(editor, classes, range);
  if (inheritedFontSize === fontSize) {
    unsetMarkAtRange(editor, range, 'fontSize');
  } else {
    setMarkAtRange(editor, range, 'fontSize', fontSize);
  }
};

export const setColor = (editor: ICustomEditor,
                         classes: ReadonlyArray<IClassDefinition>,
                         color: string,
                         range?: Range) => {
  const inheritedColor = getInheritedColor(editor, classes, range);
  if (inheritedColor === color) {
    unsetMarkAtRange(editor, range, 'color');
  } else {
    setMarkAtRange(editor, range, 'color', color);
  }
};

export const setBackgroundColor = (editor: ICustomEditor,
                                   classes: ReadonlyArray<IClassDefinition>,
                                   backgroundColor: string,
                                   range?: Range) => {
  const inheritedBackgroundColor = getInheritedColor(editor, classes, range);
  if (inheritedBackgroundColor === backgroundColor) {
    unsetMarkAtRange(editor, range, 'backgroundColor');
  } else {
    setMarkAtRange(editor, range, 'backgroundColor', backgroundColor);
  }
};
