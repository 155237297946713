import React from 'react';
import styled from 'styled-components';
import { Transforms } from '../../TypedSlate';
import { findPath } from '../../utils/locationUtils';
import { ITTSElement } from '../../models/elements';
import { updateElement } from '../../utils/updateElement';
import { IPopperProps, Popper } from '../../../../utils/Popups/Popper/Popper';
import TrashBinIcon from '../../../../../assets/trash.svg';
import { Button } from '../../../Button/Button';
import { PopupInput } from '../../components/Input';
import { ICustomEditor } from '../../models/editor';

export interface ITTSPopperProps extends IPopperProps {
  element: ITTSElement;
  editor: ICustomEditor;
  ttsInputPlaceholder: string;
}

export class TTSPopper extends React.Component<ITTSPopperProps> {
  private inputRef: React.RefObject<HTMLInputElement>;

  private oldSelectionStart: number;

  private oldSelectionEnd: number;

  constructor(props: ITTSPopperProps) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
    this.oldSelectionStart = null;
    this.oldSelectionEnd = null;
  }

  public componentDidUpdate(prevProps: ITTSPopperProps): void {
    if (this.props.open
      && this.inputRef.current
      && this.oldSelectionStart
      && this.oldSelectionEnd
      && this.props.element.altText !== prevProps.element.altText
    ) {
      // React can't properly keep track of the input's cursor by itself for some reason ¯\_(ツ)_/¯
      this.inputRef.current.selectionStart = this.oldSelectionStart;
      this.inputRef.current.selectionEnd = this.oldSelectionEnd;
    }
  }

  public render(): React.ReactNode {
    const { anchorEl, open, element, onClickInside, onClickOutside, ttsInputPlaceholder } = this.props;
    const { altText } = element;
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        onClickOutside={onClickOutside}
        onClickInside={onClickInside}
      >
        <SizedPopupInput
          ref={this.inputRef}
          value={altText}
          placeholder={ttsInputPlaceholder}
          onChange={this.onChangeHref}
        />
        <Button
          icon
          onClick={this.onDeleteTTS}
        >
          <TrashBinIcon />
        </Button>
      </Popper>
    );
  }

  private closePopup = () => {
    const { onClickOutside } = this.props;
    onClickOutside();
  };

  private onDeleteTTS = () => {
    const { element, editor } = this.props;
    const path = findPath(editor.children, element);
    Transforms.unwrapNodes(editor, {
      at: path,
      match: n => n === element,
    });
    this.closePopup();
  };

  private onChangeHref = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setCursorPosition(event);
    const { element, editor } = this.props;
    const newNodeValue: Partial<ITTSElement> = {
      altText: event.target.value,
    };
    updateElement(editor, element, newNodeValue);
  };

  private setCursorPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.oldSelectionStart = event.target.selectionStart;
    this.oldSelectionEnd = event.target.selectionEnd;
  };
}

const SizedPopupInput = styled(PopupInput)`
  width: 184px;
`;
