import React from 'react';
import styled from 'styled-components';
import { InputClearButton } from '../Input/InputClearButton';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';

export interface ITopLabeledTextArea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  readonly label: React.ReactNode;
  readonly className?: string;
  readonly hasError?: boolean;
  readonly onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  readonly onEnter?: () => void;
  readonly onClear?: () => void;
}

const TopLabeledTextAreaComponent: React.ForwardRefRenderFunction<HTMLTextAreaElement, ITopLabeledTextArea> = (props, ref) => {
  const {
    label,
    value,
    className,
    hasError,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onClear,
    maxLength,
    inputMode = 'text',
    ...otherProps
  } = props;

  delete otherProps.onEnter;
  delete otherProps.type;
  delete otherProps.pattern;

  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const localOnFocus
    = React.useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]);
  const localOnBlur
    = React.useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur]);

  const clearButton
    = onClear && (value !== null && value !== undefined) && !isDisabled
      ? (
        <InputClearButton
          onClear={onClear}
        />
      )
      : null;

  const hasContent = value !== null && value !== undefined && value !== '';

  return (
    <Container
      className={className}
      hasError={hasError}
      disabled={isDisabled}
      hasFocus={hasFocus}
    >
      <Label
        hasError={hasError}
        minimizeLabel={hasFocus || hasContent}
      >
        {label}
      </Label>
      <TextAreaWrapper>
        <TextArea
          ref={ref}
          inputMode={inputMode}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          onBlur={localOnBlur}
          onFocus={localOnFocus}
          disabled={isDisabled}
          hasError={hasError}
          {...otherProps}
        />
      </TextAreaWrapper>
      {clearButton}
    </Container>
  );
};

export const TopLabeledTextArea = React.forwardRef(TopLabeledTextAreaComponent);

interface IError {
  hasError: boolean;
}

interface IContainer extends IError {
  disabled: boolean;
  hasFocus: boolean;
}

interface ILabel extends IError {
  readonly minimizeLabel: boolean;
}

const Container = styled.div<IContainer>`
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: border-color 100ms linear;
  border-radius: 4px;

  color: ${props => props.theme.input.default.color};
  border: ${props => props.theme.input.default.border};
  background-color: ${props => props.theme.input.default.backgroundColor};

  ${props => props.hasFocus && `
    border: ${props.theme.input.default.focus.border};
  `}

  ${props => props.disabled && `
    background-color: ${props.theme.input.default.disabled.backgroundColor};
    border: ${props.theme.input.default.disabled.border};
    color: ${props.theme.input.default.disabled.color};
    pointer-events: none;
  `}

  ${props => props.hasError && `
    border: ${props.theme.input.default.error.border};
  `}

  &:hover {
    border: ${props => (props.hasError ? props.theme.input.default.error : props.theme.input.default.focus).border};
  }
`;

const Label = styled.label<ILabel>`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: ${props => props.minimizeLabel ? 'translate(8px, 4px) scale(0.7)' : 'translate(8px, 11px) scale(1)'};
  transform-origin: top left;
  color: ${props => props.hasError ? props.theme.colorset.error2 : props.theme.colorset.grey400};
  font-size: 14px;
  font-weight: 600;
  font-family: ${props => props.theme.fontFamily};
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  height: calc(100% - 17px);
  padding-top: 17px;
  overflow-y: hidden;
`;

const TextArea = styled.textarea<IError>`
  padding: 0px 6px 1px 8px;
  border: unset;
  outline: unset;
  resize: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily};
  color: ${props =>
    (props.disabled
      ? props.theme.input.default.disabled
      : (props.hasError ? props.theme.input.default.error : props.theme.input.default)).color};
  box-sizing: border-box;
  font-weight: 600;
  width: 100%;
  height: 100%;
`;
