import React from 'react';
import styled from 'styled-components';
import { PopoverWithTip } from '../../../../utils/Popups/PopoverWithTip/PopoverWithTip';
import { IImageElement } from '../../models/elements';
import { updateElement } from '../../utils/updateElement';
import { PopupInput } from '../../components/Input';
import { ICustomEditor } from '../../models/editor';

export interface IImageElementPopoverProps {
  anchorEl: HTMLElement;
  open: boolean;
  handleClose: () => void;
  element: IImageElement;
  editor: ICustomEditor;
  srcInputPlaceholder: string;
  altInputPlaceholder: string;
}

export class ImageElementPopover extends React.Component<IImageElementPopoverProps> {
  constructor(props: IImageElementPopoverProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { anchorEl, open, handleClose, element, srcInputPlaceholder, altInputPlaceholder } = this.props;
    const { src, altText } = element;
    return (
      <PopoverWithTip
        tipAlignment="bottom-center"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Container>
          <PopupInput value={src} placeholder={srcInputPlaceholder} onChange={this.onChangeSrc} />
          <PopupInput value={altText} placeholder={altInputPlaceholder} onChange={this.onChangeAltText} />
        </Container>
      </PopoverWithTip>
    );
  }

  private onChangeSrc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const src = event.target.value;
    const newNodeValue: Partial<IImageElement> = {
      src,
    };
    this.updateNode(newNodeValue);
  };

  private onChangeAltText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const altText = event.target.value;
    const newNodeValue: Partial<IImageElement> = {
      altText,
    };
    this.updateNode(newNodeValue);
  };

  private updateNode = (newNodeValue: Partial<IImageElement>) => {
    const { element, editor } = this.props;
    updateElement(editor, element, newNodeValue);
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;
