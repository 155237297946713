import React from 'react';
import styled from 'styled-components';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';
import { LatexLabel } from '../LatexLabel';

export interface IHtmlLabelProps {
  htmlText: string;
  className?: string;
  latexStartTag?: string;
  latexEndTag?: string;
}

interface INode {
  readonly type: 'tag' | 'text';
  readonly name?: string; // for tag type only
  readonly attribs: { [key: string]: string };
  readonly children?: ReadonlyArray<INode>;
  readonly data?: string; // for text type only
  readonly next: INode;
  readonly prev: INode;
  readonly parent: INode;
}

export class HtmlLabel extends React.PureComponent<IHtmlLabelProps> {
  public render(): JSX.Element {
    const { htmlText, className } = this.props;

    if (!htmlText) {
      return null;
    }

    const parser = new Parser();
    const processNodeDefinitions = new ProcessNodeDefinitions(React);
    const processingInstructions: ReadonlyArray<any> = this.getProcessingInstructions(processNodeDefinitions);

    const content = parser.parseWithInstructions(
      this.htmlEncodeLatexContent(htmlText),
      () => true,
      processingInstructions);

    return (
      <Container
        className={className}
      >
        {content}
      </Container>
    );
  }

  private htmlEncodeLatexContent = (value: string): string => {
    const { latexStartTag = '[latex]', latexEndTag = '[/latex]' } = this.props;
    return value.split(latexStartTag).join(`ÿ${latexStartTag}`).split(latexEndTag).join(`${latexEndTag}ÿ`).split('ÿ').map((span: string) => {
      if (span.startsWith(latexStartTag) && span.endsWith(latexEndTag)) {
        return span.replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      }
      return span;
    }).join('');
  };

  private getProcessingInstructions = (processNodeDefinitions: any): ReadonlyArray<any> => {
    return [
      {
        shouldProcessNode(node: INode): boolean {
          return node.type === 'text';
        },
        processNode: (node: INode): ReadonlyArray<JSX.Element> => {
          return this.splitText(node.data);
        },
      },
      {
        shouldProcessNode(): boolean {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
  };

  private splitText = (text: string): JSX.Element[] => {
    const { latexStartTag = '[latex]', latexEndTag = '[/latex]' } = this.props;
    if (text.indexOf(latexStartTag) !== -1 || text.indexOf(latexEndTag) !== -1) {
      return text.split(latexStartTag).join(`ÿ${latexStartTag}`).split(latexEndTag).join(`${latexEndTag}ÿ`).split('ÿ').map((span: string, index) => {
        if (span.startsWith(latexStartTag) && span.endsWith(latexEndTag)) {
          return (
            <LatexLabel
              key={getKey(index, span)}
              latex={span.substring(latexStartTag.length, span.length - latexEndTag.length)}
            />
          );
        }
        return (
          <span
            key={getKey(index, span)}
          >
            {span}
          </span>
        );
      });
    }
    return [(
      <span
        key={getKey(0, text)}
      >
        {text}
      </span>
    )];
  };
}

const getKey = (index: number, text: string) => {
  return `${index}_${text.substring(0, 5)}`;
};

export const Container = styled.div`
`;
