import { DISMISS } from './SpecialKeys';
import { IKey } from '../../../keyboard/Keyboard.models';

type KeyboardElement = HTMLInputElement | HTMLTextAreaElement;

export const createKey = (key: string | number): IKey => {
  const stringKey = key.toString();
  return {
    value: stringKey,
    displayedValue: stringKey,
  };
};

export const processKeyEvent = (htmlElement: KeyboardElement, key: string) => {
  if (key === DISMISS) {
    htmlElement.blur();
  } else {
    sendKeyEvent(htmlElement, key);
  }
};

const sendKeyEvent = (htmlElement: KeyboardElement, key: string) => {
  const selectionStart = htmlElement.selectionStart;
  const newValue
    = htmlElement.value.substring(0, selectionStart)
    + key
    + htmlElement.value.substring(selectionStart);
  const nativeValueSetter = getNativeValueSetter(htmlElement);
  nativeValueSetter.call(htmlElement, newValue);
  const inputEvent = new Event('input', { bubbles: true });
  htmlElement.selectionStart = selectionStart + 1;
  htmlElement.selectionEnd = selectionStart + 1;
  htmlElement.dispatchEvent(inputEvent);
};

// https://stackoverflow.com/a/46012210
const getNativeValueSetter = (htmlElement: KeyboardElement): any => {
  switch (htmlElement.tagName) {
    case 'INPUT': return Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    case 'TEXTAREA': return Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
  }
  return null;
};
