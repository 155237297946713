import React from 'react';
import styled from 'styled-components';
import { ITheme } from '../../../themes/ITheme';

export interface IPillProps {
  readonly children?: React.ReactNode;
  readonly theme?: ITheme;
  readonly selected: boolean;
}

export const Pill: React.FC<IPillProps> = (
  {
    children,
    selected = false,
  },
) => {
  return (
    <PillWrapper selected={selected}>{children}</PillWrapper>
  );
};

export const PillWrapper = styled.label`
  align-self: center;
  background: ${(props: IPillProps) => (props.selected) ? props.theme.colorset.shadowAlt : props.theme.colorset.shadow};
  border-radius: ${(props: IPillProps) => props.theme.baseBorderRadius};
  display: block;
  font: ${(props: IPillProps) => props.theme.typeset.captionRegular};
  margin: 0 calc(${(props: IPillProps) => props.theme.baseVerticalSpacing} / 2);
  padding: ${(props: IPillProps) => props.theme.baseVerticalPadding} calc(${(props: IPillProps) => props.theme.baseHorizontalPadding} * 2);
`;
