import { ICustomEditor, ICustomElement } from '../models/editor';
import { IPlugin } from '../models/plugins';

export const withImages: IPlugin = (editor: ICustomEditor) => {
  const { isVoid, isInline } = editor;

  editor.isInline = (element: ICustomElement) => {
    return element.type === 'image' ? true : isInline(element);
  };

  editor.isVoid = (element: ICustomElement) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  return editor;
};
