import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox/Checkbox';
import { ISelectItemModel } from './ISelectItemModel';

export interface ICheckboxChildren {
  readonly values: ReadonlyArray<ISelectItemModel>;
  readonly filter: string;
  readonly onSaveSelected: (values: ReadonlyArray<string>) => void;
  readonly open: boolean;
  readonly preselected: ReadonlyArray<string>;
  readonly selectAllLabel: React.ReactNode;
  readonly unselectAllLabel: React.ReactNode;
  readonly disableIndentation?: boolean;
  className?: string;
}

export const CheckboxChildren: React.FC<ICheckboxChildren> = (props) => {
  const [previousSelection, setPreviousSelection] = React.useState <ReadonlyArray<string>>([]);

  const onSelectAll = () => {
    props.onSaveSelected(props.values.map(v => v.value));
  };
  const onUnselectAll = () => props.onSaveSelected([]);

  React.useEffect(() => {
    if (!props.open) {
      setPreviousSelection([]);
    } else {
      setPreviousSelection(props.preselected);
    }
  }, [props.open]);

  const preselectedValues = React.useMemo(() => {
    return props.values.filter((value) => {
      return props.preselected.includes(value.value) && previousSelection.includes(value.value);
    });
  }, [props.values, props.preselected, previousSelection]);

  const otherValues = React.useMemo(() => {
    return props.values.filter((value) => {
      return !props.preselected.includes(value.value)
        || (props.preselected.includes(value.value) && !previousSelection.includes(value.value))
        || false;
    });
  }, [props.values, props.preselected, previousSelection]);

  const preselectedElements = React.useMemo(() => {
    // Add parent if not selected or not selectable
    const parents: string[] = [];
    const newPreselectedValues: ISelectItemModel[] = [];

    preselectedValues.forEach((item) => {
      if (item.parent !== null && !parents.includes(item.parent)) {
        parents.push(item.parent);
        newPreselectedValues.push(props.values.find((element) => {
          return element.value === item.parent;
        }));
      }
      newPreselectedValues.push(item);
    });

    return renderItems(newPreselectedValues, props.preselected, props.filter, props.onSaveSelected, props.disableIndentation);
  }, [preselectedValues, props.filter, props.preselected, props.disableIndentation]);

  const otherElements = React.useMemo(() => {
    return renderItems(otherValues, props.preselected, props.filter, props.onSaveSelected, props.disableIndentation);
  }, [otherValues, props.filter, props.preselected, props.disableIndentation]);

  const divider = preselectedElements.length > 0 && otherElements.length > 0 ? <StyledHR /> : null;

  return (
    <Container>
      {props.filter.length > 0
        ? null
        : (
          <>
            <ButtonSection>
              <TextButton onClick={onSelectAll}>{props.selectAllLabel}</TextButton>
              <span> / </span>
              <TextButton onClick={onUnselectAll}>{props.unselectAllLabel}</TextButton>
            </ButtonSection>
            <StyledHR />
          </>
        )}
      <ItemsContainer className={props.className}>
        <ul>
          {preselectedElements}
        </ul>
        {divider}
        <ul>
          {otherElements}
        </ul>
      </ItemsContainer>
    </Container>
  );
};

const renderItems = (
  values: ReadonlyArray<ISelectItemModel>,
  selected: ReadonlyArray<string>,
  filter: string,
  onSelect: (values: ReadonlyArray<string>) => void,
  disableIndentation: boolean = false,
) => {
  const items = values.filter(e => (new RegExp(filter, 'i')).test(e.label));
  return items.map((e) => {
    const checked = selected.some(s => s === e.value);
    const onChange = () => {
      if (checked) {
        // uncheck all children
        const children = items.filter(item => (item.parent === e.value)).map(si => si.value);
        onSelect(selected.filter(s => (s !== e.value !== children.includes(s))));
      } else {
        // check all children
        const children = items.filter((item) => {
          return item.parent === e.value;
        });
        onSelect([...selected, e.value, ...children.map(si => si.value)]);
      }
    };

    if (e.selectable) {
      // Highlight filter text in the label
      const match = new RegExp(filter, 'i').exec(e.label);
      let filterIndex = match.index;
      filterIndex = filterIndex === -1 ? 0 : filterIndex;
      const pre = e.label.substr(0, filterIndex);
      const sel = e.label.substr(filterIndex, match[0].length);
      const post = e.label.substr(filterIndex + filter.length);

      const label = (
        <RichLabel>
          {e.icon}
          <span>
            {pre}
            <b>
              {sel}
            </b>
            {post}
          </span>
        </RichLabel>
      );

      return (
        <StyledLi key={e.value}>
          <StyledCheckbox
            checked={checked}
            Label={label}
            onChange={onChange}
            level={(!disableIndentation && e.parent != null) ? 1 : 0}
            data-testid={e.testid}
          />
        </StyledLi>
      );
    }
    return (
      <RootItem key={e.value}>{e.label}</RootItem>
    );
  });
};

const StyledHR = styled.div`
  background-color: ${props => props.theme.colorset.grey100};
  margin: 8px 0;
  height: 1px;
`;

const ButtonSection = styled.section`
  padding: 8px 0 0 8px;
`;

const RootItem = styled.div`
  font: ${props => props.theme.typeset.captionRegular};
  color: ${props => props.theme.colorset.grey400};
  margin: 8px 0 6px ;
`;

const TextButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0;
  font: ${props => props.theme.typeset.body2Bold};
  cursor: pointer;
  display: inline;
  color: ${props => props.theme.colorset.primary};
  font-weight: bold;
`;

interface ICheckboxProps {
  level: number;
}

const StyledLi = styled.li`
  margin: 2px 0;
`;

const StyledCheckbox = styled(Checkbox)<ICheckboxProps>`
  font: ${props => props.theme.typeset.body2Regular};
  padding-left: calc(${props => props.level} * 12px);
`;

const ItemsContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 0 8px 8px ;
  margin-top: 8px;

  ul {
    padding: 0;
    margin: 0;

    & > :first-child {
      margin-top: 0;
    }
  }

  ul > :first-child {
    margin-top: 0;
  }

  li {
    list-style: none;
    line-height: 24px;
    margin: 2px 0;
  }
`;

const RichLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;
