import React from 'react';
import styled from 'styled-components';
import { PopoverWithTip } from '../../../../utils/Popups/PopoverWithTip/PopoverWithTip';
import AddIcon from '../../../../../assets/add.svg';
import { Button } from '../../../Button/Button';
import { PopupInput } from '../../components/Input';

interface IAddImagePopoverProps {
  anchorEl: HTMLElement;
  open: boolean;
  srcInputPlaceholder: string;
  altInputPlaceholder: string;
  handleClose: () => void;
  onAddImage: (src: string, altText: string) => void;
}

interface IAddImagePopoverState {
  src: string;
  altText: string;
}

export class AddImagePopover extends React.Component<IAddImagePopoverProps, IAddImagePopoverState> {
  constructor(props: IAddImagePopoverProps) {
    super(props);
    this.state = {
      src: '',
      altText: '',
    };
  }

  public render(): React.ReactNode {
    const { anchorEl, open, handleClose, srcInputPlaceholder, altInputPlaceholder } = this.props;
    const { src, altText } = this.state;
    return (
      <PopoverWithTip
        tipAlignment="bottom-right"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Container>
          <SizedPopupInput
            value={src}
            placeholder={srcInputPlaceholder}
            onChange={this.onChangeSrc}
          />
          <SizedPopupInput
            value={altText}
            placeholder={altInputPlaceholder}
            onChange={this.onChangeAltText}
          />
          <Button
            onClick={this.onAddImage}
            icon
          >
            <AddIcon />
          </Button>
        </Container>
      </PopoverWithTip>
    );
  }

  private closePopup = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  private onAddImage = () => {
    const { src, altText } = this.state;
    this.props.onAddImage(src, altText);
    this.closePopup();
  };

  private onChangeSrc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const src = event.target.value;
    this.setState({
      src,
    });
  };

  private onChangeAltText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const altText = event.target.value;
    this.setState({
      altText,
    });
  };
}

const SizedPopupInput = styled(PopupInput)`
  width: 158px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;
