import { DismissKey, NoopKey } from '../Keys/SpecialKeys';
import { createKey } from '../Keys/utils';
import { IKeyboard } from '../../../keyboard/Keyboard.models';

const ALL_CHARACTERS = '×±÷−⋅∘~≅∀∃∞∟∠≤≥≠≈∼≙∥∦|∧∨∪∩∖∆∈∉∅⊆⊂←→⇔⬚≟⊥ℕℤℚℝ•▪◦ôêûâîᵉʳ°$¢€αβγδεηθλμνπρφψΩΣΔ';
const NB_ROWS = 4;
const charactersPerRow = Math.ceil(ALL_CHARACTERS.length / NB_ROWS);

export const specialCharactersKeyboard: IKeyboard = {
  sections: [
    {
      rows: Array.from({ length: NB_ROWS }).map((_, rowIndex) => {
        return {
          keys: Array.from({ length: charactersPerRow }).map((__, colIndex) => {
            const key = ALL_CHARACTERS[(rowIndex * charactersPerRow) + colIndex];
            if (key) {
              return createKey(key);
            }
            return NoopKey;
          }),
        };
      }),
    },
  ],
  actionSection: {
    rows: [
      {
        keys: [
          DismissKey,
        ],
      },
    ],
  },
};
