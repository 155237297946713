import { FetchResponse, publisherApi } from '../../utils/fetch';

export interface IPageContentRenewLockResponse {
  pageGuid: string;
  status: 'acquired' | 'failed';
  lockedBy: string;
}

export const pageContentRenewLock = async (pageGuid: string,
                                           editionWindowId: string): Promise<FetchResponse<IPageContentRenewLockResponse>> => {
  return publisherApi<IPageContentRenewLockResponse>(`/pages/${pageGuid}/content/renew-lock?editionWindowId=${editionWindowId}`, 'POST');
};
