import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { JoinImpl } from '../../funcs/str/JoinImpl';
import { WListOfMarkup } from '../../elements/tokens/WListOfMarkup';

/**
 * Joint une liste de nombres avec un séparateur.
 */
export class Join extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let separator: WString = args.getString(1);
    if (!separator && args.length === 2) {
      return null;
    }
    if (!separator) {
      separator = new WString('');
    }

    if (args.getStrings(0)) {
      return this.strings(args.getStrings(0), separator);
    }
    if (args.getReals(0)) {
      return this.reals(args.getReals(0), separator);
    }
    if (args.getMarkups(0)) {
      return this.markups(args.getMarkups(0), separator);
    }

    return null;
  }

  private strings(values: WListOfString, separator: WString): WString {
    return new WString(
      values.toStrings().join(separator.getString()),
      null,
      WString.getCombinedSource(values.getSource(), separator.getSource()));
  }

  private reals(values: WList, separator: WString): TokenElement {
    if (values.count === 0) {
      return new WString('');
    }
    const s: any[] = this.toStringList(values);
    if (s) {
      return new WString(s.join(separator.getString()), null, separator.getSource());
    }
    return new WMarkup(new JoinImpl(values, separator.getString()));
  }

  private toStringList(values: WList): any[] {
    const o: any[] = [];
    for (let i: number = 0; i < values.count; i++) {
      const s: string = values.getItemAt(i).toText(true);
      if (s) {
        o.push(s);
      } else {
        return null;
      }
    }
    return o;
  }

  private markups(values: WListOfMarkup, separator: WString): TokenElement {
    if (values.count === 0) {
      return new WString('');
    }
    return new WMarkup(new JoinImpl(values, separator.getString()));
  }
}
