import React from 'react';
import { KeyboardIcon } from '@scolab/publisher-ui-kit';
import { ICustomEditor } from '../../models/editor';
import { Button } from '../../../Button/Button';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IKeyboardControlProps {
  editor: ICustomEditor;
  label: string;
}

export const KeyboardControl: React.FC<IKeyboardControlProps> = (props) => {
  const { editor, label } = props;
  const onOpenKeyboard = (event: React.MouseEvent) => {
    event.preventDefault();
    editor.toggleKeyboard();
  };

  return (
    <Tooltip
      title={label}
    >
      <Button
        onMouseDown={onOpenKeyboard}
        aria-label={label}
        icon
      >
        <KeyboardIcon />
      </Button>
    </Tooltip>
  );
};
