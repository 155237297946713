import { IPageContent } from '@scolab/content-model';
import { FetchResponse, publisherApi } from '../../utils/fetch';

export interface IPageContentGetContentResponse {
  pageGuid: string;
  pageLock: {
    pageGuid: string;
    status: 'acquired' | 'failed';
    lockedBy: string;
  };
  pageVersion: {
    copyType: 'publishedCopy' | 'draftCopy';
    version: number | null;
    dateModified: string;
    contentFormat: 'json';
  };
  pageContent: IPageContent;
}

export const pageContentGetPageContentLatestCopy = async (pageGuid: string,
                                                          editionWindowId: string): Promise<FetchResponse<IPageContentGetContentResponse>> => {
  return publisherApi<IPageContentGetContentResponse>(`/pages/${pageGuid}/content?editionWindowId=${editionWindowId}`);
};

export const pageContentGetPageContentVersion = async (pageGuid: string,
                                                       version: number,
                                                       editionWindowId: string): Promise<IPageContentGetContentResponse> => {
  const copyType = 'draftCopy'; // When requesting a specific page content version, use the draftCopy type
  const response = await publisherApi<IPageContentGetContentResponse>(`/pages/${pageGuid}/content/versions/${version}/${copyType}?editionWindowId=${editionWindowId}`);
  return response.json();
};
