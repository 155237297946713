import React from 'react';
import styled from 'styled-components';
import { Editor, Range, RangeRef } from '../../TypedSlate';
import { ICustomEditor } from '../../models/editor';
import { ColorPicker } from '../../../ColorPicker/ColorPicker';
import { IPresetColorGroup } from '../../../ColorPicker/models';
import { IClassDefinition } from '../../models/configuration';

interface IGenericColorControlProps {
  editor: ICustomEditor;
  colorPresets: ReadonlyArray<IPresetColorGroup>;
  Label: React.ReactNode;
  classes: ReadonlyArray<IClassDefinition>;
  setColorValue: (editor: ICustomEditor, classes: ReadonlyArray<IClassDefinition>, value: string, selection?: Range) => void;
  getColorValue: (editor: ICustomEditor, classes: ReadonlyArray<IClassDefinition>, selection?: Range) => string;
  className?: string;
}

export const GenericColorControl: React.FC<IGenericColorControlProps> = (props) => {
  const {
    editor,
    colorPresets,
    Label,
    classes,
    setColorValue,
    getColorValue,
    className,
  } = props;
  const selectionRefRef = React.useRef<RangeRef>(null); // Name is not a typo. It's a react ref of a slate ref.
  const onChange = (value: string) => {
    setColorValue(editor, classes, value, selectionRefRef.current.current);
  };
  const onChangeOpen = (open: boolean) => {
    if (open) {
      const rangeRef = Editor.rangeRef(editor, editor.selection);
      selectionRefRef.current = rangeRef;
    } else {
      selectionRefRef.current.unref();
      selectionRefRef.current = null;
    }
  };
  const onMouseDown = (event: React.MouseEvent) => {
    const isInInput = (event.target as HTMLElement).nodeName === 'INPUT';
    if (!isInInput) {
      event.preventDefault();
    }
  };
  return (
    <StyledColorPicker
      colorPresets={colorPresets}
      selectedColor={getColorValue(editor, classes, selectionRefRef.current?.current)}
      onChange={onChange}
      onChangeOpen={onChangeOpen}
      Label={Label}
      onMouseDown={onMouseDown}
      className={className}
    />
  );
};

const StyledColorPicker = styled(ColorPicker)`
  width: 180px;
`;
