import {
  DragSourceMonitor,
  DropTargetMonitor,
  Matching,
  GetProps,
  useDrag,
  useDrop,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import React from 'react';
import { ISortableListContext, SortableListContext } from './SortableListContext';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';

export interface IDragItem {
  readonly type: string;
  readonly index: number;
}

export interface IWrappedDragChild {
  readonly handleRef: React.MutableRefObject<HTMLDivElement>;
  readonly containerRef: React.MutableRefObject<HTMLDivElement>;
  readonly index: number;
  readonly isAnItemDragged: boolean;
  readonly isDragging: boolean;
  readonly onSelectItem: (event: React.MouseEvent) => void;
  readonly afterSelectItem: (event: React.MouseEvent) => void;
}

const Wrapper: React.FC<IDragItem & ISortableListContext & { readonly WrappedComponent: any }> = (props) => {
  const {
    type,
    index,
    onDragStart,
    onDragEnd,
    onChangeDropTarget,
    onSelectItem,
    afterSelectItem,
    WrappedComponent,
    children,
    ...otherProps
  } = props;

  const handleRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isReadonly = React.useContext(ReadonlyContext);

  const [{ isAnItemDragged, isDragging }, drop] = useDrop({
    accept: type,
    hover(_, monitor: DropTargetMonitor): void {
      if (!containerRef.current) {
        return;
      }

      const hoverBoundingRect = containerRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (hoverClientY < hoverMiddleY) {
        onChangeDropTarget(index);
      } else {
        onChangeDropTarget(index + 1);
      }
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        isAnItemDragged: monitor.getItem()?.type === type,
        isDragging: monitor.getItem()?.index === index,
      };
    },
  });
  const [, drag, preview] = useDrag({
    item: { index, type },
    canDrag: !isReadonly,
    begin: (monitor: DragSourceMonitor) => {
      onDragStart(index);
    },
    end: () => {
      onDragEnd();
    },
  });

  drag(handleRef);
  preview(drop(containerRef));
  const wrappedOnSelectItem = React.useCallback((event: React.MouseEvent) => onSelectItem(index, event), [onSelectItem, index]);
  const wrappedAfterSelectItem = React.useCallback((event: React.MouseEvent) => afterSelectItem(index, event), [afterSelectItem, index]);

  const TypedWrappedComponent = WrappedComponent as React.FC<IWrappedDragChild>;
  return (
    <TypedWrappedComponent
      handleRef={handleRef}
      containerRef={containerRef}
      index={index}
      isAnItemDragged={isAnItemDragged}
      isDragging={isDragging}
      onSelectItem={wrappedOnSelectItem}
      afterSelectItem={wrappedAfterSelectItem}
      {...otherProps}
    >
      {children}
    </TypedWrappedComponent>
  );
};

const MemoizedWrapper = React.memo(Wrapper);
type ISortableListItemWrapperProps<C> = Omit<GetProps<C>, keyof IWrappedDragChild> & React.PropsWithChildren<IDragItem>;

export const SortableListItemWrapper = <C extends React.ComponentType<Matching<IWrappedDragChild, GetProps<C>>>>(Component: C): React.FC<ISortableListItemWrapperProps<C>> => {
  const Context = (props: ISortableListItemWrapperProps<C>) => (
    <SortableListContext.Consumer>
      {({
        onDragStart,
        onDragEnd,
        onChangeDropTarget,
        onSelectItem,
        afterSelectItem,
      }) => (
        <MemoizedWrapper
          {...props}
          WrappedComponent={Component}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onChangeDropTarget={onChangeDropTarget}
          onSelectItem={onSelectItem}
          afterSelectItem={afterSelectItem}
        />
      )}
    </SortableListContext.Consumer>
  );
  Context.displayName = `SortableListItemWrapper(${Component.displayName})`;
  return Context;
};
