import React from 'react';
import styled from 'styled-components';
import XIcon from '../../../assets/x.svg';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';
import { Tooltip } from '../../utils/Tooltip/Tooltip';

export interface ILeftLabeledInput extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly showClearButton?: boolean;
  readonly label: React.ReactNode;
  readonly clearLabel?: React.ReactNode;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onClear?: () => void;
  readonly placeholder?: string;
}

const LeftLabeledInputComponent: React.FC<ILeftLabeledInput> = ({
  label,
  value,
  className,
  disabled,
  placeholder,
  showClearButton,
  onChange,
  onClear,
  clearLabel = 'Clear',
  ...otherProps
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [inputValue, setInputValue] = React.useState(value);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const localOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  }, [onChange]);

  const localOnClear = React.useCallback(() => {
    setInputValue('');

    if (onClear) {
      onClear();
    } else {
      onChange(
        {
          target: { ...inputRef.current, value: '' },
        } as React.ChangeEvent<HTMLInputElement>,
      );
    }
  }, []);

  const clearButton = showClearButton && !isDisabled && inputValue !== ''
    ? (
      <Tooltip title={clearLabel ?? 'Clear'}>
        <ClearButton onClick={localOnClear}><XIcon /></ClearButton>
      </Tooltip>
    )
    : null;

  const labelElement = label
    ? (
      <Label>
        {label}
      </Label>
    )
    : null;

  return (
    <Container className={className} disabled={isDisabled}>
      {labelElement}
      {clearButton}
      <Input
        ref={inputRef}
        type="text"
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={localOnChange}
        value={inputValue}
        {...otherProps}
      />
    </Container>
  );
};
export const LeftLabeledInput = React.memo(LeftLabeledInputComponent);

interface IContainer {
  disabled: boolean;
}

const Container = styled.div<IContainer>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colorset.grey13};
  background-color: ${props => props.theme.colorset.grey03};
  font-size: 1em;
  line-height: 1.25em;
  opacity: ${props => props.disabled ? 0.5 : 1};
`;

const ClearButton = styled.button`
  top: 0;
  bottom: 0;
  margin: auto;
  right: 7px;
  position: absolute;
  z-index: 1;
  background-color: ${props => props.theme.colorset.grey800};
  border: none;
  border-radius: 9px;
  color: white;
  height: 18px;
  width: 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1px 0 0;
  font-size: 16px;
  cursor: pointer;

  svg {
    color: ${props => props.theme.colorset.white};
    fill: ${props => props.theme.colorset.white};
    width: 16px;
    height: 16px;
  }
`;

const Label = styled.label`
  color: ${props => props.theme.colorset.grey10};
  margin-right: 6px;
`;

const Input = styled.input`
  border: unset;
  outline: unset;
  background-color: transparent;
  color: ${props => props.theme.colorset.grey13};
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
`;
