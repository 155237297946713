import isUrl from 'is-url';
import { Editor, Element, NodeEntry, Text, Transforms } from '../TypedSlate';
import { isLink, wrapLink } from '../utils/linkUtils';
import { ICustomEditor, ICustomElement } from '../models/editor';
import { IPlugin } from '../models/plugins';

export const withLinks: IPlugin = (editor: ICustomEditor) => {
  const { insertData, insertText, isInline, normalizeNode } = editor;

  editor.isInline = (element: ICustomElement) => {
    return isLink(element) ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && isLink(node)) {
      const containsOnlyEmptyText = node.children.length === 1
        && Text.isText(node.children[0])
        && node.children[0].text === '';
      if (containsOnlyEmptyText) {
        Transforms.removeNodes(editor, {
          at: path,
          match: n => !Editor.isEditor(n) && Element.isElement(n) && isLink(n),
        });
      }
    }
    normalizeNode(entry);
  };

  return editor;
};
