import React from 'react';
import styled from 'styled-components';
import { ITheme } from '../../../themes/ITheme';
import { LeftLabeledInput } from '../LeftLabeledInput/LeftLabeledInput';

export interface IDatePicker extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly clearLabel?: React.ReactNode;
  readonly className?: string;
  readonly hasError?: boolean;
  readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onClear?: () => void;
  readonly variant?: 'outlined' | 'filled';
}

export const DatePicker: React.FC<IDatePicker> = ({
  value,
  className,
  hasError,
  disabled,
  onBlur,
  onFocus,
  onChange,
  onClear,
  clearLabel = 'Clear',
  variant = 'outlined',
  ...otherProps
}) => {
  const [hasFocus, setHasFocus] = React.useState(false);

  const localOnFocus = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  }, [onFocus]);
  const localOnBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  }, [onBlur]);
  const localOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  }, [onChange]);

  const Container = variant === 'outlined' ? OutlinedContainer : FilledContainer;

  return (
    <Container className={className} hasError={hasError} disabled={disabled} hasFocus={hasFocus}>
      <div>
        <Input
          label=""
          type="date"
          value={value}
          onChange={localOnChange}
          onBlur={localOnBlur}
          onFocus={localOnFocus}
          hasError={hasError}
          disabled={disabled}
          clearLabel={clearLabel}
          onClear={onClear}
          showClearButton
          {...otherProps}
        />
      </div>
    </Container>
  );
};

interface IError {
  hasError: boolean;
}

interface IContainer extends IError {
  disabled: boolean;
  hasFocus: boolean;
}

const getBorderColor = (props: IContainer & { theme: ITheme }) => {
  if (props.hasError) {
    return props.theme.colorset.error;
  }
  if (props.hasFocus) {
    return props.theme.colorset.grey12;
  }
  return props.theme.colorset.grey09;
};

const CommonContainer = styled.div<IContainer>`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  vertical-align: top;
  opacity: ${props => props.disabled ? 0.5 : 1};
  transition: border-color 100ms linear;

  &:hover {
    border-color: ${props => props.theme.colorset.grey12};
  }
`;

const OutlinedContainer = styled(CommonContainer)`
  border-radius: 4px;
  border: 1px solid ${getBorderColor};
  background-color: ${props => props.theme.colorset.grey03};
`;

const FilledContainer = styled(CommonContainer)`
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${getBorderColor};
  background-color: ${props => props.theme.colorset.grey04};
`;

const Input = styled(LeftLabeledInput)<IError>`
  padding: 6px;
  border: unset;
  outline: unset;
  background-color: transparent;
  color: ${props => props.hasError ? props.theme.colorset.error : props.theme.colorset.grey400};
  width: 100%;
  box-sizing: border-box;
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;
