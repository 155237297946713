import React from 'react';
import styled from 'styled-components';
import { useSlate } from '../../TypedSlate';
import { ICustomRenderElementProps } from '../../models/editor';
import { ITTSElement } from '../../models/elements';
import { TTSPopper } from './TTSPopper';
import { isSelectionWithin, hasDescendantWithPopup } from '../../utils/selectionUtils';
import AudioIcon from '../../../../../assets/volume.svg';

interface ITTSElementProps {
  ttsInputPlaceholder: string;
}

export const TTSElement: React.FC<ICustomRenderElementProps<ITTSElement> & ITTSElementProps> = (props) => {
  const editor = useSlate();
  const anchorRef = React.useRef(null);
  const selected = isSelectionWithin(editor, props.element);
  const popupConflict = hasDescendantWithPopup(editor, props.element);
  const [popperHasFocus, setPopperHasFocus] = React.useState(false);
  const onPopperFocus = () => {
    setPopperHasFocus(true);
  };
  const onPopperBlur = () => {
    setPopperHasFocus(false);
  };
  return (
    <>
      <Container
        {...props.attributes}
        ref={anchorRef}
      >
        <StyledAudioIcon />
        {props.children}
      </Container>
      <TTSPopper
        open={(selected || popperHasFocus) && !popupConflict}
        anchorEl={anchorRef.current}
        element={props.element}
        editor={editor}
        onClickInside={onPopperFocus}
        onClickOutside={onPopperBlur}
        ttsInputPlaceholder={props.ttsInputPlaceholder}
      />
    </>
  );
};

const FIFTEEN_PERCENT_HEX = Math.floor(256 * 0.15).toString(16);

const Container = styled.span`
  background-color: #FF45A2${FIFTEEN_PERCENT_HEX};
  padding: 0px 0.66em 0px 1.5em;
  border-radius: 0.66em;
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 1.33em;
`;

const StyledAudioIcon = styled(AudioIcon)`
  position: absolute;
  height: 1em;
  width: 1em;
  color: #E9157F;
  top: 50%;
  left: 0.33em;
  transform: translateY(-50%);
`;
