import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { TreeContext } from './TreeContext';
import {
  TREE_ARROW_ICON_CLASS_NAME,
  TREE_BRANCH_CLASS_NAME,
  TREE_HEADER_CLASS_NAME,
  TREE_LEAF_CLASS_NAME,
} from './theme';
import { Collapsable } from '../../layout/Collapsable';
import CaretRightIcon from '../../../assets/caret-right.svg';
import { InsertButtonContainer, CheckboxContainer } from './Tree.styles';
import { Checkbox } from '../../inputs/Checkbox/Checkbox';
import { DragDropTreeNode } from './DragDropTreeNode';
import { Tooltip } from '../../utils/Tooltip/Tooltip';

export interface ITreeNodeProps<TNode = any> {
  node: TNode;
  ancestors: ReadonlyArray<TNode>;
  depth: number;
  indent: number;
  gap: number;
  verticalBars: boolean;
  closeTooltip?: React.ReactNode;
  openTooltip?: React.ReactNode;
  onSelect: (nodeId: string) => void;
  onToggle: (nodeId: string) => void;
  onDoubleClick: (nodeId: string) => void;
  onToggleChecked: (nodeId: string) => void;
  onMouseEnterNode?: (node: any) => void;
  onMouseLeaveNode?: (node: any) => void;
}

export const TREE_NODE_CHILDREN_CONTAINER = 'TREE_NODE_CHILDREN_CONTAINER';

export const TreeNode: React.FC<ITreeNodeProps> = (props) => {
  const treeContext = React.useContext(TreeContext);
  const {
    dataDescriptor,
    nodeRenderer,
    insertNodeEnabled,
    insertButtonRenderer,
    onInsertChild,
    checkboxVisible,
    isChecked,
    isPartiallyChecked,
    dragEnabled,
    dropEnabled,
    onDropAction,
    onMoveAction,
  } = treeContext;

  const {
    node,
    ancestors,
    depth,
    indent,
    gap,
    verticalBars,
    closeTooltip = 'Close',
    openTooltip = 'Open',
    onSelect,
    onToggle,
    onDoubleClick,
    onToggleChecked,
    onMouseEnterNode,
    onMouseLeaveNode,
  } = props;

  const [showMarginBelow, setShowMarginBelow] = React.useState(false);
  const nodeId = dataDescriptor.getNodeId(node, ancestors);
  const isTemporaryNode = nodeId === null;

  const isOpen = treeContext.isOpen(nodeId);
  const isSelected = treeContext.isSelected(nodeId);

  const isHeader = dataDescriptor.isHeader(node);
  const isBranch = dataDescriptor.isBranch(node);

  const BorderComponent
    = isHeader
      ? Header
      : (isBranch ? Branch : TreeNodeLeaf);

  const className
    = isHeader
      ? TREE_HEADER_CLASS_NAME
      : (isBranch ? TREE_BRANCH_CLASS_NAME : TREE_LEAF_CLASS_NAME);

  const childAncestors = ancestors.concat(node);
  const children
    = dataDescriptor.hasChildren(node)
      ? (
        isOpen
          ? (
            dataDescriptor.getChildren(node).map((child) => {
              const childId = dataDescriptor.getNodeId(child, childAncestors);
              return (
                <TreeNode
                  key={childId ?? uuidv4()}
                  node={child}
                  ancestors={childAncestors}
                  depth={depth + 1}
                  indent={indent}
                  gap={gap}
                  verticalBars={verticalBars}
                  closeTooltip={closeTooltip}
                  openTooltip={openTooltip}
                  onSelect={onSelect}
                  onToggle={onToggle}
                  onDoubleClick={onDoubleClick}
                  onToggleChecked={onToggleChecked}
                  onMouseEnterNode={onMouseEnterNode}
                  onMouseLeaveNode={onMouseLeaveNode}
                />
              );
            })
          )
          : []
      )
      : null;

  const onSelectLocal
    = onSelect
      ? (event: React.MouseEvent) => {
        if (!isTemporaryNode) {
          onSelect(nodeId);
        }
        event.stopPropagation();
      }
      : null;

  const onToggleLocal
    = onToggle
      ? (event: React.MouseEvent) => {
        if (!isTemporaryNode) {
          onToggle(nodeId);
        }
        event.stopPropagation();
      }
      : null;

  const onDropHoldLocal = () => {
    if (!isTemporaryNode && !isOpen && (isBranch || isHeader)) {
      onToggle(nodeId);
    }
  };

  const onDoubleClickLocal
    = onDoubleClick
      ? (event: React.MouseEvent) => {
        if (!isTemporaryNode) {
          onDoubleClick(nodeId);
        }
        event.stopPropagation();
      }
      : null;

  const onToggleCheckedLocal
    = onToggleChecked
      ? (checked: boolean, event?: React.MouseEvent) => {
        if (!isTemporaryNode) {
          onToggleChecked(nodeId);
        }
        event.stopPropagation();
      }
      : null;

  const onMouseEnterLocal = onMouseEnterNode
    ? () => onMouseEnterNode(node)
    : null;

  const onMouseLeaveLocal = onMouseLeaveNode
    ? () => onMouseLeaveNode(node)
    : null;

  const tooltipLabel = isOpen
    ? closeTooltip
    : openTooltip;

  const icon
    = (isHeader || isBranch)
      ? (
        <Tooltip title={tooltipLabel}>
          <ArrowIconWrapper
            data-testid={TREE_ARROW_ICON_CLASS_NAME}
            className={TREE_ARROW_ICON_CLASS_NAME}
            isOpen={isOpen}
            isGhost={isTemporaryNode}
            onClick={onToggleLocal}
          >
            <CaretRightIcon />
          </ArrowIconWrapper>
        </Tooltip>
      )
      : null;

  const ContentContainerComponent
    = isHeader
      ? HeaderContent
      : BranchContent;

  const indentInPixels = indent * depth + (checkboxVisible ? 40 : 0);

  const content
    = children
      ? (
        <StyledCollapsable
          isOpen={isOpen}
          indentInPixels={indentInPixels}
          isSelected={isSelected}
          verticalBars={verticalBars}
        >
          <ContentContainerComponent
            gap={gap}
            data-testid={TREE_NODE_CHILDREN_CONTAINER}
          >
            {children}
          </ContentContainerComponent>
        </StyledCollapsable>
      )
      : null;

  const onInsertChildLocal = () => {
    if (isTemporaryNode) return;
    if (!isOpen) {
      onToggle(nodeId);
    }
    onInsertChild(node);
  };

  const insertButtonVisible = !isTemporaryNode && insertNodeEnabled && isOpen;

  const insertButton
    = insertButtonVisible
      ? (
        <InsertButtonContainer>
          {insertButtonRenderer(node, onInsertChildLocal)}
        </InsertButtonContainer>
      )
      : null;

  const canBeDragged = dataDescriptor.canBeDragged ? dataDescriptor.canBeDragged(node, ancestors) : true;

  const header = (
    <DragDropTreeNode
      node={node}
      ancestors={ancestors}
      id={nodeId}
      ownerId={dataDescriptor.getOwnerId()}
      dragEnabled={dragEnabled && canBeDragged}
      dropEnabled={dropEnabled}
      onDropAction={onDropAction}
      onMoveAction={onMoveAction}
      onDropHold={onDropHoldLocal}
      marginLeft={insertNodeEnabled ? 40 : 0}
      marginRight={indentInPixels}
      onDropAfter={setShowMarginBelow}
    >
      <BorderComponent
        data-testid="borderComponent"
        isOpen={isOpen}
        isGhost={isTemporaryNode}
        isSelected={isSelected}
        isInserting={insertNodeEnabled}
        className={className}
        onClick={onSelectLocal}
        onDoubleClick={onDoubleClickLocal}
        indent={indentInPixels}
        onMouseEnter={onMouseEnterLocal}
        onMouseLeave={onMouseLeaveLocal}
      >
        {icon}
        {nodeRenderer(node, isOpen, isSelected)}
      </BorderComponent>
    </DragDropTreeNode>
  );

  const checked
    = !isTemporaryNode && isChecked(nodeId)
      ? true
      : (isPartiallyChecked(nodeId) ? 'partial' : false);

  const checkbox
    = !isTemporaryNode && checkboxVisible
      ? (
        <CheckboxContainer>
          <Checkbox
            checked={checked}
            onChange={onToggleCheckedLocal}
          />
        </CheckboxContainer>
      )
      : null;

  return (
    <Container
      data-testid={`TreeNodeContainer${nodeId ?? ''}`}
      showMarginBelow={showMarginBelow}
    >
      {checkbox}
      {header}
      {insertButton}
      {content}
    </Container>
  );
};

interface IOpen {
  readonly isOpen: boolean;
}

interface IGhost {
  readonly isGhost: boolean;
}

interface ISelectable {
  readonly isSelected: boolean;
}

interface IIndentable {
  readonly indent: number;
}

interface ISpacing {
  readonly gap: number;
}

interface IInsertable {
  readonly isInserting: boolean;
}

type NodeHeaderPropsType = IGhost & IOpen & ISelectable & IIndentable;

interface IContainer {
  showMarginBelow: boolean;
}

const Container = styled.div<IContainer>`
  width: 100%;
  gap: 0px;
  ${props => props.showMarginBelow && `
    margin-bottom: 40px;
  `}
`;

const HeaderContent = styled.div<ISpacing>`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.gap}px;
  padding-bottom: ${props => props.gap * 2}px;
  padding-right: 16px;
  gap: ${props => props.gap}px;
`;

const BranchContent = styled.div<ISpacing>`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.gap}px;
  gap: ${props => props.gap}px;
`;

export const Item = styled.div<IInsertable>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: ${props => props.isInserting ? '40px' : null};
`;

const Header = styled(Item)<NodeHeaderPropsType>`
  opacity: ${props => props.isGhost ? 0.5 : null};
  padding: 8px;
  border-radius: 0;
  font: ${props => props.theme.typeset.body1SemiBold};
  font-size: 16px;
  text-transform: uppercase;
  color: ${props =>
    props.isSelected && props.theme.tree.header.selected
      ? props.theme.tree.header.selected.color
      : props.theme.tree.header.color};
  background-color: ${(props) => {
    if (props.isSelected) {
      return props.theme.tree.header.selected.backgroundColor;
    }
    if (props.isOpen) {
      return props.theme.tree.header.open.backgroundColor;
    }
    return props.theme.tree.header.backgroundColor;
  }};
  border:
    ${props =>
      props.isSelected && props.theme.tree.header.selected
        ? props.theme.tree.header.selected.border
        : props.theme.tree.header.border};
  &:hover {
    background-color:
      ${props => props.isSelected
        ? props.theme.tree.header.selected.hover.backgroundColor
        : props.theme.tree.header.hover.backgroundColor};
  }
`;

const Branch = styled(Item)<NodeHeaderPropsType>`
  opacity: ${props => props.isGhost ? 0.5 : null};
  padding: 3px 4px;
  border-radius: 4px;
  color: ${props =>
    props.isSelected && props.theme.tree.branch.selected
      ? props.theme.tree.branch.selected.color
      : props.theme.tree.branch.color};
  border: ${props =>
    props.isSelected && props.theme.tree.branch.selected
      ? props.theme.tree.branch.selected.border
      : props.theme.tree.branch.border};
  background-color: ${(props) => {
    if (props.isSelected) {
      return props.theme.tree.branch.selected.backgroundColor;
    }
    if (props.isOpen) {
      return props.theme.tree.branch.open.backgroundColor;
    }
    return props.theme.tree.branch.backgroundColor;
  }};

  &:hover {
    background-color:
      ${props => props.isSelected
        ? props.theme.tree.branch.selected.hover.backgroundColor
        : props.theme.tree.branch.hover.backgroundColor};
  }
  margin-left: ${props => props.indent}px;
`;

export const TreeNodeLeaf = styled(Item)<NodeHeaderPropsType>`
  opacity: ${props => props.isGhost ? 0.5 : null};
  padding: 4px;
  border-radius: 4px;
  color: ${props =>
    props.isSelected
      ? props.theme.tree.leaf.selected.color
      : props.theme.tree.leaf.color};
  background-color:
    ${props =>
      props.isSelected
        ? props.theme.tree.leaf.selected.backgroundColor
        : props.theme.tree.leaf.backgroundColor};
  border: ${props =>
    props.isSelected
      ? props.theme.tree.leaf.selected.border
      : props.theme.tree.leaf.border};
  &:hover {
    background-color:
      ${props => props.isSelected
        ? props.theme.tree.leaf.selected.hover.backgroundColor
        : props.theme.tree.leaf.hover.backgroundColor};
  }
  margin-left: ${props => props.indent}px;
`;

const ANIMATION_DURATION_MS = 200;

const ArrowIconWrapper = styled.div<IOpen & IGhost>`
  display: inline-block;
  line-height: 0;
  visibility: ${props => props.isGhost ? 'hidden' : null};
  & svg {
    width: 16px;
    height: 16px;
    transform: rotate(${props => props.isOpen ? 90 : 0}deg);
    transition: transform ${ANIMATION_DURATION_MS}ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

interface IStyledCollapsable {
  indentInPixels: number;
  isSelected: boolean;
  verticalBars: boolean;
}

const StyledCollapsable = styled(Collapsable)<IStyledCollapsable>`
  position: relative;

  ${props => props.verticalBars && `
    &::after {
      content: '';
      width: 2px;
      background: ${props.isSelected ? props.theme.colorset.primary500 : props.theme.colorset.penumbra};
      position: absolute;
      top: 4px;
      left: ${props.indentInPixels + 12}px;
      bottom: 0;
    }
  `}
`;
