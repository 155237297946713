import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Snackbar } from '../Snackbar/Snackbar';
import { Alert } from '../Alert';

export enum NotificationSeverity {
  success,
  info,
  warning,
  error,
}

export interface INotification {
  readonly id: string;
  readonly intlId?: string;
  readonly values?: Record<string, string>;
  readonly message?: string;
  readonly severity: NotificationSeverity;
}

export interface INotificationProps {
  readonly preventClickAway?: boolean;
  readonly notification: INotification | null;
}

export const Notification: React.FC<INotificationProps> = (props) => {
  if (!props.notification) {
    return null;
  }

  const [open, setOpen] = React.useState(false);
  React.useEffect(
    () => {
      if (props.notification.intlId !== '' || props.notification.message !== '') {
        setOpen(true);
      }
    },
    [props.notification, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const message
    = props.notification.intlId
      ? (
        <FormattedMessage
          id={props.notification.intlId}
          values={props.notification.values}
          defaultMessage={props.notification.message}
        />
      )
      : props.notification.message;

  return (
    <Snackbar open={open} autoHideDuration={60000} onClose={handleClose}>
      <Alert
        severity={props.notification.severity}
        onClose={handleCloseAlert}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
