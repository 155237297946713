import React from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';
import { FormattedMessage } from 'react-intl';
import isHotkey from 'is-hotkey';
import { ListItem } from './ListItem';
import { ContextMenu } from '../utils/ContextMenu/ContextMenu';
import { ContextMenuItem } from '../utils/ContextMenu/ContextMenuItem';
import { isModMouseEvent } from '../../utils/mouse';

export interface IListProps<T> {
  dataProvider: ReadonlyArray<T>;
  itemRenderer: (item: T, itemIndex: number, selected: boolean) => React.ReactNode;
  itemContextMenuItems?:
  (item: T, itemIndex: number, selected: boolean, onSelect: (item: T) => void) =>
  ReadonlyArray<{ labelKey: string; clickHandler: () => void }>;
  selectedIndices: ReadonlyArray<number>;
  setSelection: (indices: ReadonlyArray<number>) => void;
  minColumns: number;
  itemMargin: number;
  itemMinWidth: number;
  itemMaxWidth: number;
  itemHeight: number;
}

export interface IListState {
  contextMenuItemIndex: number;
  contextMenuAnchorPosition: { x: number; y: number };
}

export class List<T> extends React.Component<IListProps<T>, IListState> {
  constructor(props: IListProps<T>) {
    super(props);
    this.state = {
      contextMenuItemIndex: -1,
      contextMenuAnchorPosition: null,
    };
  }

  public componentDidUpdate(prevProps: Readonly<IListProps<T>>, prevState: Readonly<IListState>, snapshot?: any): void {
    if (prevProps.dataProvider !== this.props.dataProvider && prevProps.selectedIndices && prevProps.selectedIndices.length !== 0) {
      const prevSelectedItems = prevProps.selectedIndices.map((prevSelectedIndex) => {
        return prevProps.dataProvider[prevSelectedIndex];
      });

      const newSelectedIndices = prevSelectedItems.map((prevSelectedItem) => {
        return this.props.dataProvider.indexOf(prevSelectedItem);
      }).filter((newSelectedIndex) => {
        return newSelectedIndex !== -1;
      });

      this.props.setSelection(newSelectedIndices);
    }
  }

  public render(): React.ReactNode {
    const {
      dataProvider,
      itemRenderer,
      selectedIndices,
      itemContextMenuItems,
      minColumns,
      itemMargin,
      itemMinWidth,
      itemMaxWidth,
      itemHeight,
    } = this.props;

    if (!dataProvider || dataProvider.length === 0) {
      return null;
    }

    const { contextMenuItemIndex, contextMenuAnchorPosition } = this.state;

    const contextMenuItems
      = contextMenuAnchorPosition && itemContextMenuItems
        ? itemContextMenuItems(
          dataProvider[contextMenuItemIndex],
          contextMenuItemIndex,
          selectedIndices.includes(contextMenuItemIndex),
          this.onSelectItem)
          .map((menuItem, index) => {
            return (
              <ContextMenuItem
                key={index}
                label={(
                  <FormattedMessage
                    id={menuItem.labelKey}
                  />
                )}
                onClick={() => {
                  menuItem.clickHandler();
                  this.onContextMenuClose();
                }}
              />
            );
          })
        : null;

    const contextMenu
      = contextMenuAnchorPosition
        ? (
          <StyledContextMenu
            anchorPosition={contextMenuAnchorPosition}
            onClose={this.onContextMenuClose}
          >
            {contextMenuItems}
          </StyledContextMenu>
        )
        : null;

    return (
      <ListWrapper
        tabIndex={0}
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
      >
        <StyledAutoSizer>
          {
            ({ width, height }: any) => {
              const scrollBarWidth = 8;

              const minColumnWidth = itemMinWidth + (itemMargin * 2);
              const maxColumnWidth = itemMaxWidth + (itemMargin * 2);
              const rowHeight = itemHeight + (itemMargin * 2);

              const availableWidth = width - scrollBarWidth;
              let columnCount = minColumns;
              while (availableWidth / (columnCount + 1) > minColumnWidth) {
                columnCount++;
              }
              while (availableWidth / (columnCount - 1) < maxColumnWidth) {
                columnCount--;
              }
              columnCount = Math.min(columnCount, dataProvider.length);
              const rowCount = Math.ceil(dataProvider.length / columnCount);
              const columnWidth = Math.min(maxColumnWidth, Math.floor(availableWidth / columnCount));

              const renderCell = ({ columnIndex, rowIndex, style }: any) => {
                const itemIndex = rowIndex * columnCount + columnIndex;
                if (itemIndex < dataProvider.length) {
                  return (
                    <div
                      key={itemIndex}
                      style={{
                        ...style,
                        left: style.left + itemMargin,
                        top: style.top + itemMargin,
                        width: style.width - itemMargin * 2,
                        height: style.height - itemMargin * 2,
                      }}
                    >
                      <ListItem
                        index={itemIndex}
                        onSelect={this.onSelect}
                        onContextMenu={this.onContextMenu}
                      >
                        {itemRenderer(dataProvider[itemIndex], itemIndex, selectedIndices.includes(itemIndex))}
                      </ListItem>
                    </div>
                  );
                }
                return null;
              };

              return (
                <FixedSizeGrid
                  width={width}
                  height={height}
                  columnWidth={columnWidth}
                  rowHeight={rowHeight}
                  columnCount={columnCount}
                  rowCount={rowCount}
                >
                  {renderCell}
                </FixedSizeGrid>
              );
            }
          }
        </StyledAutoSizer>
        {contextMenu}
      </ListWrapper>
    );
  }

  private onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.focus();
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { dataProvider, setSelection } = this.props;
    if (isHotkey('mod+a')(event.nativeEvent)) {
      setSelection(dataProvider.map((item, index) => index));
    }
  };

  private onSelectItem = (item: T) => {
    const { dataProvider } = this.props;
    if (!dataProvider) return;
    const itemIndex = dataProvider.indexOf(item);
    if (itemIndex >= 0 && itemIndex < dataProvider.length) {
      this.onSelectIndex(itemIndex, true);
    }
  };

  private onContextMenu = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      contextMenuItemIndex: index,
      contextMenuAnchorPosition: { x: event.clientX, y: event.clientY },
    });
  };

  private onContextMenuClose = () => {
    this.setState({
      contextMenuItemIndex: -1,
      contextMenuAnchorPosition: null,
    });
  };

  private onSelect = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    this.onSelectIndex(index, isModMouseEvent(event.nativeEvent), event.shiftKey);
  };

  private onSelectIndex = (index: number, modKey: boolean = false, shiftKey: boolean = false) => {
    const { selectedIndices, setSelection } = this.props;

    if (shiftKey) {
      if (selectedIndices.length === 0) {
        setSelection([index]);
        return;
      }
      const tempIndices = modKey ? selectedIndices.concat() : [];
      const lastSelectedIndex = selectedIndices[selectedIndices.length - 1];
      for (let i = Math.min(index, lastSelectedIndex); i <= Math.max(index, lastSelectedIndex); i++) {
        if (!tempIndices.includes(i)) {
          tempIndices.push(i);
        }
      }
      setSelection(tempIndices);
    } else if (selectedIndices.includes(index)) {
      if (modKey || selectedIndices.length === 1) {
        setSelection(selectedIndices.filter(i => i !== index));
      } else {
        setSelection([index]);
      }
    } else if (modKey) {
      setSelection(selectedIndices.concat(index));
    } else {
      setSelection([index]);
    }
  };
}

const ListWrapper = styled.div`
  flex: 1 1 auto;
  outline: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledAutoSizer = styled(AutoSizer)`
  & > div {
    overflow-y: overlay !important;
    overflow-x: hidden !important;
  }
`;

const StyledContextMenu = styled(ContextMenu)`
  z-index: 2;
  min-width: 0px;
`;
