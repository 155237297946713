import React from 'react';
import { ICustomEditor } from '../../models/editor';
import { IPresetColorGroup } from '../../../ColorPicker/models';
import { getBackgroundColor, setBackgroundColor } from '../../utils/characterFormatUtils';
import { GenericColorControl } from './GenericColorControl';
import { IClassDefinition } from '../../models/configuration';

interface IColorControlProps {
  editor: ICustomEditor;
  colorPresets: ReadonlyArray<IPresetColorGroup>;
  label: string;
  classes: ReadonlyArray<IClassDefinition>;
  className?: string;
}

export const BackgroundColorControl: React.FC<IColorControlProps> = ({ editor, label, colorPresets, classes, className }) => {
  return (
    <GenericColorControl
      Label={label}
      editor={editor}
      colorPresets={colorPresets}
      classes={classes}
      setColorValue={setBackgroundColor}
      getColorValue={getBackgroundColor}
      className={className}
    />
  );
};
