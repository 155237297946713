import { v4 as uuidv4 } from 'uuid';
import { ReactEditor } from '../TypedSlate';
import { ICustomEditor } from '../models/editor';
import { IPlugin } from '../models/plugins';
import KeyboardManager from '../../../keyboard/KeyboardManager';
import { keyboardLayout } from './keyboardLayout';
import { DISMISS } from '../../Keyboards/Keys/SpecialKeys';
import { getEditorDomElement } from '../utils/editorUtils';

export const withKeyboard: IPlugin = (editor: ICustomEditor) => {
  const keyboardId = uuidv4();
  let isKeyboardOpen = false;

  const closeKeyboard = () => {
    KeyboardManager.getInstance().hideKeyboard(keyboardId);
    const editorDom = getEditorDomElement(editor);
    editorDom.removeEventListener('blur', closeKeyboard);
    isKeyboardOpen = false;
  };

  const openKeyboard = () => {
    const onKeyPress = (key: string) => {
      if (key === DISMISS) {
        closeKeyboard();
      } else {
        editor.insertText(key);
      }
    };
    KeyboardManager.getInstance().showKeyboard(keyboardId, keyboardLayout, onKeyPress);
    const editorDom = ReactEditor.toDOMNode(editor, editor);
    editorDom.addEventListener('blur', closeKeyboard);
    isKeyboardOpen = true;
  };

  editor.toggleKeyboard = () => {
    if (isKeyboardOpen) {
      closeKeyboard();
    } else {
      openKeyboard();
    }
  };

  return editor;
};
