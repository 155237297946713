import React from 'react';
import styled from 'styled-components';
import { TopLabeledInput } from '../TopLabeledInput/TopLabeledInput';
import { Button } from '../Button/Button';
import { sanitizeFilter } from '../../../utils/filtering';

export interface IValueCreationFormResources {
  inputLabel: React.ReactNode;
  cancelLabel: React.ReactNode;
  saveLabel: React.ReactNode;
}

interface IValueCreationFormProps {
  initialValue: string;
  resources: IValueCreationFormResources;
  onSave: (value: string) => void;
  onCancel: () => void;
}

export const ValueCreationForm: React.FC<IValueCreationFormProps> = (props) => {
  const {
    initialValue,
    resources,
    onSave,
    onCancel,
  } = props;
  const [valueName, setValueName] = React.useState(initialValue);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValueName(sanitizeFilter(event.target.value));
  return (
    <Container>
      <TopLabeledInput
        label={resources.inputLabel}
        value={valueName}
        onChange={onChange}
        autoFocus
      />
      <Row>
        <Button
          onClick={onCancel}
        >
          {resources.cancelLabel}
        </Button>
        <Button
          onClick={() => onSave(valueName.trim())}
          disabled={!valueName}
          primary
        >
          {resources.saveLabel}
        </Button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
