import React from 'react';
import { isItalicMarkActive, toggleItalicMark } from '../../utils/characterFormatUtils';
import { ICustomEditor } from '../../models/editor';
import { Button } from '../../../Button/Button';
import ItalicIcon from '../../../../../assets/italic.svg';
import { IClassDefinition } from '../../models/configuration';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IItalicControlProps {
  editor: ICustomEditor;
  label: string;
  classes: ReadonlyArray<IClassDefinition>;
}

export const ItalicControl: React.FC<IItalicControlProps> = ({ editor, label, classes }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleItalicMark(editor, classes);
  };
  return (
    <Tooltip
      title={label}
    >
      <Button
        active={isItalicMarkActive(editor, classes)}
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <ItalicIcon />
      </Button>
    </Tooltip>
  );
};
