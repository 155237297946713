import React, { useEffect, useLayoutEffect, useCallback, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';
import { throttle } from 'lodash';

export interface ISliderProps {
  readonly disabled?: boolean;
  readonly value: number;
  readonly onChange: (value: number) => void;
  readonly step?: number;
  readonly min: number;
  readonly max: number;
  readonly valueLabelDisplay?: 'on' | 'auto' | 'off';
  readonly className?: string;
}
export const Slider: React.FC<ISliderProps> = (props) => {
  const {
    disabled,
    onChange,
    step,
    min,
    max,
    value,
    className,
  } = props;

  const isReadonly = useContext(ReadonlyContext);

  const isDisabled = disabled || isReadonly;

  const ref = useRef<HTMLSpanElement>(null);

  const [containerSize, setContainerSize] = useState({ rect: null });

  const [isDragging, setIsDragging] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      setContainerSize({
        rect: ref.current.getBoundingClientRect(),
      });
    }
  }, [ref.current]);

  useEffect(() => {
    return () => {
      window.removeEventListener('mousemove', throttledMouseMove);
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('touchmove', throttledMouseMove);
      window.removeEventListener('touchend', mouseUp);
      setIsDragging(false);
      throttledMouseMove.cancel();
    };
  }, []);

  const mouseMove = useCallback((e: MouseEvent | TouchEvent): void => {
    const position = containerSize.rect ? calculateMousePos(e, containerSize.rect, min, max, step) : value;
    if (!disabled) {
      onChange(position);
    }
  }, [disabled, onChange, containerSize, min, max, step]);

  const throttledMouseMove = useCallback(throttle(mouseMove, 16), [mouseMove]);

  const mouseDown = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isDisabled) {
      return;
    }
    event.preventDefault();
    window.addEventListener('mousemove', throttledMouseMove);
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('touchmove', throttledMouseMove);
    window.addEventListener('touchend', mouseUp);
    setIsDragging(true);
    mouseMove(event.nativeEvent);
  }, [isDisabled, throttledMouseMove]);

  const mouseUp = useCallback((): void => {
    document.body.style.cursor = '';
    window.removeEventListener('mousemove', throttledMouseMove);
    window.removeEventListener('mouseup', mouseUp);
    window.removeEventListener('touchmove', throttledMouseMove);
    window.removeEventListener('touchend', mouseUp);
    setIsDragging(false);
  }, [throttledMouseMove]);

  return (
    <SliderWrapper
      role="sliderContainer"
      ref={ref}
      disabled={isDisabled}
      className={className}
      onMouseDown={mouseDown}
      onTouchStart={mouseDown}
    >
      <SliderTrack />
      <SliderSelected />
      <SliderThumb
        role="slider"
        value={valueToPercent(value, min, max)}
        isDragging={isDragging}
      >
        <ValueDisplay>
          <ValueBackground>
            <ValueText>
              {props.value}
            </ValueText>
          </ValueBackground>
        </ValueDisplay>
      </SliderThumb>
    </SliderWrapper>
  );
};

function getDecimalPrecision(num: number) {
  // This handles the case when num is very small (0.00000001), js will turn this into 1e-8.
  // When num is bigger than 1 or less than -1 it won't get converted to this notation so it's fine.
  if (Math.abs(num) < 1) {
    const parts = num.toExponential().split('e-');
    const matissaDecimalPart = parts[0].split('.')[1];
    return (matissaDecimalPart ? matissaDecimalPart.length : 0) + parseInt(parts[1], 10);
  }

  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function roundValueToStep(value: number,
                          step: number,
                          min: number) {
  const nearest = Math.round((value - min) / step) * step + min;
  return Number(nearest.toFixed(getDecimalPrecision(step)));
}

function percentToValue(percent: number,
                        min: number,
                        max: number) {
  return (max - min) * percent + min;
}

function clamp(value: number,
               min: number,
               max: number) {
  return Math.min(Math.max(min, value), max);
}

function valueToPercent(value: number,
                        min: number,
                        max: number) {
  return (value - min) * 100 / (max - min);
}

function calculateMousePos(e: MouseEvent | TouchEvent,
                           containerRect: DOMRect,
                           min: number,
                           max: number,
                           step?: number) {
  const event = e instanceof MouseEvent ? e : e.touches[0];
  const x = event.clientX - containerRect.left;
  let percent = (x - min) / containerRect.width;
  // in Jest tests, the containerRect.width is 0, so we need to handle this case
  if (isNaN(percent)) {
    percent = 0;
  }
  percent = Math.min(1, Math.max(0, percent));
  let newValue = percentToValue(percent, min, max);
  if (step) {
    newValue = roundValueToStep(newValue, step, min);
  }
  newValue = clamp(newValue, min, max);

  return newValue;
}

interface ISliderWrapperProps {
  readonly disabled?: boolean;
}

const SliderWrapper = styled.span<ISliderWrapperProps>`
  cursor: ${(props: ISliderWrapperProps) => props.disabled ? 'default' : 'pointer'};
  display: block;
  padding: 13px 0;
  pointer-events: ${(props: ISliderWrapperProps) => props.disabled ? 'none' : 'auto'};
  position: relative;
  width: 100%;
`;

const SliderTrack = styled.span`
  border-radius: 1px;
  background-color: ${props => props.theme.colorset.grey13};
  display: block;
  height: 2px;
  opacity: 0.38;
  position: absolute;
  width: 100%;
`;

const ValueDisplay = styled.span`
  left: calc(-50% - 4px);
  top: -34px;
  z-index: 1;
  position: absolute;
  font-size: 0.75rem;
  transform: scale(0);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01071em;
  transform-origin: bottom center;
`;

interface ISliderValueProps {
  readonly value: number;
  readonly isDragging: boolean;
}

const SliderThumb = styled.span.attrs<ISliderValueProps>((props: ISliderValueProps) => ({
  style: {
    left: props.value + '%',
  },
}))<ISliderValueProps>`
  align-items: center;
  background-color: currentColor;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 12px;
  justify-content: center;
  margin-left: -6px;
  margin-top: -5px;
  outline: 0;
  position: absolute;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 12px;
  ${(props: ISliderValueProps) => {
    if (props.isDragging) {
      return `
      ${ValueDisplay} {
          transform: scale(1) translateY(-10px);
      }`;
    }
  }};
  &:after {
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &:hover {
    box-shadow: 0 0 0 8px ${props => props.theme.colorset.grey13}29;
    ${ValueDisplay} {
      transform: scale(1) translateY(-10px);
    }
  }
  }
`;

const SliderSelected = styled.span`
  background-color: ${props => props.theme.colorset.grey13};
  border-radius: 1px;
  height: 2px;
  position: absolute;
  width: 100%;
`;

const ValueBackground = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  transform: rotate(-45deg);
  align-items: center;
  border-radius: 50% 50% 50% 0;
  justify-content: center;
  background-color: currentColor;
`;

const ValueText = styled.span`
  color: ${props => props.theme.colorset.grey01};
  transform: rotate(45deg);
`;
