import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../utils/Modal/Modal';
import { IMarkupElement } from '../../models/elements';
import { updateElement } from '../../utils/updateElement';
import { MarkupEditor } from '../../../MarkupEditor/MarkupEditor';
import { ICustomEditor } from '../../models/editor';

export interface IMarkupElementProps {
  open: boolean;
  handleClose: () => void;
  element: IMarkupElement;
  editor: ICustomEditor;
}

export class MarkupElementDialog extends React.Component<IMarkupElementProps> {
  constructor(props: IMarkupElementProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { open, handleClose, element } = this.props;
    const { markup } = element;
    return (
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Container
          onClick={this.stopMouseEvents}
          onMouseDown={this.stopMouseEvents}
          onMouseUp={this.stopMouseEvents}
        >
          <MarkupEditor
            value={markup}
            onChange={this.onChangeMarkup}
          />
          <button
            onClick={this.closePopup}
          >
            Close
          </button>
        </Container>
      </Modal>
    );
  }

  private closePopup = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  private onChangeMarkup = (markup: string) => {
    const { element, editor } = this.props;
    const newNodeValue: Partial<IMarkupElement> = {
      markup,
    };
    updateElement(editor, element, newNodeValue);
  };

  private stopMouseEvents = (event: React.MouseEvent) => {
    // prevent the Page Editor from intercepting the mouse events
    event.stopPropagation();
  };
}

const Container = styled.div`
  padding: 16px;
`;
