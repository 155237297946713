import React from 'react';
import styled from 'styled-components';
import { AvatarCount } from './AvatarCount';
import { Avatar } from './Avatar';
import { IAvatar } from './IAvatar';

export interface IAvatarsGroupSpacedSpacedProps {
  readonly avatars: ReadonlyArray<IAvatar>;
  readonly extraCount?: number;
}

export const AvatarsGroupSpaced: React.FC<IAvatarsGroupSpacedSpacedProps> = (
  {
    avatars,
    extraCount = 0,
  },
) => {
  const extraLabel
    = extraCount > 0
      ? (
        <AvatarCount count={extraCount} />
      )
      : null;

  const avatarsImages = avatars.map((a, index) => {
    return (
      <Avatar
        key={index}
        label={a.label}
        src={a.src}
        size={a.size}
      />
    );
  });

  return (
    <AvatarsRow>
      {extraLabel}
      {avatarsImages}
    </AvatarsRow>
  );
};

const AvatarsRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
`;
