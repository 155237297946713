import React from 'react';
import styled from 'styled-components';
import { useVirtual } from 'react-virtual';
import { IMultiselectFilterResult } from './IMultiselectFilterResult';
import { Checkbox } from '../Checkbox/Checkbox';
import { Separator } from './styles';
import { TagLabel } from '../../tagging/TagLabel';
import { EmptyState } from '../../dataDisplay/EmptyState';
import { SelectItemStyle } from '../Select/SelectItem';

interface ISelectorListProps<TId> {
  originalSelectionIds: ReadonlyArray<TId>;
  currentSelectionIds: ReadonlyArray<TId>;
  filteredValues: ReadonlyArray<IMultiselectFilterResult<TId>>;
  emptyStateLabel: React.ReactNode;
  singleSelection?: boolean;
  onChange: (id: TId, isSelected: boolean) => void;
  listItemLabelRenderFunction?: (value: IMultiselectFilterResult<TId>) => React.ReactNode;
}

export const MULTISELECT_SELECTION_LIST_TEST_ID = 'MULTISELECT_SELECTION_LIST';

type TSelectorList = <TId extends string | number>(props: ISelectorListProps<TId>) => React.ReactElement;
export const SelectorList: TSelectorList = (props) => {
  const {
    originalSelectionIds,
    currentSelectionIds,
    filteredValues,
    onChange,
    listItemLabelRenderFunction,
    emptyStateLabel,
    singleSelection,
  } = props;
  const parentRef = React.useRef<HTMLDivElement>(null);
  const selectedFilteredTags = filteredValues.filter(match => originalSelectionIds.includes(match.value.id));
  const notSelectedFilteredTags = filteredValues.filter(match => !originalSelectionIds.includes(match.value.id));

  const hasSeparator = selectedFilteredTags.length > 0 && notSelectedFilteredTags.length > 0;
  const nbItems = selectedFilteredTags.length + notSelectedFilteredTags.length + (hasSeparator ? 1 : 0);
  const rowVirtualizer = useVirtual({
    parentRef,
    size: nbItems,
  });
  if (filteredValues.length === 0) {
    return (
      <>
        <Separator />
        <EmptyState
          label={emptyStateLabel}
        />
      </>
    );
  }
  const isSeparator = (index: number) => hasSeparator && index === selectedFilteredTags.length;
  const getRowData = (index: number) => {
    return index < selectedFilteredTags.length
      ? selectedFilteredTags[index]
      : notSelectedFilteredTags[index - selectedFilteredTags.length - (hasSeparator ? 1 : 0)];
  };
  const labelRenderFn = listItemLabelRenderFunction ?? defaultLabelRenderFn;
  const getRow = (index: number) => {
    if (isSeparator(index)) {
      return (
        <StyledSeparator />
      );
    }
    const rowData = getRowData(index);
    const isSelected = currentSelectionIds.includes(rowData.value.id);

    if (singleSelection) {
      const onClick = () => onChange(rowData.value.id, true);
      return (
        <SingleSelectItem
          isSelected={isSelected}
          onClick={onClick}
        >
          {labelRenderFn(rowData)}
        </SingleSelectItem>
      );
    }

    const onChangeCheckbox = () => onChange(rowData.value.id, !isSelected);
    return (
      <StyledCheckbox
        key={rowData.value.id}
        checked={isSelected}
        onChange={onChangeCheckbox}
        Label={labelRenderFn(rowData)}
        data-testid={`MultiSelect-${rowData.value.id}`}
      />
    );
  };

  return (
    <>
      <Separator />
      <StyledSection
        ref={parentRef}
      >
        <div
          style={{
            height: rowVirtualizer.totalSize,
            width: '100%',
            position: 'relative',
          }}
          data-testid={MULTISELECT_SELECTION_LIST_TEST_ID}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {getRow(virtualRow.index)}
            </div>
          ))}
        </div>
      </StyledSection>
    </>
  );
};

const defaultLabelRenderFn = <TId extends string | number>(tagInfo: IMultiselectFilterResult<TId>) => (
  <StyledTagLabel
    capitalize
    tagName={tagInfo.value.displayValue}
    emphasis={tagInfo.emphasis}
  />
);

const StyledSection = styled.div`
  flex: 1;
  overflow: auto;
  padding: 8px 0 8px 8px;
`;

const StyledSeparator = styled(Separator)`
  margin: 6px 0;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 2px 0;
  align-items: flex-start;
  line-height: 26px;
`;

const StyledTagLabel = styled(TagLabel)`
  padding: 0px;
`;

const SingleSelectItem = styled(SelectItemStyle)`
  padding: 6px 8px;
`;
