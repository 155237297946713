import React from 'react';
import styled from 'styled-components';
import { ITheme } from '../../../themes/ITheme';

export interface ILabelProps {
  readonly children?: React.ReactNode;
  readonly theme?: ITheme;
  readonly className?: string;
}

export const Label: React.FC<ILabelProps> = (
  {
    children,
    theme,
    className,
  },
) => {
  return (
    <LabelWrapper
      className={className}
    >
      {children}
    </LabelWrapper>
  );
};

const LabelWrapper = styled.label`
  align-self: center;
  color: ${props => props.theme.colorset.grey03};
  font: ${props => props.theme.typeset.captionRegular};
  margin-left: ${props => props.theme.baseVerticalSpacing};
  text-transform: uppercase;

  a {
    color: ${props => props.theme.colorset.grey03};
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;
