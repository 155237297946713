import React from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Select } from '../inputs/Select/Select';
import { SelectItem } from '../inputs/Select/SelectItem';
import { IDateModel } from './DateSelectModel';
import { DatePicker } from '../inputs/DatePicker/DatePicker';
import { setHexColorTransparency } from '../../themes/ColorSet';

export interface IDateChildrenProps {
  readonly dates: IDateModel;
  readonly className?: string;
  readonly onSaveSelected: (indexDateOption: number, indexOperator: number, values: ReadonlyArray<string>) => void;
  readonly typeDateValues: ReadonlyArray<{ label: string }>;
  readonly operatorValues: ReadonlyArray<{ label: string; showSecondaryDate: boolean }>;
  readonly selectedIndex: IDateChildrenIndexState;
}

export interface IDateChildrenState {
  mainDate: string;
  secondaryDate: string;
}

export interface IDateChildrenIndexState {
  indexDateOption: number;
  indexOperator: number;
}

export class DateChildren extends React.Component<IDateChildrenProps, IDateChildrenState & IDateChildrenIndexState> {
  constructor(props: IDateChildrenProps) {
    super(props);

    this.state = {
      indexDateOption: props.selectedIndex.indexDateOption,
      indexOperator: props.selectedIndex.indexOperator,
      mainDate: props.dates?.startDate ?? '',
      secondaryDate: props.dates?.endDate ?? '',
    };
  }

  public render(): React.ReactNode {
    const {
      typeDateValues,
      operatorValues,
      className,
    } = this.props;
    const {
      indexDateOption,
      indexOperator,
      mainDate,
      secondaryDate,
    } = this.state;

    const selectedType = typeDateValues[indexDateOption];
    const selectedTypeElement = (
      <SelectedItemLabel>
        {selectedType.label}
      </SelectedItemLabel>
    );

    const selectedOperator = operatorValues[indexOperator];
    const selectedOperatorElement = (
      <SelectedItemLabel>
        {selectedOperator.label}
      </SelectedItemLabel>
    );

    const typeDateChildren = typeDateValues.map((e, index) => {
      return (
        <StyledSelectItem
          key={`dateoption_${index}`}
          value={index}
        >
          {e.label}
        </StyledSelectItem>
      );
    });

    const typeOperatorChildren = operatorValues.map((e, index) => {
      return (
        <StyledSelectItem
          key={`operator_${index}`}
          value={index}
        >
          {e.label}
        </StyledSelectItem>
      );
    });

    const secondaryDateElement = (selectedOperator.showSecondaryDate)
      ? (
        <StyledDatePicker
          value={secondaryDate}
          onChange={event => this.onChangeSecondary(event.target.value)}
          max="9999-12-31"
        />
      )
      : null;

    return (
      <Container className={className} data-testid="DateChildren">
        <TopContainer>
          <StyledSelect SelectedComponent={selectedTypeElement} value={indexDateOption} onChange={this.setIndexDateOption}>
            {typeDateChildren}
          </StyledSelect>
          <StyledSelect SelectedComponent={selectedOperatorElement} value={indexOperator} onChange={this.onChangeOperator}>
            {typeOperatorChildren}
          </StyledSelect>
        </TopContainer>
        <StyledDatePicker
          value={mainDate}
          onChange={event => this.onChangeMain(event.target.value)}
          max="9999-12-31"
        />
        {secondaryDateElement}
      </Container>
    );
  }

  private setIndexDateOption = (value: number) => {
    this.setState({
      indexDateOption: value,
    }, () => {
      if (this.state.mainDate || this.state.secondaryDate) {
        this.localOnSaveSelected();
      }
    });
  };

  private onChangeOperator = (value: number) => {
    const currentOperator = this.props.operatorValues[value];
    this.setState({
      indexOperator: value,
      secondaryDate: currentOperator.showSecondaryDate
        ? this.state.secondaryDate
        : '',
    }, () => {
      if (this.state.mainDate || this.state.secondaryDate) {
        this.localOnSaveSelected();
      }
    });
  };

  private onChangeMain = debounce((value: string) => {
    this.setState({
      mainDate: value,
    }, () => {
      const { indexOperator, secondaryDate } = this.state;
      const currentOperator = this.props.operatorValues[indexOperator];
      if (
        (currentOperator.showSecondaryDate && ((value && secondaryDate)
          || (!value && !secondaryDate)))
        || !currentOperator.showSecondaryDate
      ) {
        this.localOnSaveSelected();
      }
    });
  }, 500);

  private onChangeSecondary = debounce((value: string) => {
    this.setState({
      secondaryDate: value,
    }, () => {
      const { mainDate } = this.state;
      if ((mainDate && value) || (!mainDate && !value)) {
        this.localOnSaveSelected();
      }
    });
  }, 500);

  private localOnSaveSelected = () => {
    const {
      onSaveSelected,
      operatorValues,
    } = this.props;
    const {
      indexDateOption,
      indexOperator,
      mainDate,
      secondaryDate,
    } = this.state;
    const operatorValue = operatorValues[indexOperator];
    onSaveSelected(indexDateOption, indexOperator, operatorValue.showSecondaryDate ? [mainDate, secondaryDate] : [mainDate]);
  };
}

const Container = styled.div`
  font-family: ${props => props.theme.fontFamily};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const TopContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledSelect = styled(Select)`
  flex-basis: 100%;
  font: ${props => props.theme.typeset.body2SemiBold};
  color: ${props => props.theme.colorset.penumbra};
`;

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid ${props => props.theme.colorset.grey100};
  background-color: ${props => props.theme.colorset.white};

  input[type=date] {
    font-family: ${props => props.theme.typeset.body2SemiBold};
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.colorset.penumbra};
  }
`;

const StyledSelectItem = styled(SelectItem)`
  padding: 6px 8px;

  :hover {
    background-color: ${props => setHexColorTransparency(props.theme.colorset.primary500, 0.2)};
  }
`;

const SelectedItemLabel = styled.span`
  padding-left: 8px;
`;
