import React from 'react';
import styled from 'styled-components';
import { NotificationSeverity } from './Notification/Notification';
import InfoCircle from '../../assets/info-circle.svg';
import Success from '../../assets/objects/success.svg';
import Warning from '../../assets/warning.svg';
import RemoveIcon from '../../assets/remove.svg';
import X from '../../assets/x.svg';

interface IAlertProps {
  readonly severity: NotificationSeverity;
  readonly onClose: () => void;
}

export const Alert: React.FC<IAlertProps> = (props) => {
  const icon = {
    0: <Success />,
    1: <InfoCircle />,
    2: <Warning />,
    3: <RemoveIcon />,
  };

  return (
    <AlertDiv
      severity={props.severity}
    >
      <IconWrapper>
        {icon[props.severity]}
      </IconWrapper>
      <Message>
        {props.children}
      </Message>
      <CloseButton>
        <CloseBg
          onClick={props.onClose}
        >
          <X />
        </CloseBg>
      </CloseButton>
    </AlertDiv>
  );
};

interface IAlertDiv {
  readonly severity: NotificationSeverity;
}

const AlertDiv = styled.div`
  background-color: ${(props: IAlertDiv) => {
    switch (props.severity) {
      case NotificationSeverity.success:
        return '#4caf50';
      case NotificationSeverity.info:
        return '#2196f3';
      case NotificationSeverity.warning:
        return '#ff9800';
      case NotificationSeverity.error:
        return '#f44336';
    }
  }};
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  color: #fff;
`;

const Message = styled.div`
  padding: 8px 0;
`;

const CloseButton = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 16px;
`;

const CloseBg = styled.div`
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const IconWrapper = styled.div`
  color: #fff;
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 22px;
  margin-right: 12px;
  > svg {
    width: 24px;
    height: 24px;
  }
`;
