import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { StaticMarkupFactory } from '../../expr/conversion/markup/StaticMarkupFactory';
import { MElement } from '../../core/mml/MElement';
import { RawDocument } from '../../core/RawDocument';

/**
 * Converti un string mathML en WMarkup
 * Un string encodé en base 64 peut être utilisé si le mathML contient des charactères spéciaux, tel que ".
 */
export class ParseMathML extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const rawMathML = args.getString(0);
    if (!rawMathML) {
      return null;
    }

    const isBase64 = args.length === 2 && args.getBoolean(1).toBoolean();
    const mathML = isBase64 ? atob(rawMathML.getString()) : rawMathML.getString();

    const element = MElement.fromDocument(new RawDocument(mathML).toDocument(), null, true);
    return new WMarkup(new StaticMarkupFactory(element, args.env.culture));
  }
}
