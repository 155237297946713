import { ListElement } from '../abstract/ListElement';
import { ContentElement } from '../abstract/ContentElement';
import { BaseListFormatter } from '../formats/BaseListFormatter';
import { WMarkup } from './WMarkup';

export class WListOfMarkup extends ListElement {
  constructor(markups: ContentElement[], formatter: BaseListFormatter) {
    super(markups, formatter);
  }

  public useUnambiguousItemSeparator(): boolean {
    return true;
  }

  public acceptElement(element: ContentElement): boolean {
    return element.getListItemCode() === WMarkup.getListItemCode();
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    return new WListOfMarkup(items, formatter);
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WListOfMarkup) {
      return this.unorderedEqualsTo(value);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfMarkup) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    return WMarkup.compare(a as WMarkup, b as WMarkup);
  }

  public getType(): string {
    return 'markups';
  }
}
