import { IDictionary } from '../../js/utils/IDictionary';

import { FunctionElement } from '../elements/abstract/FunctionElement';
import { OperatorElement } from '../elements/abstract/OperatorElement';
import { IFunctionsResolver } from '../expr/IFunctionsResolver';
import { SolveFor } from '../funcs/algebra/SolveFor';
import { Abs } from '../funcs/arithmetic/Abs';
import { Ceil } from '../funcs/arithmetic/Ceil';
import { Digit } from '../funcs/arithmetic/Digit';
import { DigitalRoot } from '../funcs/arithmetic/DigitalRoot';
import { DivideOutFormsOfOne } from '../funcs/arithmetic/DivideOutFormsOfOne';
import { Divisors } from '../funcs/arithmetic/Divisors';
import { Factorial } from '../funcs/arithmetic/Factorial';
import { Floor } from '../funcs/arithmetic/Floor';
import { Gcd } from '../funcs/arithmetic/Gcd';
import { Lcm } from '../funcs/arithmetic/Lcm';
import { Ln } from '../funcs/arithmetic/Ln';
import { LogN } from '../funcs/arithmetic/LogN';
import { LongPower } from '../funcs/arithmetic/LongPower';
import { Max } from '../funcs/arithmetic/Max';
import { Min } from '../funcs/arithmetic/Min';
import { Mod } from '../funcs/arithmetic/Mod';
import { NthRoot } from '../funcs/arithmetic/NthRoot';
import { Round } from '../funcs/arithmetic/Round';
import { Slices } from '../funcs/arithmetic/Slices';
import { Sqrt } from '../funcs/arithmetic/Sqrt';
import { Truncate } from '../funcs/arithmetic/Truncate';
import { IsDecimal } from '../funcs/asserts/IsDecimal';
import { IsError } from '../funcs/asserts/IsError';
import { IsInteger } from '../funcs/asserts/IsInteger';
import { IsLang } from '../funcs/asserts/IsLang';
import { IsList } from '../funcs/asserts/IsList';
import { IsMatrix } from '../funcs/asserts/IsMatrix';
import { IsNumeric } from '../funcs/asserts/IsNumeric';
import { IsPlural } from '../funcs/asserts/IsPlural';
import { IsPrime } from '../funcs/asserts/IsPrime';
import { IsSpecialCase } from '../funcs/asserts/IsSpecialCase';
import { IsToken } from '../funcs/asserts/IsToken';
import { IsUnhandled } from '../funcs/asserts/IsUnhandled';
import { IsVariable } from '../funcs/asserts/IsVariable';
import { AreCoincident } from '../funcs/asserts/geom/AreCoincident';
import { AreCongruent } from '../funcs/asserts/geom/AreCongruent';
import { AreEquals } from '../funcs/asserts/geom/AreEquals';
import { AreSimilar } from '../funcs/asserts/geom/AreSimilar';
import { IsTranslated } from '../funcs/asserts/geom/IsTranslated';
import { SloppyCompare } from '../funcs/asserts/geom/SloppyCompare';
import { AreParallel } from '../funcs/asserts/geom/lines/AreParallel';
import { ArePerpendicular } from '../funcs/asserts/geom/lines/ArePerpendicular';
import { IsAxisOfSymmetry } from '../funcs/asserts/geom/lines/IsAxisOfSymmetry';
import { IsHorizontal } from '../funcs/asserts/geom/lines/IsHorizontal';
import { IsPointOnLine } from '../funcs/asserts/geom/lines/IsPointOnLine';
import { IsVertical } from '../funcs/asserts/geom/lines/IsVertical';
import { IsClosed } from '../funcs/asserts/geom/paths/IsClosed';
import { HasParallels } from '../funcs/asserts/geom/polygons/HasParallels';
import { IsRegular } from '../funcs/asserts/geom/polygons/IsRegular';
import { IsSymmetric } from '../funcs/asserts/geom/polygons/IsSymmetric';
import { IsArrowHead } from '../funcs/asserts/geom/quadrilaterals/IsArrowHead';
import { IsConcave } from '../funcs/asserts/geom/quadrilaterals/IsConcave';
import { IsConvex } from '../funcs/asserts/geom/quadrilaterals/IsConvex';
import { IsCrossed } from '../funcs/asserts/geom/quadrilaterals/IsCrossed';
import { IsIsoscelesTrapezoid } from '../funcs/asserts/geom/quadrilaterals/IsIsoscelesTrapezoid';
import { IsKite } from '../funcs/asserts/geom/quadrilaterals/IsKite';
import { IsParallelogram } from '../funcs/asserts/geom/quadrilaterals/IsParallelogram';
import { IsRectangle } from '../funcs/asserts/geom/quadrilaterals/IsRectangle';
import { IsRectangleTrapezoid } from '../funcs/asserts/geom/quadrilaterals/IsRectangleTrapezoid';
import { IsRhombus } from '../funcs/asserts/geom/quadrilaterals/IsRhombus';
import { IsTrapezoid } from '../funcs/asserts/geom/quadrilaterals/IsTrapezoid';
import { IsAcute } from '../funcs/asserts/geom/triangles/IsAcute';
import { IsIsoangle } from '../funcs/asserts/geom/triangles/IsIsoangle';
import { IsIsorectangle } from '../funcs/asserts/geom/triangles/IsIsorectangle';
import { IsIsosceles } from '../funcs/asserts/geom/triangles/IsIsosceles';
import { IsObtuse } from '../funcs/asserts/geom/triangles/IsObtuse';
import { IsRight } from '../funcs/asserts/geom/triangles/IsRight';
import { IsScalene } from '../funcs/asserts/geom/triangles/IsScalene';
import { HasAmbiguousFractionScript } from '../funcs/asserts/markup/HasAmbiguousFractionScript';
import { HasExponent } from '../funcs/asserts/markup/HasExponent';
import { HasFence } from '../funcs/asserts/markup/HasFence';
import { HasFraction } from '../funcs/asserts/markup/HasFraction';
import { HasOperator } from '../funcs/asserts/markup/HasOperator';
import { HasRadical } from '../funcs/asserts/markup/HasRadical';
import { HasSubscript } from '../funcs/asserts/markup/HasSubscript';
import { TestPoint } from '../funcs/asserts/relations/TestPoint';
import { ListTray } from '../funcs/balances/ListTray';
import { NumericTray } from '../funcs/balances/NumericTray';
import { PolynomialTray } from '../funcs/balances/PolynomialTray';
import { ValidateLinearEquationBalanceScale } from '../funcs/balances/ValidateLinearEquationBalanceScale';
import { BattleshipHit } from '../funcs/battleship/BattleshipHit';
import { BattleshipPlace } from '../funcs/battleship/BattleshipPlace';
import { CardName } from '../funcs/cards/CardName';
import { CardRank } from '../funcs/cards/CardRank';
import { CardShortName } from '../funcs/cards/CardShortName';
import { CardSuit } from '../funcs/cards/CardSuit';
import { CardsTable } from '../funcs/cards/CardsTable';
import { Clubs } from '../funcs/cards/Clubs';
import { Diamonds } from '../funcs/cards/Diamonds';
import { Hearts } from '../funcs/cards/Hearts';
import { Spades } from '../funcs/cards/Spades';
import { ChessBoard } from '../funcs/chess/ChessBoard';
import { ChessMove } from '../funcs/chess/ChessMove';
import { ChessPlace } from '../funcs/chess/ChessPlace';
import { ChessRemove } from '../funcs/chess/ChessRemove';
import { Diff } from '../funcs/collections/Diff';
import { FastMap } from '../funcs/collections/FastMap';
import { Filter } from '../funcs/collections/Filter';
import { Flatten } from '../funcs/collections/Flatten';
import { Items } from '../funcs/collections/Items';
import { LargestPrevious } from '../funcs/collections/LargestPrevious';
import { Map } from '../funcs/collections/Map';
import { MapN } from '../funcs/collections/MapN';
import { NotDistinct } from '../funcs/collections/NotDistinct';
import { Pattern } from '../funcs/collections/Pattern';
import { Points } from '../funcs/collections/Points';
import { PrimeFactors } from '../funcs/collections/PrimeFactors';
import { Repeat } from '../funcs/collections/Repeat';
import { Replace } from '../funcs/collections/Replace';
import { SmallestFollowing } from '../funcs/collections/SmallestFollowing';
import { Color } from '../funcs/colors/Color';
import { Colors } from '../funcs/colors/Colors';
import { CnmRepeatingDecimal } from '../funcs/compatibility/CnmRepeatingDecimal';
import { DenominatorOrInteger } from '../funcs/compatibility/DenominatorOrInteger';
import { DenominatorOrOne } from '../funcs/compatibility/DenominatorOrOne';
import { LongRuleOfThree } from '../funcs/compatibility/LongRuleOfThree';
import { NEncloseNegative } from '../funcs/compatibility/NEncloseNegative';
import { NPlusMinusSign } from '../funcs/compatibility/NPlusMinusSign';
import { NSpacedPlusMinusSign } from '../funcs/compatibility/NSpacedPlusMinusSign';
import { NumberLineLabels } from '../funcs/compatibility/NumberLineLabels';
import { NumeratorOrInteger } from '../funcs/compatibility/NumeratorOrInteger';
import { PayAmount } from '../funcs/compatibility/PayAmount';
import { RandomDigits } from '../funcs/compatibility/RandomDigits';
import { RandomListWMean } from '../funcs/compatibility/RandomListWMean';
import { RandomRelTR } from '../funcs/compatibility/RandomRelTR';
import { RandomSignificantFigures } from '../funcs/compatibility/RandomSignificantFigures';
import { RandomTilesFigure } from '../funcs/compatibility/RandomTilesFigure';
import { RuleOfThree } from '../funcs/compatibility/RuleOfThree';
import { BoundVector } from '../funcs/ctor/BoundVector';
import { CreatePoint } from '../funcs/ctor/CreatePoint';
import { CreateRectangle } from '../funcs/ctor/CreateRectangle';
import { Err } from '../funcs/ctor/Err';
import { ExpFunction } from '../funcs/ctor/ExpFunction';
import { False } from '../funcs/ctor/False';
import { FunctionABHK } from '../funcs/ctor/FunctionABHK';
import { HalfPlane } from '../funcs/ctor/HalfPlane';
import { Line } from '../funcs/ctor/Line';
import { LogFunction } from '../funcs/ctor/LogFunction';
import { NotANumber } from '../funcs/ctor/NotANumber';
import { Polygon } from '../funcs/ctor/Polygon';
import { Polyline } from '../funcs/ctor/Polyline';
import { PolynomialFunction } from '../funcs/ctor/PolynomialFunction';
import { Polyomino } from '../funcs/ctor/Polyomino';
import { Quadratic } from '../funcs/ctor/Quadratic';
import { Quadrilateral } from '../funcs/ctor/Quadrilateral';
import { RandomPoint } from '../funcs/ctor/RandomPoint';
import { RandomPolygon } from '../funcs/ctor/RandomPolygon';
import { RandomQuadrilateral } from '../funcs/ctor/RandomQuadrilateral';
import { RandomSegment } from '../funcs/ctor/RandomSegment';
import { RandomTriangle } from '../funcs/ctor/RandomTriangle';
import { RationalFunction } from '../funcs/ctor/RationalFunction';
import { RepeatingDecimal } from '../funcs/ctor/RepeatingDecimal';
import { ScatterPlot } from '../funcs/ctor/ScatterPlot';
import { Segment } from '../funcs/ctor/Segment';
import { SpeakAs } from '../funcs/ctor/SpeakAs';
import { Transform } from '../funcs/ctor/Transform';
import { Triangle } from '../funcs/ctor/Triangle';
import { TriangleASA } from '../funcs/ctor/TriangleASA';
import { TriangleSAS } from '../funcs/ctor/TriangleSAS';
import { TriangleSSS } from '../funcs/ctor/TriangleSSS';
import { True } from '../funcs/ctor/True';
import { If } from '../funcs/ctrl/If';
import { Nest } from '../funcs/ctrl/Nest';
import { NestWhile } from '../funcs/ctrl/NestWhile';
import { While } from '../funcs/ctrl/While';
import { Bills } from '../funcs/currency/Bills';
import { CAD } from '../funcs/currency/CAD';
import { Change } from '../funcs/currency/Change';
import { Coins } from '../funcs/currency/Coins';
import { Denominations } from '../funcs/currency/Denominations';
import { RoundMoney } from '../funcs/currency/RoundMoney';
import { USD } from '../funcs/currency/USD';
import { LongAddition } from '../funcs/elementary/LongAddition';
import { LongDivision } from '../funcs/elementary/LongDivision';
import { LongMultiplication } from '../funcs/elementary/LongMultiplication';
import { LongSubtraction } from '../funcs/elementary/LongSubtraction';
import { AllowOperators } from '../funcs/expr/AllowOperators';
import { AllowResults } from '../funcs/expr/AllowResults';
import { AllowTerms } from '../funcs/expr/AllowTerms';
import { BoxExpression } from '../funcs/expr/BoxExpression';
import { DenyOperators } from '../funcs/expr/DenyOperators';
import { DenyResults } from '../funcs/expr/DenyResults';
import { DenyTerms } from '../funcs/expr/DenyTerms';
import { HideParenthesis } from '../funcs/expr/HideParenthesis';
import { LatexToMarkup } from '../funcs/expr/LatexToMarkup';
import { LeftHandSide } from '../funcs/expr/LeftHandSide';
import { Parse } from '../funcs/expr/Parse';
import { ParseEn } from '../funcs/expr/ParseEn';
import { RandomNumericExpression } from '../funcs/expr/RandomNumericExpression';
import { RestrictOperations } from '../funcs/expr/RestrictOperations';
import { RightHandSide } from '../funcs/expr/RightHandSide';
import { UnboxExpression } from '../funcs/expr/UnboxExpression';
import { Factorize } from '../funcs/factorization/Factorize';
import { FactorizeDetailDouble } from '../funcs/factorization/FactorizeDetailDouble';
import { FactorizeDetailSimple } from '../funcs/factorization/FactorizeDetailSimple';
import { ApplyFormat } from '../funcs/formats/ApplyFormat';
import { BaseForm } from '../funcs/formats/BaseForm';
import { ClearFormat } from '../funcs/formats/ClearFormat';
import { CopyFormat } from '../funcs/formats/CopyFormat';
import { DictionaryFormat } from '../funcs/formats/DictionaryFormat';
import { ExtractFormat } from '../funcs/formats/ExtractFormat';
import { FactoredForm } from '../funcs/formats/FactoredForm';
import { FractionFormat } from '../funcs/formats/FractionFormat';
import { GeneralForm } from '../funcs/formats/GeneralForm';
import { NumberWordsFormat } from '../funcs/formats/NumberWordsFormat';
import { SlopeInterceptForm } from '../funcs/formats/SlopeInterceptForm';
import { StandardForm } from '../funcs/formats/StandardForm';
import { StringFormat } from '../funcs/formats/StringFormat';
import { VertexForm } from '../funcs/formats/VertexForm';
import { ApplyFormula } from '../funcs/formulas/ApplyFormula';
import { ByProduct } from '../funcs/formulas/ByProduct';
import { Formula } from '../funcs/formulas/Formula';
import { Apex } from '../funcs/functions/Apex';
import { ConstantIntervals } from '../funcs/functions/ConstantIntervals';
import { DecreasingIntervals } from '../funcs/functions/DecreasingIntervals';
import { Discriminant } from '../funcs/functions/Discriminant';
import { Image } from '../funcs/functions/Image';
import { IncreasingIntervals } from '../funcs/functions/IncreasingIntervals';
import { NegativeIntervals } from '../funcs/functions/NegativeIntervals';
import { PositiveIntervals } from '../funcs/functions/PositiveIntervals';
import { Slope } from '../funcs/functions/Slope';
import { Solve } from '../funcs/functions/Solve';
import { StriclyNegativeIntervals } from '../funcs/functions/StriclyNegativeIntervals';
import { StriclyPositiveIntervals } from '../funcs/functions/StriclyPositiveIntervals';
import { XIntercept } from '../funcs/functions/XIntercept';
import { YIntercept } from '../funcs/functions/YIntercept';
import { DistinguishableSegments } from '../funcs/geom/constructions/DistinguishableSegments';
import { Segments } from '../funcs/geom/constructions/Segments';
import { Abscissa } from '../funcs/geom/props/Abscissa';
import { Angle } from '../funcs/geom/props/Angle';
import { Angles } from '../funcs/geom/props/Angles';
import { Apothem } from '../funcs/geom/props/Apothem';
import { Area } from '../funcs/geom/props/Area';
import { AxesOfSymmetry } from '../funcs/geom/props/AxesOfSymmetry';
import { Centroid } from '../funcs/geom/props/Centroid';
import { Distance } from '../funcs/geom/props/Distance';
import { EstimateArea } from '../funcs/geom/props/EstimateArea';
import { Intersection } from '../funcs/geom/props/Intersection';
import { Magnitude } from '../funcs/geom/props/Magnitude';
import { Measure } from '../funcs/geom/props/Measure';
import { Ordinate } from '../funcs/geom/props/Ordinate';
import { Perimeter } from '../funcs/geom/props/Perimeter';
import { QuadrilateralName } from '../funcs/geom/props/QuadrilateralName';
import { Vertex } from '../funcs/geom/props/Vertex';
import { Homothety } from '../funcs/geom/transforms/Homothety';
import { Reflection } from '../funcs/geom/transforms/Reflection';
import { Rotation } from '../funcs/geom/transforms/Rotation';
import { Translation } from '../funcs/geom/transforms/Translation';
import { AdjacencyMatrix } from '../funcs/graph/AdjacencyMatrix';
import { DegreeMatrix } from '../funcs/graph/DegreeMatrix';
import { DirectedGraph } from '../funcs/graph/DirectedGraph';
import { Graph } from '../funcs/graph/Graph';
import { IncidenceMatrix } from '../funcs/graph/IncidenceMatrix';
import { LabeledGraph } from '../funcs/graph/LabeledGraph';
import { Neighbors } from '../funcs/graph/Neighbors';
import { Order } from '../funcs/graph/Order';
import { OutDegreeMatrix } from '../funcs/graph/OutDegreeMatrix';
import { Size } from '../funcs/graph/Size';
import { Concat } from '../funcs/lists/Concat';
import { Contains } from '../funcs/lists/Contains';
import { Count } from '../funcs/lists/Count';
import { Distinct } from '../funcs/lists/Distinct';
import { Find } from '../funcs/lists/Find';
import { FindIntervals } from '../funcs/lists/FindIntervals';
import { First } from '../funcs/lists/First';
import { InsertAt } from '../funcs/lists/InsertAt';
import { Item } from '../funcs/lists/Item';
import { ItemAt } from '../funcs/lists/ItemAt';
import { Last } from '../funcs/lists/Last';
import { Listify } from '../funcs/lists/Listify';
import { Locate } from '../funcs/lists/Locate';
import { Mix } from '../funcs/lists/Mix';
import { Remove } from '../funcs/lists/Remove';
import { RemoveAt } from '../funcs/lists/RemoveAt';
import { ReplaceAt } from '../funcs/lists/ReplaceAt';
import { Reverse } from '../funcs/lists/Reverse';
import { Skip } from '../funcs/lists/Skip';
import { Sort } from '../funcs/lists/Sort';
import { Stringify } from '../funcs/lists/Stringify';
import { Take } from '../funcs/lists/Take';
import { And } from '../funcs/logical/And';
import { Not } from '../funcs/logical/Not';
import { Or } from '../funcs/logical/Or';
import { XOr } from '../funcs/logical/XOr';
import { AddMatrices } from '../funcs/matrices/AddMatrices';
import { Column } from '../funcs/matrices/Column';
import { Combine } from '../funcs/matrices/Combine';
import { CountColumns } from '../funcs/matrices/CountColumns';
import { CountInColumn } from '../funcs/matrices/CountInColumn';
import { CountInRow } from '../funcs/matrices/CountInRow';
import { CountRows } from '../funcs/matrices/CountRows';
import { CrossMap } from '../funcs/matrices/CrossMap';
import { Diagonal } from '../funcs/matrices/Diagonal';
import { DiffColumn } from '../funcs/matrices/DiffColumn';
import { DiffRow } from '../funcs/matrices/DiffRow';
import { Entries } from '../funcs/matrices/Entries';
import { Entry } from '../funcs/matrices/Entry';
import { FirstColumn } from '../funcs/matrices/FirstColumn';
import { FirstRow } from '../funcs/matrices/FirstRow';
import { FlipHorizontal } from '../funcs/matrices/FlipHorizontal';
import { FlipVertical } from '../funcs/matrices/FlipVertical';
import { FormatMatrix } from '../funcs/matrices/FormatMatrix';
import { IdentityMatrix } from '../funcs/matrices/IdentityMatrix';
import { LastColumn } from '../funcs/matrices/LastColumn';
import { LastRow } from '../funcs/matrices/LastRow';
import { MapTable } from '../funcs/matrices/MapTable';
import { Mask } from '../funcs/matrices/Mask';
import { Matrix } from '../funcs/matrices/Matrix';
import { MatrixFind } from '../funcs/matrices/MatrixFind';
import { MatrixTable } from '../funcs/matrices/MatrixTable';
import { MixColumns } from '../funcs/matrices/MixColumns';
import { MixRows } from '../funcs/matrices/MixRows';
import { MultiplyMatrices } from '../funcs/matrices/MultiplyMatrices';
import { Pad } from '../funcs/matrices/Pad';
import { ProductColumn } from '../funcs/matrices/ProductColumn';
import { ProductRow } from '../funcs/matrices/ProductRow';
import { QuotientColumn } from '../funcs/matrices/QuotientColumn';
import { QuotientRow } from '../funcs/matrices/QuotientRow';
import { RandomMatrix } from '../funcs/matrices/RandomMatrix';
import { ReplaceDownDiagonal } from '../funcs/matrices/ReplaceDownDiagonal';
import { ReplacePart } from '../funcs/matrices/ReplacePart';
import { ReplaceUpDiagonal } from '../funcs/matrices/ReplaceUpDiagonal';
import { ResizeMatrix } from '../funcs/matrices/ResizeMatrix';
import { RotateMatrix } from '../funcs/matrices/RotateMatrix';
import { Row } from '../funcs/matrices/Row';
import { SortColumns } from '../funcs/matrices/SortColumns';
import { SortRows } from '../funcs/matrices/SortRows';
import { StringifyRows } from '../funcs/matrices/StringifyRows';
import { SubMatrix } from '../funcs/matrices/SubMatrix';
import { SubtractMatrices } from '../funcs/matrices/SubtractMatrices';
import { TotalColumn } from '../funcs/matrices/TotalColumn';
import { TotalRow } from '../funcs/matrices/TotalRow';
import { Transpose } from '../funcs/matrices/Transpose';
import { TrimMatrix } from '../funcs/matrices/TrimMatrix';
import { VisitMatrix } from '../funcs/matrices/VisitMatrix';
import { CFemale } from '../funcs/names/CFemale';
import { CMale } from '../funcs/names/CMale';
import { ChineseLastName } from '../funcs/names/ChineseLastName';
import { EnglishLastName } from '../funcs/names/EnglishLastName';
import { Female } from '../funcs/names/Female';
import { FrenchLastName } from '../funcs/names/FrenchLastName';
import { ItalianLastName } from '../funcs/names/ItalianLastName';
import { LastName } from '../funcs/names/LastName';
import { Male } from '../funcs/names/Male';
import { MexicanLastName } from '../funcs/names/MexicanLastName';
import { VFemale } from '../funcs/names/VFemale';
import { VMale } from '../funcs/names/VMale';
import { CompareNumbers } from '../funcs/numbers/CompareNumbers';
import { Digits } from '../funcs/numbers/Digits';
import { DigitsTable } from '../funcs/numbers/DigitsTable';
import { Expand } from '../funcs/numbers/Expand';
import { Exponent } from '../funcs/numbers/Exponent';
import { FormatDigitPlaceValue } from '../funcs/numbers/FormatDigitPlaceValue';
import { FormatNumberWords } from '../funcs/numbers/FormatNumberWords';
import { FractionalDigits } from '../funcs/numbers/FractionalDigits';
import { FromDigits } from '../funcs/numbers/FromDigits';
import { FromRoman } from '../funcs/numbers/FromRoman';
import { Mantissa } from '../funcs/numbers/Mantissa';
import { PrimeAfter } from '../funcs/numbers/PrimeAfter';
import { PrimeBefore } from '../funcs/numbers/PrimeBefore';
import { RemoveDivisor } from '../funcs/numbers/RemoveDivisor';
import { ScNumber } from '../funcs/numbers/ScNumber';
import { ScSigFigs } from '../funcs/numbers/ScSigFigs';
import { ToRoman } from '../funcs/numbers/ToRoman';
import { RandomPath } from '../funcs/paths/RandomPath';
import { RightDownPaths } from '../funcs/paths/RightDownPaths';
import { ClustersPattern } from '../funcs/patterns/ClustersPattern';
import { ContainsPattern } from '../funcs/patterns/ContainsPattern';
import { CubePattern } from '../funcs/patterns/CubePattern';
import { EdgesPattern } from '../funcs/patterns/EdgesPattern';
import { IsCubePattern } from '../funcs/patterns/IsCubePattern';
import { IsMagicSquare } from '../funcs/patterns/IsMagicSquare';
import { IsSquareShape } from '../funcs/patterns/IsSquareShape';
import { MagicSquare } from '../funcs/patterns/MagicSquare';
import { NeighborPattern } from '../funcs/patterns/NeighborPattern';
import { NineSquaresSum } from '../funcs/patterns/NineSquaresSum';
import { NotCubePattern } from '../funcs/patterns/NotCubePattern';
import { CompareFunctions } from '../funcs/plotting/CompareFunctions';
import { Eval } from '../funcs/plotting/Eval';
import { FunctionInput } from '../funcs/plotting/FunctionInput';
import { Plot } from '../funcs/plotting/Plot';
import { PlotLine } from '../funcs/plotting/PlotLine';
import { PlotPeriodic } from '../funcs/plotting/PlotPeriodic';
import { SetPeriod } from '../funcs/plotting/SetPeriod';
import { SetPlotAxis } from '../funcs/plotting/SetPlotAxis';
import { SetPlotDomain } from '../funcs/plotting/SetPlotDomain';
import { SetPlotOperator } from '../funcs/plotting/SetPlotOperator';
import { SetVarName } from '../funcs/plotting/SetVarName';
import { Coefficient } from '../funcs/polynomial/Coefficient';
import { CommonFactor } from '../funcs/polynomial/CommonFactor';
import { Derivative } from '../funcs/polynomial/Derivative';
import { Factors } from '../funcs/polynomial/Factors';
import { IsSimplified } from '../funcs/polynomial/IsSimplified';
import { LongFindRoots } from '../funcs/polynomial/LongFindRoots';
import { PolynomialDegree } from '../funcs/polynomial/PolynomialDegree';
import { Binomial } from '../funcs/probs/Binomial';
import { Combinations } from '../funcs/probs/Combinations';
import { KPermutations } from '../funcs/probs/KPermutations';
import { ClearCProbs } from '../funcs/ptree/ClearCProbs';
import { ClearProbs } from '../funcs/ptree/ClearProbs';
import { ClearResults } from '../funcs/ptree/ClearResults';
import { ClearValues } from '../funcs/ptree/ClearValues';
import { ColorPath } from '../funcs/ptree/ColorPath';
import { CombResult } from '../funcs/ptree/CombResult';
import { ExpandTree } from '../funcs/ptree/ExpandTree';
import { PermResult } from '../funcs/ptree/PermResult';
import { ProductResult } from '../funcs/ptree/ProductResult';
import { RenderDiagram } from '../funcs/ptree/RenderDiagram';
import { SetHeaders } from '../funcs/ptree/SetHeaders';
import { ShowLongProbs } from '../funcs/ptree/ShowLongProbs';
import { ShowProbs } from '../funcs/ptree/ShowProbs';
import { ShowShortProbs } from '../funcs/ptree/ShowShortProbs';
import { SumResult } from '../funcs/ptree/SumResult';
import { TreeDiagram } from '../funcs/ptree/TreeDiagram';
import { TreePath } from '../funcs/ptree/TreePath';
import { Draw } from '../funcs/random/Draw';
import { Random } from '../funcs/random/Random';
import { RandomFraction } from '../funcs/random/RandomFraction';
import { RandomNumber } from '../funcs/random/RandomNumber';
import { RandomPoly } from '../funcs/random/RandomPoly';
import { RandomPrime } from '../funcs/random/RandomPrime';
import { RandomScatterData } from '../funcs/random/RandomScatterData';
import { Sample } from '../funcs/random/Sample';
import { Decimal } from '../funcs/rationals/Decimal';
import { Denominator } from '../funcs/rationals/Denominator';
import { EquivalentFraction } from '../funcs/rationals/EquivalentFraction';
import { Fraction } from '../funcs/rationals/Fraction';
import { Integer } from '../funcs/rationals/Integer';
import { Lcd } from '../funcs/rationals/Lcd';
import { MixedFraction } from '../funcs/rationals/MixedFraction';
import { Numerator } from '../funcs/rationals/Numerator';
import { Rationalize } from '../funcs/rationals/Rationalize';
import { Reciprocal } from '../funcs/rationals/Reciprocal';
import { SameDenominator } from '../funcs/rationals/SameDenominator';
import { Simplify } from '../funcs/rationals/Simplify';
import { VerboseFractions } from '../funcs/rationals/VerboseFractions';
import { Eratosthene } from '../funcs/sequences/Eratosthene';
import { IndexOf } from '../funcs/sequences/IndexOf';
import { Sequence } from '../funcs/sequences/Sequence';
import { ValueAt } from '../funcs/sequences/ValueAt';
import { Cardinal } from '../funcs/sets/Cardinal';
import { Range } from '../funcs/sets/Range';
import { SetBuilder } from '../funcs/sets/SetBuilder';
import { Setify } from '../funcs/sets/Setify';
import { CorrelationCoef } from '../funcs/statistics/CorrelationCoef';
import { Intervals } from '../funcs/statistics/Intervals';
import { Mean } from '../funcs/statistics/Mean';
import { MeanDeviation } from '../funcs/statistics/MeanDeviation';
import { Median } from '../funcs/statistics/Median';
import { Mode } from '../funcs/statistics/Mode';
import { Multiplicities } from '../funcs/statistics/Multiplicities';
import { NormalDistribution } from '../funcs/statistics/NormalDistribution';
import { PercentileRank } from '../funcs/statistics/PercentileRank';
import { ProbabilityDistribution } from '../funcs/statistics/ProbabilityDistribution';
import { ProbabilityVector } from '../funcs/statistics/ProbabilityVector';
import { Quartile } from '../funcs/statistics/Quartile';
import { QuintileRank } from '../funcs/statistics/QuintileRank';
import { SampleDistribution } from '../funcs/statistics/SampleDistribution';
import { SampleStandardDeviation } from '../funcs/statistics/SampleStandardDeviation';
import { SampleStandardScore } from '../funcs/statistics/SampleStandardScore';
import { SampleVariance } from '../funcs/statistics/SampleVariance';
import { StandardDeviation } from '../funcs/statistics/StandardDeviation';
import { StandardScore } from '../funcs/statistics/StandardScore';
import { StatisticsTable } from '../funcs/statistics/StatisticsTable';
import { Sum } from '../funcs/statistics/Sum';
import { Tally } from '../funcs/statistics/Tally';
import { TallyMarks } from '../funcs/statistics/TallyMarks';
import { TallyMarksTable } from '../funcs/statistics/TallyMarksTable';
import { TallyTable } from '../funcs/statistics/TallyTable';
import { Variance } from '../funcs/statistics/Variance';
import { SetLeavesCountLabel } from '../funcs/stemLeaf/SetLeavesCountLabel';
import { SetLeavesLabel } from '../funcs/stemLeaf/SetLeavesLabel';
import { SetStemLabel } from '../funcs/stemLeaf/SetStemLabel';
import { ShowEmptyStems } from '../funcs/stemLeaf/ShowEmptyStems';
import { ShowLeavesCount } from '../funcs/stemLeaf/ShowLeavesCount';
import { ShowTallies } from '../funcs/stemLeaf/ShowTallies';
import { StemLeafTable } from '../funcs/stemLeaf/StemLeafTable';
import { Alphabet } from '../funcs/str/Alphabet';
import { Char } from '../funcs/str/Char';
import { Chars } from '../funcs/str/Chars';
import { Consonants } from '../funcs/str/Consonants';
import { FormatTitle } from '../funcs/str/FormatTitle';
import { Join } from '../funcs/str/Join';
import { Letters } from '../funcs/str/Letters';
import { Lowercase } from '../funcs/str/Lowercase';
import { Numbers } from '../funcs/str/Numbers';
import { Pluralize } from '../funcs/str/Pluralize';
import { Print } from '../funcs/str/Print';
import { RemoveDiacritics } from '../funcs/str/RemoveDiacritics';
import { Uppercase } from '../funcs/str/Uppercase';
import { Vowels } from '../funcs/str/Vowels';
import { Words } from '../funcs/str/Words';
import { Desc } from '../funcs/structs/Desc';
import { Init } from '../funcs/structs/Init';
import { Prop } from '../funcs/structs/Prop';
import { Struct } from '../funcs/structs/Struct';
import { ColumnLines } from '../funcs/tables/ColumnLines';
import { CrossTable } from '../funcs/tables/CrossTable';
import { EqualColumns } from '../funcs/tables/EqualColumns';
import { EqualRows } from '../funcs/tables/EqualRows';
import { Footer } from '../funcs/tables/Footer';
import { Frame } from '../funcs/tables/Frame';
import { Header } from '../funcs/tables/Header';
import { LcmTable } from '../funcs/tables/LcmTable';
import { RowLines } from '../funcs/tables/RowLines';
import { Table } from '../funcs/tables/Table';
import { VFooter } from '../funcs/tables/VFooter';
import { VHeader } from '../funcs/tables/VHeader';
import { VTable } from '../funcs/tables/VTable';
import { XYTable } from '../funcs/tables/XYTable';
import { ConcatH } from '../funcs/tiling/ConcatH';
import { ConcatV } from '../funcs/tiling/ConcatV';
import { CopyHor } from '../funcs/tiling/CopyHor';
import { CopyVer } from '../funcs/tiling/CopyVer';
import { TessellatePeriod } from '../funcs/tiling/TessellatePeriod';
import { AddTime } from '../funcs/time/AddTime';
import { FormatTime } from '../funcs/time/FormatTime';
import { SubtractTime } from '../funcs/time/SubtractTime';
import { TimeOfDay } from '../funcs/time/TimeOfDay';
import { TimeSpan } from '../funcs/time/TimeSpan';
import { ArcCos } from '../funcs/trigonometry/ArcCos';
import { ArcSin } from '../funcs/trigonometry/ArcSin';
import { ArcTan } from '../funcs/trigonometry/ArcTan';
import { Cos } from '../funcs/trigonometry/Cos';
import { Degrees } from '../funcs/trigonometry/Degrees';
import { EnvAngle } from '../funcs/trigonometry/EnvAngle';
import { PytTriples } from '../funcs/trigonometry/PytTriples';
import { Radians } from '../funcs/trigonometry/Radians';
import { RandomPytTriple } from '../funcs/trigonometry/RandomPytTriple';
import { Sin } from '../funcs/trigonometry/Sin';
import { SolveTriangle } from '../funcs/trigonometry/SolveTriangle';
import { Tan } from '../funcs/trigonometry/Tan';
import { RepeatedDecimalDigits } from '../funcs/rationals/RepeatedDecimalDigits';
import { EnvVariable } from '../funcs/utility/EnvVariable';
import { MarkupToExpression } from '../funcs/expr/MarkupToExpression';
import { ShapesPattern } from '../funcs/patterns/ShapesPattern';
import { ShapeEdges } from '../funcs/geom/constructions/ShapeEdges';
import { ProcessInput } from '../funcs/plotting/ProcessInput';
import { CastInterval } from '../funcs/sets/CastInterval';
import { PercentFormat } from '../funcs/formats/PercentFormat';
import { CurrencyFormat } from '../funcs/formats/CurrencyFormat';
import { CompositeAfter } from '../funcs/numbers/CompositeAfter';
import { CompositeBefore } from '../funcs/numbers/CompositeBefore';
import { OrthogonalProjection } from '../funcs/geom/transforms/OrthogonalProjection';
import { FormatHours } from '../funcs/time/FormatHours';
import { HoursPart } from '../funcs/time/HoursPart';
import { TakeColumns } from '../funcs/matrices/TakeColumns';
import { TakeRows } from '../funcs/matrices/TakeRows';
import { SkipRows } from '../funcs/matrices/SkipRows';
import { SkipColumns } from '../funcs/matrices/SkipColumns';
import { Subset } from '../funcs/sets/Subset';
import { IsSegment } from '../funcs/asserts/geom/IsSegment';
import { IsPoint } from '../funcs/asserts/geom/IsPoint';
import { IsPolygon } from '../funcs/asserts/geom/IsPolygon';
import { IsPolyline } from '../funcs/asserts/geom/IsPolyline';
import { RegressionLine } from '../funcs/statistics/RegressionLine';
import { UserInput } from '../funcs/str/UserInput';
import { Vouvoyer } from '../funcs/str/Vouvoyer';
import { LongDivisionStyle } from '../funcs/elementary/LongDivisionStyle';
import { LongSubtractionMethod } from '../funcs/elementary/LongSubtractionMethod';
import { TaxRates } from '../funcs/taxes/TaxRates';
import { TaxRateTotal } from '../funcs/taxes/TaxRateTotal';
import { TaxNames } from '../funcs/taxes/TaxNames';
import { TaxAbbrvs } from '../funcs/taxes/TaxAbbrvs';
import { AreStrictlyEqual } from './asserts/AreStrictlyEqual';
import { Split } from './str/Split';
import { ConvertToMathML } from './markup/ConvertToMathML';
import { ParseMathML } from './markup/ParseMathML';

/**
 *
 */
export class FunctionsRegistry implements IFunctionsResolver {
  private static _instance: FunctionsRegistry;

  public static getInstance(): FunctionsRegistry {
    if (!FunctionsRegistry._instance) {
      FunctionsRegistry._instance = new FunctionsRegistry();
    }
    return FunctionsRegistry._instance;
  }

  /**
   *
   */
  private _names: string[] = [];

  private _displayNames: string[] = [];

  /**
   * key: normalized name,
   * value: instance of FunctionElement.
   */
  private _impls: IDictionary = {};

  constructor() {
    this.register('Abs', new Abs());
    this.register('Abscissa', new Abscissa());
    this.register('ACos', new ArcCos());
    this.register('AddMatrices', new AddMatrices());
    this.register('AddTime', new AddTime());
    this.register('AdjacencyMatrix', new AdjacencyMatrix());
    this.register('AllowOperators', new AllowOperators());
    this.register('AllowResults', new AllowResults());
    this.register('AllowTerms', new AllowTerms());
    this.register('Alphabet', new Alphabet());
    this.register('And', new And());
    this.register('Angle', new Angle());
    this.register('Angles', new Angles());
    this.register('Apex', new Apex());
    this.register('Apothem', new Apothem());
    this.register('ApplyFormat', new ApplyFormat());
    this.register('ApplyFormula', new ApplyFormula());
    this.register('ArcCos', new ArcCos());
    this.register('ArcSin', new ArcSin());
    this.register('ArcTan', new ArcTan());
    this.register('Area', new Area());
    this.register('AreCoincident', new AreCoincident());
    this.register('AreCongruent', new AreCongruent());
    this.register('AreEquals', new AreEquals());
    this.register('AreParallel', new AreParallel());
    this.register('ArePerpendicular', new ArePerpendicular());
    this.register('AreSimilar', new AreSimilar());
    this.register('AreStrictlyEqual', new AreStrictlyEqual());
    this.register('ASin', new ArcSin());
    this.register('ATan', new ArcTan());
    this.register('AxesOfSymmetry', new AxesOfSymmetry());
    this.register('BaseForm', new BaseForm());
    this.register('BattleshipHit', new BattleshipHit());
    this.register('BattleshipPlace', new BattleshipPlace());
    this.register('Bills', new Bills());
    this.register('Binomial', new Binomial());
    this.register('BoundVector', new BoundVector());
    this.register('BoxExpression', new BoxExpression());
    this.register('ByProduct', new ByProduct());
    this.register('CAD', new CAD());
    this.register('Cardinal', new Cardinal());
    this.register('CardName', new CardName());
    this.register('CardRank', new CardRank());
    this.register('CardShortName', new CardShortName());
    this.register('CardsTable', new CardsTable());
    this.register('CardSuit', new CardSuit());
    this.register('Ceil', new Ceil());
    this.register('Centroid', new Centroid());
    this.register('CFemale', new CFemale());
    this.register('Change', new Change());
    this.register('Char', new Char());
    this.register('Chars', new Chars());
    this.register('ChessBoard', new ChessBoard());
    this.register('ChessMove', new ChessMove());
    this.register('ChessPlace', new ChessPlace());
    this.register('ChessRemove', new ChessRemove());
    this.register('ChineseLastName', new ChineseLastName());
    this.register('ClearCProbs', new ClearCProbs());
    this.register('ClearFormat', new ClearFormat());
    this.register('ClearProbs', new ClearProbs());
    this.register('ClearResults', new ClearResults());
    this.register('ClearValues', new ClearValues());
    this.register('Clubs', new Clubs());
    this.register('ClustersPattern', new ClustersPattern());
    this.register('CMale', new CMale());
    this.register('CnmRepeatingDecimal', new CnmRepeatingDecimal());
    this.register('Coefficient', new Coefficient());
    this.register('Coins', new Coins());
    this.register('Color', new Color());
    this.register('ColorPath', new ColorPath());
    this.register('Colors', new Colors());
    this.register('Column', new Column());
    this.register('ColumnLines', new ColumnLines());
    this.register('Comb', new Binomial());
    this.register('Combinations', new Combinations());
    this.register('Combine', new Combine());
    this.register('CombResult', new CombResult());
    this.register('CommonFactor', new CommonFactor());
    this.register('CompareFunctions', new CompareFunctions());
    this.register('CompareNumbers', new CompareNumbers());
    this.register('CompositeBefore', new CompositeBefore());
    this.register('CompositeAfter', new CompositeAfter());
    this.register('Concat', new Concat());
    this.register('ConcatH', new ConcatH());
    this.register('ConcatV', new ConcatV());
    this.register('Consonants', new Consonants());
    this.register('ConstantIntervals', new ConstantIntervals());
    this.register('Contains', new Contains());
    this.register('ContainsPattern', new ContainsPattern());
    this.register('ConvertToMathML', new ConvertToMathML());
    this.register('CopyFormat', new CopyFormat());
    this.register('CopyHor', new CopyHor(false));
    this.register('CopyHorr', new CopyHor(true));
    this.register('CopyVer', new CopyVer(false));
    this.register('CopyVerr', new CopyVer(true));
    this.register('CorrelationCoef', new CorrelationCoef());
    this.register('Cos', new Cos());
    this.register('Count', new Count());
    this.register('CountColumns', new CountColumns());
    this.register('CountInColumn', new CountInColumn());
    this.register('CountInRow', new CountInRow());
    this.register('CountRows', new CountRows());
    this.register('CrossMap', new CrossMap());
    this.register('CrossTable', new CrossTable());
    this.register('CubePattern', new CubePattern());
    this.register('CurrencyFormat', new CurrencyFormat());
    this.register('Decimal', new Decimal());
    this.register('DecreasingIntervals', new DecreasingIntervals());
    this.register('Degree', new PolynomialDegree());
    this.register('DegreeMatrix', new DegreeMatrix());
    this.register('Degrees', new Degrees());
    this.register('Denominations', new Denominations());
    this.register('Denominator', new Denominator());
    this.register('DenominatorOrInteger', new DenominatorOrInteger());
    this.register('DenominatorOrOne', new DenominatorOrOne());
    this.register('DenyOperators', new DenyOperators());
    this.register('DenyResults', new DenyResults());
    this.register('DenyTerms', new DenyTerms());
    this.register('Derivative', new Derivative());
    this.register('Desc', new Desc());
    this.register('Diagonal', new Diagonal());
    this.register('Diamonds', new Diamonds());
    this.register('DictionaryFormat', new DictionaryFormat());
    this.register('Diff', new Diff());
    this.register('DiffColumn', new DiffColumn());
    this.register('DiffRow', new DiffRow());
    this.register('Digit', new Digit());
    this.register('DigitalRoot', new DigitalRoot());
    this.register('Digits', new Digits());
    this.register('DigitsTable', new DigitsTable());
    this.register('DirectedGraph', new DirectedGraph());
    this.register('Discriminant', new Discriminant());
    this.register('Distance', new Distance());
    this.register('Distinct', new Distinct());
    this.register('DistinguishableSegments', new DistinguishableSegments());
    this.register('DivideOutFormsOfOne', new DivideOutFormsOfOne());
    this.register('Divisors', new Divisors());
    this.register('Draw', new Draw());
    this.register('EdgesPattern', new EdgesPattern());
    this.register('EnglishLastName', new EnglishLastName());
    this.register('Entries', new Entries());
    this.register('Entry', new Entry());
    this.register('EqualColumns', new EqualColumns());
    this.register('EqualRows', new EqualRows());
    this.register('EquivalentFraction', new EquivalentFraction());
    this.register('EnvAngle', new EnvAngle());
    this.register('EnvRandom', new Random(true));
    this.register('EnvVariable', new EnvVariable());
    this.register('Eratosthene', new Eratosthene());
    this.register('Err', new Err());
    this.register('EstimateArea', new EstimateArea());
    this.register('Eval', new Eval());
    this.register('Expand', new Expand());
    this.register('ExpFunction', new ExpFunction());
    this.register('Exponent', new Exponent());
    this.register('ExtractFormat', new ExtractFormat());
    this.register('FactoredForm', new FactoredForm());
    this.register('Factorial', new Factorial());
    this.register('Factorize', new Factorize());
    this.register('FactorizeDetailDouble', new FactorizeDetailDouble());
    this.register('FactorizeDetailSimple', new FactorizeDetailSimple());
    this.register('Factors', new Factors());
    this.register('False', new False());
    this.register('FastMap', new FastMap());
    this.register('Female', new Female());
    this.register('Filter', new Filter());
    this.register('Find', new Find());
    this.register('FindIntervals', new FindIntervals());
    this.register('First', new First());
    this.register('FirstColumn', new FirstColumn());
    this.register('FirstRow', new FirstRow());
    this.register('Flatten', new Flatten());
    this.register('FlipHorizontal', new FlipHorizontal());
    this.register('FlipVertical', new FlipVertical());
    this.register('Floor', new Floor());
    this.register('Footer', new Footer());
    this.register('FormatMatrix', new FormatMatrix());
    this.register('FormatNumberWords', new FormatNumberWords());
    this.register('FormatDigitPlaceValue', new FormatDigitPlaceValue());
    this.register('FormatTime', new FormatTime());
    this.register('FormatTitle', new FormatTitle());
    this.register('Formula', new Formula());
    this.register('Fraction', new Fraction());
    this.register('FractionalDigits', new FractionalDigits());
    this.register('FractionFormat', new FractionFormat());
    this.register('Frame', new Frame());
    this.register('FrenchLastName', new FrenchLastName());
    this.register('FromDigits', new FromDigits());
    this.register('FromRoman', new FromRoman());
    this.register('FunctionABHK', new FunctionABHK());
    this.register('FunctionInput', new FunctionInput());
    this.register('Gcd', new Gcd());
    this.register('GeneralForm', new GeneralForm());
    this.register('Graph', new Graph());
    this.register('HalfPlane', new HalfPlane());
    this.register('HasAmbiguousFractionScript', new HasAmbiguousFractionScript());
    this.register('HasExponent', new HasExponent());
    this.register('HasFence', new HasFence());
    this.register('HasFraction', new HasFraction());
    this.register('HasOperator', new HasOperator());
    this.register('HasParallels', new HasParallels());
    this.register('HasRadical', new HasRadical());
    this.register('HasSubscript', new HasSubscript());
    this.register('Header', new Header());
    this.register('Hearts', new Hearts());
    this.register('HideParenthesis', new HideParenthesis());
    this.register('Homothety', new Homothety());
    this.register('HoursFormat', new FormatHours());
    this.register('HoursPart', new HoursPart());
    this.register('FormatHours', new FormatHours());
    this.register('IdentityMatrix', new IdentityMatrix());
    this.register('If', new If());
    this.register('Image', new Image());
    this.register('IncidenceMatrix', new IncidenceMatrix());
    this.register('IncreasingIntervals', new IncreasingIntervals());
    this.register('IndexOf', new IndexOf());
    this.register('Init', new Init());
    this.register('InsertAt', new InsertAt());
    this.register('Integer', new Integer());
    this.register('Intersection', new Intersection());
    this.register('Interval', new CastInterval());
    this.register('Intervals', new Intervals());
    this.register('IsAcute', new IsAcute());
    this.register('IsArrowHead', new IsArrowHead());
    this.register('IsAxisOfSymmetry', new IsAxisOfSymmetry());
    this.register('IsClosed', new IsClosed());
    this.register('IsConcave', new IsConcave());
    this.register('IsConvex', new IsConvex());
    this.register('IsCrossed', new IsCrossed());
    this.register('IsCubePattern', new IsCubePattern());
    this.register('IsDecimal', new IsDecimal());
    this.register('IsEn', new IsLang('en'));
    this.register('IsError', new IsError());
    this.register('IsEs', new IsLang('es'));
    this.register('IsFr', new IsLang('fr'));
    this.register('IsLang', new IsLang(null));
    this.register('IsHorizontal', new IsHorizontal());
    this.register('IsInteger', new IsInteger());
    this.register('IsIsoangle', new IsIsoangle());
    this.register('IsIsorectangle', new IsIsorectangle());
    this.register('IsIsosceles', new IsIsosceles());
    this.register('IsIsoscelesTrapezoid', new IsIsoscelesTrapezoid());
    this.register('IsKite', new IsKite());
    this.register('IsList', new IsList());
    this.register('IsMagicSquare', new IsMagicSquare());
    this.register('IsMatrix', new IsMatrix());
    this.register('IsNumeric', new IsNumeric());
    this.register('IsObtuse', new IsObtuse());
    this.register('IsParallelogram', new IsParallelogram());
    this.register('IsPlural', new IsPlural());
    this.register('IsPoint', new IsPoint());
    this.register('IsPointOnLine', new IsPointOnLine());
    this.register('IsPolygon', new IsPolygon());
    this.register('IsPolyline', new IsPolyline());
    this.register('IsPrime', new IsPrime());
    this.register('IsRectangle', new IsRectangle());
    this.register('IsRectangleTrapezoid', new IsRectangleTrapezoid());
    this.register('IsRegular', new IsRegular());
    this.register('IsRhombus', new IsRhombus());
    this.register('IsRight', new IsRight());
    this.register('IsScalene', new IsScalene());
    this.register('IsSegment', new IsSegment());
    this.register('IsSimplified', new IsSimplified());
    this.register('IsSpecialCase', new IsSpecialCase());
    this.register('IsSquareShape', new IsSquareShape());
    this.register('IsSymmetric', new IsSymmetric());
    this.register('IsToken', new IsToken());
    this.register('IsTranslated', new IsTranslated());
    this.register('IsTrapezoid', new IsTrapezoid());
    this.register('IsUnhandled', new IsUnhandled());
    this.register('IsVariable', new IsVariable());
    this.register('IsVertical', new IsVertical());
    this.register('ItalianLastName', new ItalianLastName());
    this.register('Item', new Item());
    this.register('ItemAt', new ItemAt());
    this.register('Items', new Items());
    this.register('Join', new Join());
    this.register('KPermutations', new KPermutations());
    this.register('LabeledGraph', new LabeledGraph());
    this.register('LargestPrevious', new LargestPrevious(false));
    this.register('LargestPreviousOrEqual', new LargestPrevious(true));
    this.register('Last', new Last());
    this.register('LastColumn', new LastColumn());
    this.register('LastName', new LastName());
    this.register('LastRow', new LastRow());
    this.register('LatexToMarkup', new LatexToMarkup());
    this.register('Lcd', new Lcd());
    this.register('Lcm', new Lcm());
    this.register('LcmTable', new LcmTable());
    this.register('LeftHandSide', new LeftHandSide());
    this.register('Letters', new Letters());
    this.register('Line', new Line());
    this.register('Listify', new Listify());
    this.register('ListTray', new ListTray());
    this.register('Ln', new Ln());
    this.register('Locate', new Locate());
    this.register('Log', new LogN());
    this.register('LogFunction', new LogFunction());
    this.register('LongAddition', new LongAddition());
    this.register('LongDivision', new LongDivision());
    this.register('LongDivisionStyle', new LongDivisionStyle());
    this.register('LongFindRoots', new LongFindRoots());
    this.register('LongMultiplication', new LongMultiplication());
    this.register('LongPower', new LongPower());
    this.register('LongRuleOfThree', new LongRuleOfThree());
    this.register('LongSubtraction', new LongSubtraction());
    this.register('LongSubtractionMethod', new LongSubtractionMethod());
    this.register('Lowercase', new Lowercase());
    this.register('MagicSquare', new MagicSquare());
    this.register('Magnitude', new Magnitude());
    this.register('Male', new Male());
    this.register('Mantissa', new Mantissa());
    this.register('Map', new Map());
    this.register('MapN', new MapN());
    this.register('MapTable', new MapTable());
    this.register('MarkupToExpression', new MarkupToExpression());
    this.register('Mask', new Mask());
    this.register('Matrix', new Matrix());
    this.register('MatrixFind', new MatrixFind());
    this.register('MatrixTable', new MatrixTable());
    this.register('Max', new Max());
    this.register('Mean', new Mean());
    this.register('MeanDeviation', new MeanDeviation());
    this.register('Measure', new Measure());
    this.register('Median', new Median());
    this.register('MexicanLastName', new MexicanLastName());
    this.register('Min', new Min());
    this.register('Mix', new Mix());
    this.register('MixColumns', new MixColumns());
    this.register('MixedFraction', new MixedFraction());
    this.register('MixRows', new MixRows());
    this.register('Mod', new Mod());
    this.register('Mode', new Mode());
    this.register('Multiplicities', new Multiplicities());
    this.register('MultiplyMatrices', new MultiplyMatrices());
    this.register('NaN', new NotANumber());
    this.register('NDistinctDigits', new RandomDigits());
    this.register('NegativeIntervals', new NegativeIntervals());
    this.register('NeighborPattern', new NeighborPattern());
    this.register('Neighbors', new Neighbors('default'));
    this.register('NeighborsIn', new Neighbors('in'));
    this.register('NeighborsOut', new Neighbors('out'));
    this.register('NEncloseNegative', new NEncloseNegative());
    this.register('Nest', new Nest());
    this.register('NestWhile', new NestWhile());
    this.register('NineSquaresSum', new NineSquaresSum());
    this.register('NormalDistribution', new NormalDistribution());
    this.register('Not', new Not());
    this.register('NotCubePattern', new NotCubePattern());
    this.register('NotDistinct', new NotDistinct());
    this.register('NPlusMinusSign', new NPlusMinusSign());
    this.register('NSpacedPlusMinusSign', new NSpacedPlusMinusSign());
    this.register('NthRoot', new NthRoot());
    this.register('NumberLineLabels', new NumberLineLabels());
    this.register('Numbers', new Numbers());
    this.register('NumberWordsFormat', new NumberWordsFormat());
    this.register('Numerator', new Numerator());
    this.register('NumeratorOrInteger', new NumeratorOrInteger());
    this.register('NumericTray', new NumericTray());
    this.register('Or', new Or());
    this.register('Order', new Order());
    this.register('Ordinate', new Ordinate());
    this.register('OrthogonalProjection', new OrthogonalProjection());
    this.register('OutDegreeMatrix', new OutDegreeMatrix());
    this.register('Pad', new Pad(true, true, true, true));
    this.register('PadBottom', new Pad(false, false, false, true));
    this.register('PadLeft', new Pad(true, false, false, false));
    this.register('PadRight', new Pad(false, true, false, false));
    this.register('PadTop', new Pad(false, false, true, false));
    this.register('Parse', new Parse());
    this.register('ParseEn', new ParseEn());
    this.register('ParseMathML', new ParseMathML());
    this.register('Pattern', new Pattern());
    this.register('PayAmount', new PayAmount());
    this.register('PercentFormat', new PercentFormat());
    this.register('PercentileRank', new PercentileRank());
    this.register('Perimeter', new Perimeter());
    this.register('Perm', new KPermutations());
    this.register('PermResult', new PermResult());
    this.register('Pick', new ExpandTree(true));
    this.register('Plot', new Plot());
    this.register('PlotLine', new PlotLine());
    this.register('PlotPeriodic', new PlotPeriodic());
    this.register('Pluralize', new Pluralize());
    this.register('Point', new CreatePoint());
    this.register('Points', new Points());
    this.register('Polygon', new Polygon());
    this.register('Polyline', new Polyline());
    this.register('PolynomialFunction', new PolynomialFunction());
    this.register('PolynomialTray', new PolynomialTray());
    this.register('Polyomino', new Polyomino());
    this.register('PositiveIntervals', new PositiveIntervals());
    this.register('PrimeAfter', new PrimeAfter());
    this.register('PrimeBefore', new PrimeBefore());
    this.register('PrimeFactors', new PrimeFactors());
    this.register('Print', new Print());
    this.register('ProbabilityDistribution', new ProbabilityDistribution());
    this.register('ProbabilityVector', new ProbabilityVector());
    this.register('ProcessInput', new ProcessInput());
    this.register('ProductColumn', new ProductColumn());
    this.register('ProductResult', new ProductResult());
    this.register('ProductRow', new ProductRow());
    this.register('Prop', new Prop());
    this.register('PytTriples', new PytTriples());
    this.register('Quadratic', new Quadratic());
    this.register('Quadrilateral', new Quadrilateral());
    this.register('QuadrilateralName', new QuadrilateralName());
    this.register('Quartile', new Quartile());
    this.register('QuintileRank', new QuintileRank());
    this.register('QuotientColumn', new QuotientColumn());
    this.register('QuotientRow', new QuotientRow());
    this.register('Radians', new Radians());
    this.register('Random', new Random(false));
    this.register('RandomDigits', new RandomDigits());
    this.register('RandomFraction', new RandomFraction());
    this.register('RandomListWMean', new RandomListWMean());
    this.register('RandomMatrix', new RandomMatrix());
    this.register('RandomNumber', new RandomNumber());
    this.register('RandomNumericExpression', new RandomNumericExpression());
    this.register('RandomPath', new RandomPath());
    this.register('RandomPoint', new RandomPoint());
    this.register('RandomPoly', new RandomPoly());
    this.register('RandomPolygon', new RandomPolygon());
    this.register('RandomPrime', new RandomPrime());
    this.register('RandomPytTriple', new RandomPytTriple());
    this.register('RandomQuadrilateral', new RandomQuadrilateral());
    this.register('RandomRelTR', new RandomRelTR());
    this.register('RandomScatterData', new RandomScatterData());
    this.register('RandomSegment', new RandomSegment());
    this.register('RandomSignificantFigures', new RandomSignificantFigures());
    this.register('RandomTilesFigure', new RandomTilesFigure());
    this.register('RandomTriangle', new RandomTriangle());
    this.register('Range', new Range());
    this.register('RationalFunction', new RationalFunction());
    this.register('Rationalize', new Rationalize());
    this.register('Reciprocal', new Reciprocal());
    this.register('RepeatedDecimalDigits', new RepeatedDecimalDigits());
    this.register('Rectangle', new CreateRectangle());
    this.register('Reflection', new Reflection());
    this.register('RegressionLine', new RegressionLine());
    this.register('Remove', new Remove());
    this.register('RemoveAt', new RemoveAt());
    this.register('RemoveDiacritics', new RemoveDiacritics());
    this.register('RemoveDivisor', new RemoveDivisor());
    this.register('RenderDiagram', new RenderDiagram());
    this.register('Repeat', new Repeat());
    this.register('RepeatingDecimal', new RepeatingDecimal());
    this.register('Replace', new Replace());
    this.register('ReplaceAt', new ReplaceAt());
    this.register('ReplaceDownDiagonal', new ReplaceDownDiagonal());
    this.register('ReplacePart', new ReplacePart());
    this.register('ReplaceUpDiagonal', new ReplaceUpDiagonal());
    this.register('ResizeMatrix', new ResizeMatrix());
    this.register('RestrictOperations', new RestrictOperations());
    this.register('Reverse', new Reverse());
    this.register('RightDownPaths', new RightDownPaths());
    this.register('RightHandSide', new RightHandSide());
    this.register('Rnd', new Random(false));
    this.register('Root', new NthRoot());
    this.register('RotateMatrix', new RotateMatrix());
    this.register('Rotation', new Rotation());
    this.register('Round', new Round());
    this.register('RoundMoney', new RoundMoney());
    this.register('Row', new Row());
    this.register('RowLines', new RowLines());
    this.register('RuleOfThree', new RuleOfThree());
    this.register('SameDenominator', new SameDenominator());
    this.register('Sample', new Sample());
    this.register('SampleDistribution', new SampleDistribution());
    this.register('SampleStandardDeviation', new SampleStandardDeviation());
    this.register('SampleStandardScore', new SampleStandardScore());
    this.register('SampleVariance', new SampleVariance());
    this.register('ScatterPlot', new ScatterPlot());
    this.register('ScNumber', new ScNumber());
    this.register('ScSigFigs', new ScSigFigs());
    this.register('Segment', new Segment());
    this.register('Segments', new Segments());
    this.register('Sequence', new Sequence());
    this.register('SetBuilder', new SetBuilder());
    this.register('SetHeaders', new SetHeaders());
    this.register('Setify', new Setify());
    this.register('SetLeavesCountLabel', new SetLeavesCountLabel());
    this.register('SetLeavesLabel', new SetLeavesLabel());
    this.register('SetPeriod', new SetPeriod());
    this.register('SetPlotAxis', new SetPlotAxis());
    this.register('SetPlotDomain', new SetPlotDomain());
    this.register('SetPlotOperator', new SetPlotOperator());
    this.register('SetStemLabel', new SetStemLabel());
    this.register('SetVarName', new SetVarName());
    this.register('ShapeEdges', new ShapeEdges());
    this.register('ShapesPattern', new ShapesPattern());
    this.register('ShowEmptyStems', new ShowEmptyStems());
    this.register('ShowLeavesCount', new ShowLeavesCount());
    this.register('ShowLongProbs', new ShowLongProbs());
    this.register('ShowProbs', new ShowProbs());
    this.register('ShowShortProbs', new ShowShortProbs());
    this.register('ShowTallies', new ShowTallies());
    this.register('Simplify', new Simplify());
    this.register('Sin', new Sin());
    this.register('Size', new Size());
    this.register('Skip', new Skip());
    this.register('SkipRows', new SkipRows());
    this.register('SkipColumns', new SkipColumns());
    this.register('Slices', new Slices());
    this.register('Slope', new Slope());
    this.register('SlopeInterceptForm', new SlopeInterceptForm());
    this.register('SloppyCompare', new SloppyCompare());
    this.register('SmallestFollowing', new SmallestFollowing(false));
    this.register('SmallestFollowingOrEqual', new SmallestFollowing(true));
    this.register('Solve', new Solve());
    this.register('SolveFor', new SolveFor());
    this.register('SolveTriangle', new SolveTriangle());
    this.register('Sort', new Sort());
    this.register('SortColumns', new SortColumns());
    this.register('SortRows', new SortRows());
    this.register('Spades', new Spades());
    this.register('SpeakAs', new SpeakAs());
    this.register('Split', new Split());
    this.register('Sqrt', new Sqrt());
    this.register('StandardDeviation', new StandardDeviation());
    this.register('StandardForm', new StandardForm());
    this.register('StandardScore', new StandardScore());
    this.register('StatisticsTable', new StatisticsTable());
    this.register('StemLeafTable', new StemLeafTable());
    this.register('StriclyNegativeIntervals', new StriclyNegativeIntervals());
    this.register('StriclyPositiveIntervals', new StriclyPositiveIntervals());
    this.register('StringFormat', new StringFormat());
    this.register('Stringify', new Stringify());
    this.register('StringifyRows', new StringifyRows());
    this.register('Struct', new Struct());
    this.register('SubMatrix', new SubMatrix());
    this.register('Subset', new Subset());
    this.register('SubtractMatrices', new SubtractMatrices());
    this.register('SubtractTime', new SubtractTime());
    this.register('Sum', new Sum());
    this.register('SumResult', new SumResult());
    this.register('Table', new Table());
    this.register('Take', new Take());
    this.register('TakeColumns', new TakeColumns());
    this.register('TakeRows', new TakeRows());
    this.register('Tally', new Tally());
    this.register('TallyMarks', new TallyMarks());
    this.register('TallyMarksTable', new TallyMarksTable());
    this.register('TallyTable', new TallyTable());
    this.register('Tan', new Tan());
    this.register('TaxRates', new TaxRates());
    this.register('TaxRateTotal', new TaxRateTotal());
    this.register('TaxNames', new TaxNames());
    this.register('TaxAbbrvs', new TaxAbbrvs());
    this.register('TessellatePeriod', new TessellatePeriod());
    this.register('TestPoint', new TestPoint());
    this.register('TimeOfDay', new TimeOfDay());
    this.register('TimeSpan', new TimeSpan());
    this.register('ToRoman', new ToRoman());
    this.register('TotalColumn', new TotalColumn());
    this.register('TotalRow', new TotalRow());
    this.register('Transform', new Transform());
    this.register('Translation', new Translation());
    this.register('Transpose', new Transpose());
    this.register('TreeDiagram', new TreeDiagram());
    this.register('TreePath', new TreePath());
    this.register('Triangle', new Triangle());
    this.register('TriangleASA', new TriangleASA());
    this.register('TriangleSAS', new TriangleSAS());
    this.register('TriangleSSS', new TriangleSSS());
    this.register('TrimMatrix', new TrimMatrix());
    this.register('True', new True());
    this.register('Truncate', new Truncate());
    this.register('UnboxExpression', new UnboxExpression());
    this.register('Uppercase', new Uppercase());
    this.register('USD', new USD());
    this.register('UserInput', new UserInput());
    this.register('ValidateLinearEquationBalanceScale', new ValidateLinearEquationBalanceScale());
    this.register('ValueAt', new ValueAt());
    this.register('Variance', new Variance());
    this.register('VerboseFractions', new VerboseFractions());
    this.register('Vertex', new Vertex());
    this.register('VertexForm', new VertexForm());
    this.register('VFemale', new VFemale());
    this.register('VFooter', new VFooter());
    this.register('VHeader', new VHeader());
    this.register('VisitMatrix', new VisitMatrix());
    this.register('VMale', new VMale());
    this.register('Vouvoyer', new Vouvoyer());
    this.register('Vowels', new Vowels());
    this.register('VTable', new VTable());
    this.register('While', new While());
    this.register('Withdraw', new ExpandTree(false));
    this.register('Words', new Words());
    this.register('XIntercept', new XIntercept());
    this.register('XOr', new XOr());
    this.register('XYTable', new XYTable());
    this.register('YIntercept', new YIntercept());
  }

  /**
   *
   */
  private normalizeName(name: string): string {
    return name.toLowerCase();
  }

  /**
   *
   */
  private register(
    name: string,
    impl: FunctionElement): void {
    try {
      impl.displayName = name;
      if (impl instanceof OperatorElement) {
        (<OperatorElement>impl).functionModel = true;
      }
      const normalizedName = this.normalizeName(name);
      this._impls[normalizedName] = impl;
      this._names.push(normalizedName);
      this._displayNames.push(name);
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns the actionscript definition of the specified function name.
   */
  public resolveFunction(name: string): FunctionElement {
    return this._impls[this.normalizeName(name)];
  }

  /**
   *
   */
  public get names(): string[] {
    return this._names;
  }

  public get displayNames(): string[] {
    return this._displayNames;
  }
}
