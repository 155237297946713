import React from 'react';
import styled from 'styled-components';
import { Button } from '../../Button/Button';
import { PopoverWithTip } from '../../../utils/Popups/PopoverWithTip/PopoverWithTip';

interface IPopoverIconButtonProps {
  open: boolean;
  ButtonContent: React.ReactNode;
  onOpen: () => void;
  onClose: () => void;
}

export const PopoverIconButton: React.FC<IPopoverIconButtonProps> = (props) => {
  const {
    ButtonContent,
    children,
    open,
    onOpen,
    onClose,
  } = props;
  const anchorRef = React.useRef(null);
  return (
    <>
      <Button
        ref={anchorRef}
        onMouseDown={onOpen}
        icon
      >
        {ButtonContent}
      </Button>
      <PopoverWithTip
        tipAlignment="bottom-center"
        open={open}
        anchorEl={anchorRef.current}
        onClose={onClose}
      >
        <Container>
          {children}
        </Container>
      </PopoverWithTip>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;
