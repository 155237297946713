import styled from 'styled-components';
import React from 'react';
import KeyboardCloseIcon from '../../../../assets/keyboardClose.svg';
import { IKey } from '../../../keyboard/Keyboard.models';

export const DISMISS = 'DISMISS';
export const NOOP = 'NOOP';

const StyledKeyboardHideIcon = styled(KeyboardCloseIcon)`
  && {
    height: 24px;
    width: 24px;
  }
`;

export const DismissKey: IKey = {
  value: DISMISS,
  displayedValue: <StyledKeyboardHideIcon />,
};

export const NoopKey: IKey = {
  value: NOOP,
  displayedValue: null,
};
