import React from 'react';
import styled from 'styled-components';
import { AvatarCount } from './AvatarCount';
import { Avatar } from './Avatar';
import { IAvatar } from './IAvatar';

export interface IAvatarsGroupSpacedCompactProps {
  readonly avatars: ReadonlyArray<IAvatar>;
  readonly extraCount?: number;
}

export const AvatarsGroupCompact: React.FC<IAvatarsGroupSpacedCompactProps> = (
  {
    avatars,
    extraCount = 0,
  },
) => {
  const extraLabel
    = extraCount > 0
      ? (
        <AvatarCount count={extraCount} />
      )
      : null;

  const avatarsImages = avatars.map((a, index) => {
    return index < avatars.length - 1
      ? (
        <AvatarOverlap
          key={index}
        >
          <Avatar
            label={a.label}
            src={a.src}
            size={a.size}
          />
        </AvatarOverlap>
      )
      : (
        <Avatar
          key={index}
          label={a.label}
          src={a.src}
          size={a.size}
        />
      );
  });

  return (
    <Container>
      <CountOverlap>
        {extraLabel}
      </CountOverlap>
      <AvatarsRow>
        {avatarsImages}
      </AvatarsRow>
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const AvatarOverlap = styled.div`
  margin-right: -12px;
`;

const CountOverlap = styled.div`
  margin-right: -4px;
`;

const AvatarsRow = styled.div`
  display: inline-flex;
  flex-direction: row;
`;
