import { Editor, Element, NodeEntry, Text, Transforms } from '../TypedSlate';
import { ICustomEditor, ICustomElement } from '../models/editor';
import { IPlugin } from '../models/plugins';
import { isTTS } from '../utils/TTSUtils';

export const withTTS: IPlugin = (editor: ICustomEditor) => {
  const { isInline, normalizeNode } = editor;

  editor.isInline = (element: ICustomElement) => {
    return isTTS(element) ? true : isInline(element);
  };

  editor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && isTTS(node)) {
      const containsOnlyEmptyText = node.children.length === 1
        && Text.isText(node.children[0])
        && node.children[0].text === '';
      if (containsOnlyEmptyText) {
        Transforms.removeNodes(editor, {
          at: path,
          match: n => !Editor.isEditor(n) && Element.isElement(n) && isTTS(n),
        });
      }
    }
    normalizeNode(entry);
  };

  return editor;
};
