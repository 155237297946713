import { action, observable, runInAction } from 'mobx';
import { EnvironmentType, getEnvironment } from '../../utils/environment';
import { Store } from '../../dependencyInjection/Store';
import { IUserRef } from '../IUserRef';
import { CollaborationHub } from '../../services/CollaborationHub';

interface IUserMessage {
  user: IUserRef;
}

interface IPageUserMessage extends IUserMessage {
  pageGuid: string;
}

interface IUserPageEntry {
  user: IUserRef;
  enteredOn: Date;
}

@Store('CollaborationStore.ts/CollaborationStore')
export class CollaborationStore {
  // TODO: Use ActivitySelectionsStore.currentPageRefGuid instead
  @observable
  public currentPageGuid: string;

  @observable
  public self: IUserRef;

  @observable
  public pagesEntryLog: Map<string, ReadonlyArray<IUserPageEntry>>;

  private readonly hub: CollaborationHub;

  constructor() {
    this.hub = this.configureHub();

    runInAction(() => {
      this.self = null;
      this.pagesEntryLog = new Map<string, ReadonlyArray<IUserPageEntry>>();
      this.currentPageGuid = null;
    });
  }

  @action
  public configureSelf(self: IUserRef): void {
    this.self = self;
    this.hub.start();
  }

  @action
  public exitPage(pageGuid: string): void {
    if (this.currentPageGuid === pageGuid) {
      this.currentPageGuid = null;
    }

    this.unregisterUserFromPage(pageGuid, this.self);
    this.sendExitPageMessage(pageGuid);
  }

  @action
  public enterPage(pageGuid: string): void {
    this.currentPageGuid = pageGuid;
    this.registerUserInPage(pageGuid, this.self);
    this.sendEnterPageMessage(pageGuid);
  }

  @action
  private onConnectionStarted(): void {
    this.pagesEntryLog = new Map<string, ReadonlyArray<IUserPageEntry>>();
    this.registerUserInPage(this.currentPageGuid, this.self);
    this.sendRegisterUserMessage();
  }

  @action
  private onConnectionStopped(): void {
    this.unregisterUserFromPage(this.currentPageGuid, this.self);
    this.currentPageGuid = null;

    this.pagesEntryLog = new Map<string, ReadonlyArray<IUserPageEntry>>();
  }

  @action
  private registerUserInPage(pageGuid: string, user: IUserRef): void {
    if (!this.pagesEntryLog.has(pageGuid)) {
      this.pagesEntryLog.set(pageGuid, []);
    }

    const index = this.getUserLogIndex(pageGuid, user);
    if (index === -1) {
      this.pagesEntryLog.set(pageGuid, this.pagesEntryLog.get(pageGuid).concat({ user, enteredOn: new Date() }));
    }
  }

  @action
  private unregisterUserFromPage(pageGuid: string, user: IUserRef): void {
    const index = this.getUserLogIndex(pageGuid, user);
    if (index > -1) {
      this.pagesEntryLog.set(pageGuid, this.pagesEntryLog.get(pageGuid).filter((e, i) => i !== index));
    }
  }

  @action
  private unregisterUser(user: IUserRef): void {
    this.pagesEntryLog.forEach((userEntries, pageGuid) => this.unregisterUserFromPage(pageGuid, user));
  }

  private getUserLogIndex(pageGuid: string, user: IUserRef): number {
    if (!this.pagesEntryLog.has(pageGuid)) {
      return -1;
    }

    return this.pagesEntryLog
      .get(pageGuid)
      .findIndex(log => log.user.userId === user.userId && log.user.windowId === user.windowId);
  }

  private sendExitPageMessage(pageGuid: string) {
    this.hub.send('ExitPage', {
      pageGuid,
      user: {
        ...this.self,
      },
    });
  }

  private sendEnterPageMessage(pageGuid: string) {
    this.hub.send('EnterPage', {
      pageGuid,
      user: {
        ...this.self,
      },
    });
  }

  private sendRegisterUserMessage() {
    this.hub.send('RegisterUser', {
      user: {
        ...this.self,
      },
    });
  }

  private handleUserPresentOnPage = (message: IPageUserMessage) => {
    this.registerUserInPage(
      message.pageGuid,
      {
        ...message.user,
      },
    );
  };

  private handleUserRegistered = (message: IPageUserMessage) => {
    if (this.currentPageGuid) {
      this.hub.send('SignalPresence', {
        fromUser: {
          ...this.self,
        },
        toUser: {
          ...message.user,
        },
        pageGuid: this.currentPageGuid,
      });
    }
  };

  private handleUserExitedPage = (message: IPageUserMessage) => {
    this.unregisterUserFromPage(
      message.pageGuid,
      {
        ...message.user,
      },
    );
  };

  private handleUserDisconnected = (message: IPageUserMessage) => {
    this.unregisterUser({
      ...message.user,
    });
  };

  private configureHub() {
    const hubUrl = (getEnvironment() === EnvironmentType.staging)
      ? 'https://publisher-sync.qa.scolab.io'
      : ScolabConfiguration.COLLABORATION_HUB_API;

    const hub = new CollaborationHub(hubUrl);
    hub.addHandler('userEnteredPage', this.handleUserPresentOnPage);
    hub.addHandler('userPresentOnPage', this.handleUserPresentOnPage);
    hub.addHandler('userRegistered', this.handleUserRegistered);
    hub.addHandler('userExitedPage', this.handleUserExitedPage);
    hub.addHandler('userDisconnected', this.handleUserDisconnected);

    hub.addEvent('start', () => this.onConnectionStarted());
    hub.addEvent('close', () => this.onConnectionStopped());

    return hub;
  }
}
