import React from 'react';
import { ArrowBottomCenter, ArrowTopCenter } from '@scolab/publisher-ui-kit';
import styled from 'styled-components';
import { EllipsisButton } from './EllipsisButton';
import SortIcon from '../../assets/sort.svg';
import CheckIcon from '../../assets/check.svg';
import { ContextMenuItem } from './ContextMenu/ContextMenuItem';
import { ContextMenuItemSeparator } from './ContextMenu/ContextMenuItemSeparator';

export enum SortOrder {
  ascending = 'ascending',
  descending = 'descending',
  none = 'none',
}

export interface ISortContextMenuProps<TCriteria extends string | number> {
  criteria: TCriteria;
  order: SortOrder;
  criteriaList: ReadonlyArray<{
    criteria: TCriteria;
    Label: React.ReactNode;
  }>;
  descendingLabel: React.ReactNode;
  ascendingLabel: React.ReactNode;
  tooltip?: React.ReactNode;
  onChangeCriteria: (criteria: TCriteria) => void;
  onChangeOrder: (order: SortOrder) => void;
}

export const SORT_CONTEXT_MENU_BUTTON = 'SORT_CONTEXT_MENU_BUTTON';

export const SortContextMenu = <T extends string | number>(props: ISortContextMenuProps<T>) => {
  const {
    criteria,
    order,
    criteriaList,
    descendingLabel,
    ascendingLabel,
    tooltip = 'Sort',
    onChangeCriteria,
    onChangeOrder,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <StyledEllipsisButton
      isOpen={isOpen}
      onSetIsOpen={setIsOpen}
      horizontalAlignment="left"
      icon={<StyledSortIcon data-testid={SORT_CONTEXT_MENU_BUTTON} />}
      tooltip={tooltip}
    >
      {criteriaList.map((criteriaItem) => {
        return (
          <ContextMenuItem
            key={criteriaItem.criteria}
            label={criteriaItem.Label}
            shortcutLabel={criteria === criteriaItem.criteria ? <StyledCheckIcon /> : null}
            onClick={() => onChangeCriteria(criteriaItem.criteria)}
          />
        );
      })}
      <ContextMenuItemSeparator />
      <ContextMenuItem
        label={(
          <MenuItemContainer>
            <DirectionContainer>
              {ascendingLabel}
              <ArrowBottomCenter />
            </DirectionContainer>
          </MenuItemContainer>
        )}
        shortcutLabel={order === SortOrder.ascending ? <StyledCheckIcon /> : null}
        onClick={() => onChangeOrder(SortOrder.ascending)}
      />
      <ContextMenuItem
        label={(
          <MenuItemContainer>
            <DirectionContainer>
              {descendingLabel}
              <ArrowTopCenter />
            </DirectionContainer>
          </MenuItemContainer>
        )}
        shortcutLabel={order === SortOrder.descending ? <StyledCheckIcon /> : null}
        onClick={() => onChangeOrder(SortOrder.descending)}
      />
    </StyledEllipsisButton>
  );
};

export const MenuItemContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

export const DirectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
`;

const StyledSortIcon = styled(SortIcon)`
  fill: ${props => props.theme.button.default.color};
`;

const StyledCheckIcon = styled(CheckIcon)`
  line-height: 0;
  height: 20px;
  width: 20px;
`;

const StyledEllipsisButton = styled(EllipsisButton)`
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    background-color: ${props => props.theme.button.default.hover.backgroundColor};
  }
`;
