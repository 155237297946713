import isHotkey from 'is-hotkey';
import { Editor, Transforms, Range } from '../TypedSlate';
import { toggleBoldMark, toggleItalicMark, toggleUnderlineMark } from '../utils/characterFormatUtils';
import { setClassName } from '../utils/classNameUtils';
import { listShortcuts } from './listShortcuts';
import { ICustomEditor } from '../models/editor';
import { IClassDefinition } from '../models/configuration';

export const shortcutListener = (event: React.KeyboardEvent, editor: ICustomEditor, classes: ReadonlyArray<IClassDefinition>) => {
  if (listShortcuts(event, editor)) {
    return;
  }

  if (event.key === 'PageUp') {
    event.preventDefault();
    Transforms.setSelection(
      editor,
      {
        anchor: Editor.start(editor, [0]),
        focus: Editor.start(editor, [0]),
      });
    return;
  }
  if (event.key === 'PageDown') {
    event.preventDefault();
    Transforms.setSelection(
      editor,
      {
        anchor: Editor.end(editor, [editor.children.length - 1]),
        focus: Editor.end(editor, [editor.children.length - 1]),
      });
    return;
  }

  if (isHotkey('shift+ENTER')(event.nativeEvent)) {
    editor.insertText('\n');
    event.preventDefault();
    return;
  }

  if (event.key === 'Delete') {
    if (Range.isCollapsed(editor.selection)) {
      if (isHotkey('mod+shift+Delete')(event.nativeEvent)) {
        Editor.deleteForward(editor, { unit: 'block' });
      } else if (isHotkey('mod+Delete')(event.nativeEvent)) {
        Editor.deleteForward(editor, { unit: 'word' });
      } else {
        Editor.deleteForward(editor);
      }
    } else {
      Editor.deleteFragment(editor);
    }

    event.preventDefault();
    return;
  }

  if (isHotkey('shift+ArrowRight')(event.nativeEvent)) {
    Transforms.move(editor, { distance: 1, unit: 'character', edge: 'end' });
    event.preventDefault();
  } else if (isHotkey('mod+shift+ArrowRight')(event.nativeEvent)) {
    Transforms.move(editor, { distance: 1, unit: 'word', edge: 'end' });
    event.preventDefault();
  } else if (isHotkey('shift+ArrowLeft')(event.nativeEvent)) {
    Transforms.move(editor, { distance: 1, unit: 'character', edge: 'start', reverse: true });
    event.preventDefault();
  } else if (isHotkey('mod+shift+ArrowLeft')(event.nativeEvent)) {
    Transforms.move(editor, { distance: 1, unit: 'word', edge: 'start', reverse: true });
    event.preventDefault();
  }

  if (isHotkey('mod+b')(event.nativeEvent)) {
    event.preventDefault();
    toggleBoldMark(editor, classes);
  } else if (isHotkey('mod+i')(event.nativeEvent)) {
    event.preventDefault();
    toggleItalicMark(editor, classes);
  } else if (isHotkey('mod+u')(event.nativeEvent)) {
    event.preventDefault();
    toggleUnderlineMark(editor);
  } else if (isHotkey('mod+q')(event.nativeEvent)) {
    event.preventDefault();
    setClassName(editor, 'textQuote3');
  }

  if (!event.shiftKey && event.key === 'Tab') {
    editor.insertText('\t');
    event.preventDefault();
    return true;
  }
};
