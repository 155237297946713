import { v4 as uuidv4 } from 'uuid';
import { useCallback, useRef, useState } from 'react';
import KeyboardManager from '../components/keyboard/KeyboardManager';
import { IKeyboard } from '../components/keyboard/Keyboard.models';
import { DismissKey } from '../components/inputs/Keyboards/Keys/SpecialKeys';

export function useKeyboard(keyboard: IKeyboard, onKeyPressed: (key: string) => void, id: string = uuidv4()) {
  const { current: keyboardId } = useRef(id);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const handleKeyPress = useCallback((key: string) => {
    if (key === DismissKey.value) {
      KeyboardManager.getInstance().hideKeyboard(keyboardId);
      setKeyboardOpen(false);
    } else {
      onKeyPressed(key);
    }
  }, [keyboardId, onKeyPressed]);

  const openKeyboard = useCallback(() => {
    KeyboardManager.getInstance().showKeyboard(keyboardId, keyboard, handleKeyPress);
    setKeyboardOpen(true);
  }, [handleKeyPress, keyboard, keyboardId]);

  const closeKeyboard = useCallback(() => {
    KeyboardManager.getInstance().hideKeyboard(keyboardId);
    setKeyboardOpen(false);
  }, [keyboardId]);

  const toggleKeyboard = useCallback(() => {
    if (keyboardOpen) {
      closeKeyboard();
    } else {
      openKeyboard();
    }
  }, [closeKeyboard, keyboardOpen, openKeyboard]);

  return { keyboardId, keyboardOpen, openKeyboard, closeKeyboard, toggleKeyboard };
}
