import { Transforms } from '../TypedSlate';
import { ICustomEditor, ICustomElement } from '../models/editor';
import { findPath } from './locationUtils';

export const updateElement = <T extends ICustomElement>(editor: ICustomEditor, element: T, newValue: Partial<T>) => {
  const path = findPath(editor.children, element);
  Transforms.setNodes(
    editor,
    newValue,
    {
      at: path,
      match: n => n === element,
    });
};
